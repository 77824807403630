@import '../../marketplace.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 58px 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 70px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--containerMaxWight) + (78px * 2));
    padding: 0 78px;
  }

  @media (--viewportXLarge) {
    max-width: var(--containerMaxWight);
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 16px;
  margin-right: 12px;
  text-align: center;
  position: relative;
  margin-left: 8px;

  & svg {
    width: 14px;
    height: 14px;
  }
}

.icon:before {
  width: 32px;
  height: 32px;
  padding: 4px;
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%,-50%);
  content: "";
  border: 1px solid #FFF;
  border-radius: 50%;

  &:hover {
    background-color: var(--marketplaceColor);
    opacity: 0.4;
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.topLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
}

.linkHeader {
  color: #FFF;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0;
}

/* Desktop: logo and organization stuff */
.organization {
  flex-basis: 300px;
  display: none;
  /*margin-right: 24px;*/
  /*margin-bottom: 24px;*/

  @media (--viewportMedium) {
    display: block;
    order: 1;
    flex-basis: 400px;
    /*margin-right: 12px;*/
  }
  @media (--viewportLarge) {
    /*flex-basis: 240px;*/
    /*margin-right: 57px;*/
  }
  @media (--viewportLargeWithPaddings) {
    /*flex-basis: 250px;*/
    /*margin-right: 12px;*/
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 27px;
  fill: #FFF;
}

.organizationDescription {
  @apply --marketplaceTinyFontStyles;
  color: #FFF;
  font-size: 14px;
  line-height: 20px;
}

.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
  margin-top: 36px;
  margin-bottom: 0;
}

.copyrightLink {
  color: #FFF;
  transition: var(--transitionStyleButton);
  font-size: 14px;
  line-height: 20px;

  &:hover {
    color: #FFF;
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  /*flex-basis: 165px;*/
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: #FFF;
  transition: var(--transitionStyleButton);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    text-decoration: none;
  }

  & span:hover {
    color: #FFF;
    text-decoration: none;
    border-bottom: 1px solid #FFF;
    transition: 0.3ms;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  /*flex-shrink: 0;*/
  order: 5;
  display: none;
  /*flex-basis: 109px;*/
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: #FFF;
  transition: var(--transitionStyleButton);
  font-size: 14px;
  line-height: 20px;

  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;

  &:hover {
    color: #FFF;
    text-decoration: none;
  }

  & span:hover {
    color: #FFF;
    text-decoration: none;
    border-bottom: 1px solid #FFF;
    transition: 0.3ms;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Dimensions */
  padding: 26px 0 0 0;

  /* Layout */
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;

  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    display: none;
  }
}

.logoLinkMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #FFF;
  transition: var(--transitionStyleButton);
  text-align: right;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 16px;

  & span, a {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.privacy {
  margin-right: 24px;
}

.mediaIcon {
  fill: #FFF;
}
