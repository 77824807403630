
:global(.fontsLoaded) {
    & body {
      font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    }
    & button {
      font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    }
  }
  
  :global(.react-datepicker__year-read-view--down-arrow),
  :global(.react-datepicker__month-read-view--down-arrow),
  :global(.react-datepicker__month-year-read-view--down-arrow), 
  :global(.react-datepicker__navigation-icon::before) {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
  }
  :global(.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle), :global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle) {
    margin-left: -4px;
    position: absolute;
    width: 0;
  }
  :global(.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before), :global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before), :global(.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after), :global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after){
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
  }
  :global(.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before), :global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before) {
    border-bottom-color: #aeaeae;
  }
  :global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle) {
    top: 0;
    margin-top: -8px;
  }
  :global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before), :global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after) {
    border-top: none;
    border-bottom-color: #f0f0f0;
  }
  :global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after) {
    top: 0;
  }
  :global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before) {
    top: -1px;
    border-bottom-color: #aeaeae;
  }
  :global(.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle) {
    bottom: 0;
    margin-bottom: -8px;
  }
  :global(.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before), :global(.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after) {
    border-bottom: none;
    border-top-color: #fff;
  }
  :global(.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after) {
    bottom: 0;
  }
  :global(.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before) {
    bottom: -1px;
    border-top-color: #aeaeae;
  }
  :global(.react-datepicker-wrapper) {
    display: inline-block;
    padding: 0;
    border: 0;
  }
  :global(.react-datepicker) {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
  }
  :global(.react-datepicker--time-only .react-datepicker__triangle) {
    left: 35px;
  }
  :global(.react-datepicker--time-only .react-datepicker__time-container) {
    border-left: 0;
  }
  :global(.react-datepicker--time-only .react-datepicker__time),
  :global(.react-datepicker--time-only .react-datepicker__time-box) {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  :global(.react-datepicker__triangle) {
    position: absolute;
    left: 50px;
  }
  :global(.react-datepicker-popper) {
    z-index: 1;
  }
  :global(.react-datepicker-popper[data-placement^=bottom]) {
    padding-top: 10px;
  }
  :global(.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle), :global(.react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle) {
    left: auto;
    right: 50px;
  }
  :global(.react-datepicker-popper[data-placement^=top]) {
    padding-bottom: 10px;
  }
  :global(.react-datepicker-popper[data-placement^=right]) {
    padding-left: 8px;
  }
  :global(.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle) {
    left: auto;
    right: 42px;
  }
  :global(.react-datepicker-popper[data-placement^=left]) {
    padding-right: 8px;
  }
  :global(.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle) {
    left: 42px;
    right: auto;
  }
  :global(.react-datepicker__header) {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
  }
  :global(.react-datepicker__header--time) {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
  :global(.react-datepicker__header--time:not), :global(.react-datepicker__header--time--only) {
    border-top-left-radius: 0;
  }
  :global(.react-datepicker__header:not), :global(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
  }
  
  :global(.react-datepicker__year-dropdown-container--select),
  :global(.react-datepicker__month-dropdown-container--select),
  :global(.react-datepicker__month-year-dropdown-container--select),
  :global(.react-datepicker__year-dropdown-container--scroll),
  :global(.react-datepicker__month-dropdown-container--scroll),
  :global(.react-datepicker__month-year-dropdown-container--scroll) {
    display: inline-block;
    margin: 0 15px;
  }
  :global(.react-datepicker__current-month),
  :global(.react-datepicker-time__header),
  :global(.react-datepicker-year-header) {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
  }
  :global(.react-datepicker-time__header) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  :global(.react-datepicker__navigation) {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
  }
  :global(.react-datepicker__navigation--previous) {
    left: 2px;
  }
  :global(.react-datepicker__navigation--next) {
    right: 2px;
  }
  :global(.react-datepicker__navigation--next--with-time:not) ,:global(.react-datepicker__navigation--next--with-today-button) {
    right: 85px;
  }
  :global(.react-datepicker__navigation--years) {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  :global(.react-datepicker__navigation--years-previous) {
    top: 4px;
  }
  :global(.react-datepicker__navigation--years-upcoming) {
    top: -4px;
  }
  :global(.react-datepicker__navigation:hover *::before) {
    border-color: #a6a6a6;
  }
  :global(.react-datepicker__navigation-icon) {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;
  }
  :global(.react-datepicker__navigation-icon--next) {
    left: -2px;
  }
  :global(.react-datepicker__navigation-icon--next::before) {
    transform: rotate(45deg);
    left: -7px;
  }
  :global(.react-datepicker__navigation-icon--previous) {
    right: -2px;
  }
  :global(.react-datepicker__navigation-icon--previous::before) {
    transform: rotate(225deg);
    right: -7px;
  }
  :global(.react-datepicker__month-container) {
    float: left;
  }
  :global(.react-datepicker__year) {
    margin: 0.4rem;
    text-align: center;
  }
  :global(.react-datepicker__year-wrapper) {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }
  :global(.react-datepicker__year .react-datepicker__year-text) {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
  :global(.react-datepicker__month) {
    margin: 0.4rem;
    text-align: center;
  }
  :global(.react-datepicker__month .react-datepicker__month-text) ,
  :global(.react-datepicker__month .react-datepicker__quarter-text) {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
  :global(.react-datepicker__input-time-container) {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
  }
  :global(.react-datepicker__input-time-container .react-datepicker-time__caption) {
    display: inline-block;
  }
  :global(.react-datepicker__input-time-container .react-datepicker-time__input-container) {
    display: inline-block;
  }
  :global(.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input) {
    display: inline-block;
    margin-left: 10px;
  }
  :global(.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input) {
    width: auto;
  }
  :global(.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button) ,
  :global(.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button) {
    -webkit-appearance: none;
    margin: 0;
  }
  :global(.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]) {
    -moz-appearance: textfield;
  }
  :global(.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter) {
    margin-left: 5px;
    display: inline-block;
  }
  :global(.react-datepicker__time-container) {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 85px;
  }
  :global(.react-datepicker__time-container--with-today-button) {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -87px;
    top: 0;
  }
  :global(.react-datepicker__time-container .react-datepicker__time) {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box) {
    width: 85px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3rem;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list) {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    box-sizing: content-box;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item) {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover) {
    cursor: pointer;
    background-color: #f0f0f0;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected) {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover) {
    background-color: #216ba5;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled) {
    color: #ccc;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover) {
    cursor: default;
    background-color: transparent;
  }
  :global(.react-datepicker__week-number) {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }
  :global(.react-datepicker__week-number.react-datepicker__week-number--clickable) {
    cursor: pointer;
  }
  :global(.react-datepicker__week-number.react-datepicker__week-number--clickable:hover) {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
  }
  :global(.react-datepicker__day-names) ,
  :global(.react-datepicker__week) {
    white-space: nowrap;
  }
  :global(.react-datepicker__day-names) {
    margin-bottom: -8px;
  }
  :global(.react-datepicker__day-name) ,
  :global(.react-datepicker__day) ,
  :global(.react-datepicker__time-name) {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }
  :global(.react-datepicker__day) ,
  :global(.react-datepicker__month-text) ,
  :global(.react-datepicker__quarter-text) ,
  :global(.react-datepicker__year-text) {
    cursor: pointer;
  }
  :global(.react-datepicker__day:hover) ,
  :global(.react-datepicker__month-text:hover) ,
  :global(.react-datepicker__quarter-text:hover) ,
  :global(.react-datepicker__year-text:hover) {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
  }
  :global(.react-datepicker__day--today) ,
  :global(.react-datepicker__month-text--today) ,
  :global(.react-datepicker__quarter-text--today) ,
  :global(.react-datepicker__year-text--today) {
    font-weight: bold;
  }
  :global(.react-datepicker__day--highlighted) ,
  :global(.react-datepicker__month-text--highlighted) ,
  :global(.react-datepicker__quarter-text--highlighted) ,
  :global(.react-datepicker__year-text--highlighted) {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
  }
  :global(.react-datepicker__day--highlighted:hover) ,
  :global(.react-datepicker__month-text--highlighted:hover) ,
  :global(.react-datepicker__quarter-text--highlighted:hover) ,
  :global(.react-datepicker__year-text--highlighted:hover) {
    background-color: #32be3f;
  }
  :global(.react-datepicker__day--highlighted-custom-1) ,
  :global(.react-datepicker__month-text--highlighted-custom-1) ,
  :global(.react-datepicker__quarter-text--highlighted-custom-1) ,
  :global(.react-datepicker__year-text--highlighted-custom-1) {
    color: magenta;
  }
  :global(.react-datepicker__day--highlighted-custom-2) ,
  :global(.react-datepicker__month-text--highlighted-custom-2) ,
  :global(.react-datepicker__quarter-text--highlighted-custom-2) ,
  :global(.react-datepicker__year-text--highlighted-custom-2) {
    color: green;
  }
  :global(.react-datepicker__day--selected) , 
  :global(.react-datepicker__day--in-selecting-range) , 
  :global(.react-datepicker__day--in-range) ,
  :global(.react-datepicker__month-text--selected) ,
  :global(.react-datepicker__month-text--in-selecting-range) ,
  :global(.react-datepicker__month-text--in-range) ,
  :global(.react-datepicker__quarter-text--selected) ,
  :global(.react-datepicker__quarter-text--in-selecting-range) ,
  :global(.react-datepicker__quarter-text--in-range) ,
  :global(.react-datepicker__year-text--selected) ,
  :global(.react-datepicker__year-text--in-selecting-range) ,
  :global(.react-datepicker__year-text--in-range) {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
  }
  :global(.react-datepicker__day--selected:hover) , 
  :global(.react-datepicker__day--in-selecting-range:hover) , 
  :global(.react-datepicker__day--in-range:hover),
  :global(.react-datepicker__month-text--selected:hover) ,
  :global(.react-datepicker__month-text--in-selecting-range:hover) ,
  :global(.react-datepicker__month-text--in-range:hover) ,
  :global(.react-datepicker__quarter-text--selected:hover) ,
  :global(.react-datepicker__quarter-text--in-selecting-range:hover) ,
  :global(.react-datepicker__quarter-text--in-range:hover) ,
  :global(.react-datepicker__year-text--selected:hover) ,
  :global(.react-datepicker__year-text--in-selecting-range:hover),
  :global(.react-datepicker__year-text--in-range:hover) {
    background-color: #1d5d90;
  }
  :global(.react-datepicker__day--keyboard-selected) ,
  :global(.react-datepicker__month-text--keyboard-selected) ,
  :global(.react-datepicker__quarter-text--keyboard-selected) ,
  :global(.react-datepicker__year-text--keyboard-selected) {
    border-radius: 0.3rem;
    background-color: #bad9f1;
    color: rgb(0, 0, 0);
  }
  :global(.react-datepicker__day--keyboard-selected:hover) ,
  :global(.react-datepicker__month-text--keyboard-selected:hover) ,
  :global(.react-datepicker__quarter-text--keyboard-selected:hover) ,
  :global(.react-datepicker__year-text--keyboard-selected:hover) {
    background-color: #1d5d90;
  }
  :global(.react-datepicker__day--in-selecting-range:not):global(.react-datepicker__day--in-range) ,
  :global(.react-datepicker__month-text--in-range) ,
  :global(.react-datepicker__quarter-text--in-range) ,
  :global(.react-datepicker__year-text--in-range) ,
  :global(.react-datepicker__month-text--in-selecting-range:not), :global(.react-datepicker__day--in-range) ,
  :global(.react-datepicker__month-text--in-range) ,
  :global(.react-datepicker__quarter-text--in-range) ,
  :global(.react-datepicker__year-text--in-range) ,
  :global(.react-datepicker__quarter-text--in-selecting-range:not),:global(.react-datepicker__day--in-range) ,
  :global(.react-datepicker__month-text--in-range) ,
  :global(.react-datepicker__quarter-text--in-range) ,
  :global(.react-datepicker__year-text--in-range) ,
  :global(.react-datepicker__year-text--in-selecting-range:not),:global(.react-datepicker__day--in-range) ,
  :global(.react-datepicker__month-text--in-range) ,
  :global(.react-datepicker__quarter-text--in-range) ,
  :global(.react-datepicker__year-text--in-range) {
    background-color: rgba(33 , 107 , 165 , 0.5);
  }
  :global(.react-datepicker__month--selecting-range), :global(.react-datepicker__day--in-range:not),:global(.react-datepicker__day--in-selecting-range) ,
  :global(.react-datepicker__month-text--in-selecting-range) ,
  :global(.react-datepicker__quarter-text--in-selecting-range) ,
  :global(.react-datepicker__year-text--in-selecting-range, .react-datepicker__year--selecting-range), :global(.react-datepicker__day--in-range:not),:global(.react-datepicker__day--in-selecting-range) ,
  :global(.react-datepicker__month-text--in-selecting-range) ,
  :global(.react-datepicker__quarter-text--in-selecting-range) ,
  :global(.react-datepicker__year-text--in-selecting-range ,
  .react-datepicker__month--selecting-range) :global(.react-datepicker__month-text--in-range:not):global(.react-datepicker__day--in-selecting-range) ,
  :global(.react-datepicker__month-text--in-selecting-range) ,
  :global(.react-datepicker__quarter-text--in-selecting-range) ,
  :global(.react-datepicker__year-text--in-selecting-range) ,
  .react-datepicker__year--selecting-range :global(.react-datepicker__month-text--in-range:not), :global(.react-datepicker__day--in-selecting-range) ,
  :global(.react-datepicker__month-text--in-selecting-range) ,
  :global(.react-datepicker__quarter-text--in-selecting-range) ,
  :global(.react-datepicker__year-text--in-selecting-range) ,
  .react-datepicker__month--selecting-range :global(.react-datepicker__quarter-text--in-range:not), :global(.react-datepicker__day--in-selecting-range) ,
  :global(.react-datepicker__month-text--in-selecting-range) ,
  :global(.react-datepicker__quarter-text--in-selecting-range) ,
  :global(.react-datepicker__year-text--in-selecting-range) ,
  .react-datepicker__year--selecting-range :global(.react-datepicker__quarter-text--in-range:not),:global(.react-datepicker__day--in-selecting-range) ,
  :global(.react-datepicker__month-text--in-selecting-range) ,
  :global(.react-datepicker__quarter-text--in-selecting-range) ,
  :global(.react-datepicker__year-text--in-selecting-range) ,
  .react-datepicker__month--selecting-range :global(.react-datepicker__year-text--in-range:not), :global(.react-datepicker__day--in-selecting-range) ,
  :global(.react-datepicker__month-text--in-selecting-range) ,
  :global(.react-datepicker__quarter-text--in-selecting-range) ,
  :global(.react-datepicker__year-text--in-selecting-range) ,
  .react-datepicker__year--selecting-range :global(.react-datepicker__year-text--in-range:not), :global(.react-datepicker__day--in-selecting-range) ,
  :global(.react-datepicker__month-text--in-selecting-range) ,
  :global(.react-datepicker__quarter-text--in-selecting-range) ,
  :global(.react-datepicker__year-text--in-selecting-range) {
    background-color: #f0f0f0;
    color: #000;
  }
  :global(.react-datepicker__day--disabled) ,
  :global(.react-datepicker__month-text--disabled) ,
  :global(.react-datepicker__quarter-text--disabled) ,
  :global(.react-datepicker__year-text--disabled) {
    cursor: default;
    color: #ccc;
  }
  :global(.react-datepicker__day--disabled:hover) ,
  :global(.react-datepicker__month-text--disabled:hover) ,
  :global(.react-datepicker__quarter-text--disabled:hover) ,
  :global(.react-datepicker__year-text--disabled:hover) {
    background-color: transparent;
  }
  :global(.react-datepicker__input-container) {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  :global(.react-datepicker__input-container .react-datepicker__calendar-icon) {
    position: absolute;
    padding: 0.5rem;
  }
  :global(.react-datepicker__view-calendar-icon input) {
    padding: 6px 10px 5px 25px;
  }
  :global(.react-datepicker__year-read-view) ,
  :global(.react-datepicker__month-read-view) ,
  :global(.react-datepicker__month-year-read-view) {
    border: 1px solid transparent;
    border-radius: 0.3rem;
    position: relative;
  }
  :global(.react-datepicker__year-read-view:hover) ,
  :global(.react-datepicker__month-read-view:hover) ,
  :global(.react-datepicker__month-year-read-view:hover) {
    cursor: pointer;
  }
  :global(.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow) ,
  :global(.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow) ,
  :global(.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow) ,
  :global(.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow) ,
  :global(.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow) ,
  :global(.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow) {
    border-top-color: #b3b3b3;
  }
  :global(.react-datepicker__year-read-view--down-arrow) ,
  :global(.react-datepicker__month-read-view--down-arrow) ,
  :global(.react-datepicker__month-year-read-view--down-arrow) {
    transform: rotate(135deg);
    right: -16px;
    top: 0;
  }
  :global(.react-datepicker__year-dropdown) ,
  :global(.react-datepicker__month-dropdown) ,
  :global(.react-datepicker__month-year-dropdown) {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
  }
  :global(.react-datepicker__year-dropdown:hover) ,
  :global(.react-datepicker__month-dropdown:hover) ,
  :global(.react-datepicker__month-year-dropdown:hover) {
    cursor: pointer;
  }
  :global(.react-datepicker__year-dropdown--scrollable) ,
  :global(.react-datepicker__month-dropdown--scrollable) ,
  :global(.react-datepicker__month-year-dropdown--scrollable) {
    height: 150px;
    overflow-y: scroll;
  }
  :global(.react-datepicker__year-option) ,
  :global(.react-datepicker__month-option) ,
  :global(.react-datepicker__month-year-option) {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  :global(.react-datepicker__year-option:first-of-type) ,
  :global(.react-datepicker__month-option:first-of-type) ,
  :global(.react-datepicker__month-year-option:first-of-type) {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  :global(.react-datepicker__year-option:last-of-type) ,
  :global(.react-datepicker__month-option:last-of-type) ,
  :global(.react-datepicker__month-year-option:last-of-type) {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  :global(.react-datepicker__year-option:hover) ,
  :global(.react-datepicker__month-option:hover) ,
  :global(.react-datepicker__month-year-option:hover) {
    background-color: #ccc;
  }
  :global(.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming) ,
  :global(.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming) ,
  :global(.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming) {
    border-bottom-color: #b3b3b3;
  }
  :global(.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous) ,
  :global(.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous) ,
  :global(.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous) {
    border-top-color: #b3b3b3;
  }
  :global(.react-datepicker__year-option--selected) ,
  :global(.react-datepicker__month-option--selected) ,
  :global(.react-datepicker__month-year-option--selected) {
    position: absolute;
    left: 15px;
  }
  :global(.react-datepicker__close-icon) {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }
  :global(.react-datepicker__close-icon::after) {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
  }
  :global(.react-datepicker__today-button) {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }
  :global(.react-datepicker__portal) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 , 0, 0 , 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }
  :global(.react-datepicker__portal .react-datepicker__day-name) ,
  :global(.react-datepicker__portal .react-datepicker__day) ,
  :global(.react-datepicker__portal .react-datepicker__time-name) {
    width: 3rem;
    line-height: 3rem;
  }
  @media ((max-width: 400px), (max-height: 550px)) {
    :global(.react-datepicker__portal .react-datepicker__day-name) ,
    :global(.react-datepicker__portal .react-datepicker__day) ,
    :global(.react-datepicker__portal .react-datepicker__time-name) {
      width: 2rem;
      line-height: 2rem;
    }
  }
  :global(.react-datepicker__portal .react-datepicker__current-month) ,
  :global(.react-datepicker__portal .react-datepicker-time__header) {
    font-size: 1.44rem;
  }
  :global(.react-datepicker__children-container) {
    width: 13.8rem;
    margin: 0.4rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    height: auto;
  }
  :global(.react-datepicker__aria-live) {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
  }
  :global(.react-datepicker__calendar-icon) {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
  } 
  