@import '../../marketplace.css';

.selectWrapper {
  min-width: 200px;

  @media (--viewportMedium) {
    min-width: 250px;
  }
}

.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;

  @media (--viewportSmall) {
    flex-direction: row;
    column-gap: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.searchInputWrapper {
  padding-bottom: 0;
  gap: 0;
}

.list {
  border-top: 1px solid var(--matterColorAnti);
  margin: 0;
  padding: 20px 0;
}

.error {
  color: var(--failColor);
  margin: 16px 16px 16px 0;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.totalItems {
  transition: ease-in-out 0.1s;
  margin: 0 8px 8px 0;
  text-align: right;
  font-size: 16px;
  font-weight: 400;

  @media(--viewportMedium) {
    margin: 0 8px 0 0;
  }
}

.totalItems span {
  font-weight: 500;
}

.btn {
  min-height: 44px;
  height: 44px;
  min-width: 80px;
  padding: 0 12px;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;

  @media(--viewportSmall) {
    width: auto;
    padding: 0 24px;
    align-self: flex-end;
  }

  @media(--viewportMedium) {
    align-self: center;
  }
}

.actionContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media(--viewportSmall) {
    flex-direction: row;
    align-self: flex-end;
    margin-bottom: 4px;
  }
}

.link {
  width: 100%;
  padding: 0 24px;
  border-radius: 4px;
  background: var(--marketplaceColor);
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  height: 44px;
  min-height: 44px;
  display: table;

  @media(--viewportSmall) {
    width: auto;
  }
}

.link span {
  display: table-cell;
  vertical-align: middle;
}

.link:hover {
  text-decoration: none;
  background: var(--marketplaceColorDark);
}
