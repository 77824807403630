@import '../../marketplace.css';

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  width: 100%;
  color: var(--marketplaceColor);

  margin: 40px 0 20px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    margin: 100px 0 80px 0;
    font-size: 26px;
    line-height: 36px;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 37px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 12px;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0 0;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: #666;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0px 30px 30px 30px;
  display: flex;
  flex-direction: column;
}

.locationName {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin: 30px 0 40px 0;
}

.locationName:after {
  display: block;
  position: relative;
  top: 20px;
  width: 96px;
  height: 4px;
  border-radius: 20px;
  background: var(--marketplaceColor);
  content: "";
}

.locationDesc {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.heroButton {
  display: none;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  border: 1px solid var(--matterColor);
  color: var(--matterColor);
  border-radius: 8px;
  height: 34px;
  font-weight: 500;
  padding: 7px 16px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: #ededed;
  }

  @media (--viewportMedium) {
    display: block;
  }
}
