@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 100%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  background: #FFF;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  flex-shrink: 0;
  margin-top: 6px;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    margin-top: 8px;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;

  color: var(--contentColorPrimary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; 

  @media (--viewportSmall) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  color: var(--contentColorPrimary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; 

  @media (--viewportSmall) {
    font-size: 20px;
    line-height: 30px;
  }

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column-reverse;
  gap: 2px;

  @media (--viewportSmall) {
    gap: 0;
    flex-direction: column;
  }
}

.title {
  /* Font */
  color: var(--contentColorPrimary);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;

  @media (--viewportSmall) {
    text-overflow: clip;
    -webkit-line-clamp: 3;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.distanceInfo,
.authorInfo {
  /* Font */
  color:  var(--contentColorPrimary);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportSmall) {
    font-weight: 400;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.distanceContainer {
  display: flex;
  gap: 4px;
  align-items: flex-start;
  margin-top: 4px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.withoutHomeDeliver {
  height: 18px;
  width: 18px;
}

.homeDeliverInfo {
  z-index: 100;
  border-radius: 4px;
  background: #001018;
  padding: 2px 6px;
  text-align: center;
  color:  #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  margin: 0 0 4px 0;
  display: inline-flex;
}
