@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
    flex: 1;
  }
}

.modalButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;

  @media (--viewportLarge) {
    width: 241px;
    margin: 24px 0 38px;
  }
}

.headerWrapper {
  @media (--viewportLarge) {
    display: flex;
    align-items: center;
  }
}

.label {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 500;
  margin: 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px;
  }
}

.itemWrapper {
  margin-top: 24px;
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.name {
  padding: 5px 0 5px 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  @media (--viewportMedium) {
    padding: 4px 0 10px 0;
    font-size: 20px;
  }
}

.image {
  height: 60px;
  width: 60px;
  object-fit: cover;
  display: inline-block;
  margin: 5px;
}

.info {
  color: #b2b2b2;
  margin-top: 24px;
  line-height: 24px;
  font-weight: 400;
  font-size: 14px;

  @media (--viewportLarge) {
    margin: 0;
  }
}

.buttonSubmitModal {
  padding: 24px 0;
  @media (--viewportMedium) {
    padding: 32px 0 0;
  }
}

.errorMessage {
  margin-top: 24px;
  color: #ff0000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;

  @media (--viewportMedium) {
    margin-top: 32px;
    font-size: 20px;
  }
}

.productInfo {
  border: 1px solid #A6A6A6;
  padding: 24px;
  margin-bottom: 36px;
  font-size: 16px;
  border-radius: 10px;
  color: #7D7D7D;
}

.btnSection {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 48px;
  }
}
