@import '../../marketplace.css';

.container{
 margin-top: 48px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColor);
  font-weight: 700;
  display: block;
  margin: 0 0 6px 0;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (--viewportSmall) {
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}

.dateContainer .btnShowExceptions {
  @media (--viewportSmall) {
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
    max-height: 42px;
  }
}

.dayContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
}

.info {
  margin-left: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius: 2px;
  padding: 2px 8px;
}

.opened {
  color: var(--successColor);
  background: #CBF0C7;
}

.closed {
  color: var(--failColor);
  background: #FEC9CB;
}

.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.error {
  color: var(--failColor);
  margin: 0;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.day {
  border-radius: 5px;
  text-align: left;
  display: flex;
  width: 100%;
  border: none;
  padding: 4px 0;
  font-size: 16px;
  align-items: center;

  @media (--viewportSmall) {
    border: 1px solid var(--marketplaceColor);
    width: fit-content;
    padding: 6px 12px;
    font-size: 14px;
  }
}

.deleteButton {
  margin-left: auto;
  border: none;
  padding-left: 16px;

  @media (--viewportSmall) {
    margin-left: 0;
  }
}

.deleteButton svg {
  width: 16px;
  height: 16px;
}

.deleteButton:hover svg {
  stroke: var(--marketplaceColorDark);
  cursor: pointer;
}

.text {
  font-size: 16px;
}
