@import '../../../marketplace.css';

.summaryCard {
    padding: 16px;
    background-color: #fff;
    /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); */
    height: 100%;
    border-top: 1px solid #b2b2b2;

    @media(--viewportMedium) {
        border-top: unset;
        width: 50%;
        padding: 24px;
    }

    @media(--viewportLargeCart) {
        flex-shrink: 0;
        width: 473px;
    }
}

.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0;

    @media(--viewportMedium) {
        font-size: 20px;
        line-height: 28px;
    }
}

.productSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0 16px 0;
    border-bottom: 1px solid var(--matterColorAnti);
}

.rowContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding-top: 4px;
    gap: 24px;
}

.productText {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin: 0;

    @media(--viewportMedium) {
        font-size: 16px;
        line-height: 24px;
    }
}

.togetherSection {
    display: flex;
    flex-direction: column;
    padding: 0px;
    padding: 16px 0 0 0;

    @media(--viewportSmall) {
        padding: 16px 0;
    }
}

.togetherText {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
}

.bookButton {
    @apply --marketplaceButtonStylesPrimary;
    width: 100%;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    padding: 0;
}

.addToUserButton {
    @apply --marketplaceButtonStylesPrimary;
    width: 100%;
    color: var(--marketplaceColor);
    cursor: pointer;
    margin-top: 20px;
    padding: 0;
    background-color: #FFF;
    border: 1px solid var(--marketplaceColor);
    margin-top: 10px;

    &:hover {
        background-color: #ECEDF8;
    }
}

.discount {
    color: var(--marketplaceColor);
}
