@import '../../marketplace.css';

.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.error {
  color: var(--failColor);
  margin: 0;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
}
