@import '../../marketplace.css';

.main {
    background-color: #fff;

    @media(--viewportMedium) {
        width: 100%;
        padding: 32px;
    }
}

.cartContainer {
    background-color: #f1f1f1;
    @media(--viewportMedium) {
        width: 100%;
        background-color: #FFF;
    }

    @media(--viewportLargeCart) {
        width: 1284px;
        margin: 0 auto;
    }
}

.listItemsLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    animation-duration: 1s;
    animation-name: loadingSpinnerFadeIn;
    animation-fill-mode: forwards;
}

.orderContainer {
    &:first-child {
      margin-top: 0;
    }

    @media (--viewportLargeCart) {
        width:100%;
        padding: 16px;
    }

    @media (min-width: 1316px) {
        padding: 0;
    }
}

.noResultText {
    margin: 16px 0 0 16px;

    @media(--viewportMedium) {
        margin: 0;
    }
}

.product {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & img {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }

    & p {
        font-size: 16px;
        margin: 0;
        margin-left: 8px;
    }
}

.userField {
    margin-top: 32px;
}

.error {
    color: var(--failColor);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
}

.success {
    color: var(--successColor);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    margin-top: 60px;
}

.icon {
    stroke: var(--successColor);
    margin-right: 12px;
}

.summaryCard {
  padding: 16px;
  background-color: #fff;
  height: 100%;
  border-top: 1px solid #b2b2b2;

  & > div > p:first-child {
      padding-top: 0;
  }

  @media(--viewportMedium) {
    border-top: unset;
    width: 50%;
    padding: 0 24px 24px 24px;
  }

  @media(--viewportLargeCart) {
    flex-shrink: 0;
    width: 473px;
  }
}
