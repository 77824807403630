@import '../../marketplace.css';

.root {
}

.tabs {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 55px;
    align-items: flex-end;
    padding: 13px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.listingPanel {
  width: 100%;
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #515151;
  }
}

.listingCards {
  padding: 0 24px 96px 24px;

  @media (--viewportLarge) {
    padding: 0 0 36px 0;
  }
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 768px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin: auto 24px 0 24px;

  @media (--viewportLarge) {
    margin: auto 36px 0 36px;
  }
}

.headerWrapper {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.ownListingsFilterWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.ownListingsPhraseFilterWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 14px;

  & input {
    border-radius: 4px 0 0 4px;
    width: calc(100% - 42px);

    @media (--viewportMedium) {
      width: 200px;
    }
  }
}

.providerSelector {
  margin-bottom: 20px;
}

.containerLabel {
  padding: 0;
  width: 300px;
}

.containerLabel label{
  padding: 0;
}

.containerLabel input{
  width: 100%;
}

.fields {
  display: grid;
  grid-gap: 10px;
  margin-bottom: 10px;
  grid-template-columns: 1fr 1fr;

  & select {
    width: 100%;
    height: 42px;

    @media (--viewportMedium) {
      width: 200px;
    }
  }
}

.submitBtn {
  height: 42px;
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  width: 42px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.submitBtn:hover {
  background-color: var(--marketplaceColorDark);

  & svg {
    & :nth-child(2) {
      fill: var(--marketplaceColorDark);
      stroke: var(--marketplaceColorDark);
    }
  }
}
