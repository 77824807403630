@import '../../../../../marketplace.css';

.btnClear {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border: none;

    @media(--viewportMedium) {
        font-weight: 700;
    }
}

.btnClear:hover {
    cursor: pointer;
}

.btnClear {
    color: #3643BA;
    background-color: #FFFFFF;
    text-decoration: underline;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.btnClear:hover,
.btnClear:active {
    color: var(--marketplaceColorDark);
}
