@import '../../../marketplace.css';

.listItem {
  padding: 12px 0;
  border-bottom: 1px solid var(--matterColorNegative);
  transition: ease-in-out 0.2s;

  @media (--viewportLarge) {
    padding-top: 24px;
    padding-bottom: 15px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.listItem:last-child {
  border-bottom: none;
}

.text {
  color: var(--matterColorAnti);
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.code {
  font-size: 16px;
  color: var(--matterColorDar);
}

.actionContainer {
  padding: 16px 0;
  display: flex;
  flex-direction: column-reverse;
  grid-gap: 24px;
  gap: 24px;
  justify-content: space-between;
  padding: 0;

  @media (--viewportSmall) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.checkbox>input[type=checkbox] {
  visibility: hidden;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (--viewportSmall) {
    padding: 0;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

.button {
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @media(--viewportSmall) {
    width: 100%;
    padding: 14px 24px;
  }

  @media(--viewportMedium) {
    width: auto;
  }
}

.bannedText,
.discount {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius: 2px;
  padding: 4px 8px;
  text-align: center;
  display: inline-block;
}

.bannedText {
  color: #E3262F;
  background: #FEC9CB;
}

.discount {
  padding: 4px 0;
  color: #23A942;
  font-weight: 500;
  width: 200px;
  font-size: 14px;
  text-align: left;

  @media(--viewportSmall) {
    padding: 4px 8px;
  }
}

.link {
  font-size: 14px;
  white-space: nowrap;
  padding-top: 8px;
}

.arrow>svg {
  stroke: var(--marketplaceColor);
  margin: 0 auto;
  display: block;
}

.arrow {
  display: flex;
  align-items: center;
  height: 100%;

  @media(--viewportSmall) {
    padding-right: 12px;
  }
}

.arrow:hover>svg {
  stroke: var(--bannedColorLight);
  cursor: pointer;
}

.detailContainer {
  @media (--viewportSmall) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    margin-bottom: 20px;
  }
}

.boldText {
  color: var(--matterColor);
}

.active,
.inactive {
  line-height: 18px;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  font-weight: 400;
}

.inactive {
  color: #ffaa00;
  background: #feeac2;
}

.active {
  color: #3643BA;
  background: #BEDEEF;
}

.expandedContainer {
  transition: ease-in-out 0.2s;
}

.upperCase {
  text-transform: uppercase;
}
