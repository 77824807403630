.root {
  @media (--viewportMedium) {
    margin-top: -100px;
  }
}

.title {
  margin-top: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  width: 100%;
  color: var(--marketplaceColor);

  margin: 20px 0 80px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    margin: 20px 0 80px 0;
    font-size: 26px;
    line-height: 36px;
  }
}

.partnerLink {
  margin-left: 20px;
  margin-right: 20px;
  width: 120px;

  @media (--viewportMedium) {
    margin-left: 40px;
    margin-right: 40px;
  }

  & img {
    width: 100%;
  }
}

.slider {
  display: flex;
  align-items: center;
}

:global(.slick-track) {
  display: flex;
  height: 100%;
}

:global(.slick-list) {
  overflow: hidden;
  width: calc(100% + 16px);
  margin: 0 -8px;
  max-width: 1128px;
}

.slider div[tabindex]{
  float: none;
  flex-grow: 1;
  display: flex;
  padding: 0 8px;
}

.slider div[tabindex]>div {
  display: flex;
  width: 100%;
}

.carousel {
  /* width: 43px;
  /* height: 43px;
  background: var(--marketplaceColor);
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25); */
  display: flex; 
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.carousel:before {
  content: none;
}

.carousel.icon {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  background: var(--marketplaceColor);
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    width: 40px;
    height: 40px;
  }
}

.right {
  position: absolute;
  right: 0;
  transform: translate(50%, -50%);
}
  
.left {
  transform: rotate(180deg);
}

.left > svg {
  margin-right: 1px;
}