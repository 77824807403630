@import '../../marketplace.css';

.container {
    @media(--viewportMedium) {
        max-width: 1284px;
        margin: 0 auto;
        padding-top: 24px;
    }
}

.navigation {
    padding: 16px 16px 0 16px;
    display: flex;
    align-items: center;

    @media(--viewportMedium) {
        padding: 0 16px 16px 16px;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media(--viewportMedium) {
        flex-direction: row;
    }
}

.actionButtons {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--matterColor);
}

.arrow {
    padding-left: 1px;
    margin: 0 8px;
}

.link:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
}

.button {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    align-self: flex-start;
    padding-bottom: 16px;

    @media(--viewportMedium) {
        align-self: center;
        padding: 16px;
    }
}

.messagesSection {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    @media(--viewportLarge) {
        padding-top: 40px;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 48px;
    }
}

.feedContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @media (--viewportLarge) {
        flex-grow: 2;
    }
}

.sendMessageContainer {
    @media (--viewportLarge) {
        flex-grow: 1;
    }
}

.protocolBtn {
    text-align: center;
    padding: 16px 32px;
}

.partialCancelBtn {
    margin-top: 16px;
    padding: 16px 32px;
}

.centralRental {
  background-color: beige;
  padding: 8px;
  margin-top: 20px;

  & h4 {
      margin: 0;
      margin-bottom: 16px;
  }
}

.extendRentalBtn {
  padding-left: 24px;
  padding-right: 24px;
}

.cancelled {
  color: var(--failColor);
  background: #FEC9CB;
}

.delivered {
  color: var(--successColor);
  background: #CBF0C7;
}

.inProgress {
  color: var(--marketplaceColor);
  background: var(--marketplaceColorLight);
}

.pending {
  color: #fa0;
  background: #feeac2;
}

.expired {
  color:  #979595;
  background: #e5e5e5;
}

.medicoverContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.orderMedicoverContainer {
  justify-content: flex-start;
}
