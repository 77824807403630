@import '../../marketplace.css';

.popupSize {
  padding: 0 10px 7px 10px;
}

.availabilityFieldsContainer {
  padding: 0 13px;
}

.availabilityFieldsContainer h3 {
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 5px;
}

.availabilityFieldsContainer label span {
  font-size: 16px;
}

.partialAvailabilityRadio {
  display: flex;
}

.minDurationField input {
  width: 30px;
  font-size: 16px;
}

.minDurationField input {
  text-align: center;
  padding-bottom: 2px !important;
  border-bottom: 0;
}

.minDurationField input::-webkit-outer-spin-button,
.minDurationField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.availabilityRadioButton label {
  padding: 0;
}

.availabilityRadioButton label span {
  font-size: 14px;
}

.dayField {
  display: flex;
}

.dayButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border-radius: 3px;
  background: #2ecc71;
  color: #fff;
  width: 30px;
  height: 30px;

  @media (--viewportMedium) {
    width: 20px;
  }
}

.dayButton:focus {
  outline: none;
}

.notSelected input {
  color: #D3D3D3;
}