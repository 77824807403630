@import '../../../marketplace.css';

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 20px 0;
}

.submitBtn {
  margin-top: 0;

  @media (--viewportMedium) {
    width: fit-content;
    padding-left: 32px;
    padding-right: 32px;
    max-height: 42px;
    align-self: start;
  }
}
