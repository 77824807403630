@import '../../marketplace.css';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  height: 52px;

  @media(--viewportMedium) {
    height: 58px;
  }

  @media(--viewportMedium) {
    gap: 16px;
  }
}

.prev,
.next {
  display: block;

  /* Dimensions */
  padding-top: 13px;
  padding-bottom: 13px;

  /* Reset <a> tag sizing */
  line-height: 0;
  font-size: 0;
}

.prev {
  /* Minimum touch size 44px (svg width + padding) */
  padding-right: 33px;
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
  padding-left: 33px;
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.disabled,
.disabled:hover {
  fill: var(--matterColorAnti);
  stroke: var(--matterColorAnti);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  height: 36px;

  @media(--viewportSmall) {
    height: 42px;
  }
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
}

.toPageLink {
  /* Dimensions */
  padding: 0 5px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Color for svg icons */
  color: var(--paginationColor);

  @media(--viewportSmall) {
    width: 42px;
    height: 42px;
  }

  &:hover {
    text-decoration: none;
    background-color: #E1E3F5;
    border-radius: 4px;
  }
}

.currentPage {
  /* Dimensions */
  padding: 0 5px;
  background-color: var(--marketplaceColor);
  width: 36px;
  height: 36px;
  border-radius: 4px;

  /* Color */
  color: var(--matterColorLight);

  &:hover {
    background-color: var(--marketplaceColorDark);
  }

  @media(--viewportSmall) {
    width: 42px;
    height: 42px;
  }
}

.paginationGap {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--paginationColor);

  /* Ellipsis is raised from baseline */
  line-height: 13px;
}
