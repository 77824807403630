@import '../../marketplace.css';

.title {
  margin-top: 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  width: 100%;
  color: var(--marketplaceColor);

  margin: 40px 0 80px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    margin: 100px 0 80px 0;
    font-size: 26px;
    line-height: 36px;
  }
}

.steps {
  display: flex;
  justify-content: center;
  overflow: auto;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step + .step {
  margin-left: 20px;
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #F0F7FF;

  @media (--viewportMedium) {
    width: 200px;
    height: 200px;
  }
}

.image {
  margin: 0 auto;
  width: 40px;
  display: block;
  
  @media (--viewportMedium) {
    width: 80px;
  }
}

.stepTitle {
  position: relative;
  margin-top: 40px;
  margin-bottom: 27px;
  font-size: 18px;
  line-height: 22px;
  max-height: 44px;
  text-align: center;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 40px;
    margin-bottom: 40px;
    color: #fff;
  }
}

.stepTitle:before {
  display: none;

  @media (--viewportMedium) {
    display: block;
    position: absolute;
    left: -20px;
    right: -20px;
    top: -12px;
    bottom: -12px;
    background-color: var(--marketplaceColor);
    transform: skew(-20deg);
    z-index: -1;
    content: '';
  }
}

.createListingLink {
  margin-top: 36px;

  @media (--viewportMedium) {
    margin-top: 52px;
  }
}

.step p {
  display: none;

  @media (--viewportMedium) {
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 0;
    color: #666;
    max-width: 265px;
  }
}