.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  & div {
    width: 100%;
  }

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: unset;

    & div {
      width: 50%;
    }
  }
}

.textWrapper {
  margin: auto 0;
}

.title {
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: var(--marketplaceColor);
  margin-bottom: 45px;
  margin-top: 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.text {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 45px;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
    max-width: 450px;
  }
}

.btn {
  background: var(--marketplaceColor);
  border-radius: 9px;
  color: #FFF;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  padding: 12px;
  margin: 0 auto;
  display: block;
  width: 200px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.images {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  
  & img {
    width: 100%;
    height: auto;
    align-self: flex-start;
  }

  @media (--viewportMedium) {
    margin-top: 0;

    & img {
      width: auto;
      max-height: 400px;
    }
  }
}