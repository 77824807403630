@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  /* padding-top: 70px; */
  padding: 16px;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 650px;
    min-height: auto;
    height: auto;
    padding: 32px;

  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  color: var(--successColor);
  margin: 0;
  padding-top: 4px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  @media (--viewportMedium) {
    padding-top: 0;
  }
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.modalContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.buttonSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 16px;
  padding-top: 16px;

  @media(--viewportMedium) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.cancelButton {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: 100%;

  @media(--viewportMedium) {
    width: auto;
    padding: 14px 24px;
  }
}

.cancelButton:hover {
  color: var(--marketplaceColorDark);
  text-decoration: none;
}

.confirmButton {
  min-height: 40px;
  height: 48px;
  min-width: 164px;
  padding: 0 48px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  border-radius: 4px;

  @media(--viewportMedium) {
    width: auto;
  }
}

.closeButton {
  align-items: center;
}