@import '../../../marketplace.css';

.container {
  margin: 16px 0;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0px 8px 28px 0px rgba(136, 136, 136, 0.20);
}

.title {
  margin: 0;
  padding-bottom: 16px;
}

.formContainer {
  margin-bottom: 24px;

  @media (--viewportSmall) {
    display: flex;
    gap: 32px;
  }

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.selectContainer {
  @media (--viewportSmall) {
    width: 200px;
  }
}

.actionContainer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.cancelButton {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  height: 40px;
  padding: 0 12px;

  @media(--viewportMedium) {
    width: auto;
  }
}

.submitButton {
  min-height: 44px;
  height: 44px;
  min-width: 106px;
  padding: 0 32px;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  border-radius: 4px;
  font-weight: 500;
  line-height: 24px;

  @media(--viewportMedium) {
    width: auto;
  }
}

.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.error {
  color: var(--failColor);
  margin: 0;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.notificationContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.notification {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  font-weight: 400;
}
