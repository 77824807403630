@import '../../marketplace.css';

:root {
    /* Variables to change */
    --backgroundColorDisabled: var(--matterColorBright);
    --border: 1px solid var(--borderColorPrimary);
    --borderFocus: 1px solid var(--marketplaceColor);
    --borderDisabled: 1px solid var(--matterColorNegative);
    --borderFailed: 1px solid var(--failColor);
    --borderFocusFailed: 1px solid var(--failColor);
    --borderRadius: 4px;
    --boxShadow: none;
    --textColor: var(--matterColor);
    --textDisabledColor: var(--matterColorAnti);
    --textInvalidColor: var(--matterColorAnti);
    --errorColor: var(--failColor);
}

.container {
    position: relative;
    width: 100%;
    margin-top: 0;
    padding: 4px 0;
}

.validation {
    padding: 0 0 22px 0;
}

.select {
    border: var(--border);
    border-radius: var(--borderRadius);
    transition: border-color ease-in 0.1s;
    box-shadow: var(--boxShadow);
    color: var(--textColor);
}

.select:invalid {
    color: var(--textInvalidColor);
}

.select > option {
    color: var(--textColor);
}

.select > option:disabled {
    color: var(--textDisabledColor);
}

.select:focus {
    border: var(--borderFocus);
}

.select:disabled {
    transition: ease-in-out 0.1s;
    color: var(--textDisabledColor);
    background-color: var(--backgroundColorDisabled);
    border: var(--borderDisabled);
    cursor: not-allowed;
}

.select:-webkit-autofill,
.select:-webkit-autofill:hover,
.select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--textColor);
    -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
}

.selectError {
    border: var(--borderFailed);
}

.selectError:focus {
    border: var(--borderFocusFailed);
}

.text {
    font-size: 16px;
    line-height: 24px;
    color: var(--textColor);
    font-weight: 700;
    display: block;
    margin-bottom: 6px;
}

.error {
    font-size: 12px;
    line-height: 16px;
    color: var(--errorColor);
    position: absolute;
    bottom: 4px;
    font-weight: 400;
}

.select, .select option {
    -moz-white-space: pre-wrap;
    -o-white-space: pre-wrap;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .label {
    padding: 0;
}
