@import '../../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding: 32px;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
    margin: auto 0;
  }
}

.modalContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
}

/* Title of the modal */
.modalTitle {
  /* @apply --marketplaceModalTitleStyles; */
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding-bottom: 8px;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  /* @apply --marketplaceModalParagraphStyles; */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
