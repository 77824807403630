@import '../../marketplace.css';

.error {
  color: var(--failColor);
  margin: 16px 16px 16px 0;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.pagination {
  margin: auto 24px 0 24px;
}

.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.searchInputWrapper {
  padding-top: 6px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--matterColorAnti);
}
