@import '../../../marketplace.css';

.filter {
  width: 100%;

  @media(--viewportSmall) {
    min-width: 45%;
  }
}

.searchInputWrapper {
  gap: 0;
}

.errorForm {
  color: var(--failColor);
  font-size: 12px;
  margin: 0;
  font-weight: 400;
  line-height: 24px;
}
