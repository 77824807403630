@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  width: 100%;
  padding-bottom: 24px;

  @media (--viewportMedium) {
    padding-bottom: 0;
    position: relative;
    padding-top: calc(var(--topbarHeightDesktop) + 36px);
    min-height: var(--SearchPage_containerHeight);
  }
}

.mapPanel {
  @media (--viewportMedium) {
    /* Own layout settings */
    display: flex;
    height: 100%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: 50px;

    /* Fixed content needs width relative to viewport */
    width: 90vw;
    height: calc(100vh - 100px);
  }

  @media (--viewportLarge) {
    width: 90vw;
  }
}

.closeIconClassName {
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.closeButtonClassName {
  padding-top: 8px;
  padding-right: 8px;
}

.modalContainer {
  width: 90vw;
}

.modalClose {
  width: 0;
}
