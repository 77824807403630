@import './assets/sanitize.css';
@import './marketplace.css';
@import './datepicker.css';

/* ================ Fonts ================ */

body {
  font-family: Inter, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: var(--matterColorBright);
}

::selection {
  background: var(--marketplaceColor);
  /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor);
  /* Gecko Browsers */
  color: var(--matterColorLight);
}

a {
  @apply --marketplaceLinkStyles;
}

h1 {
  @apply --marketplaceH1FontStyles;
}

h2 {
  @apply --marketplaceH2FontStyles;
}

h3 {
  @apply --marketplaceH3FontStyles;
}

h4 {
  @apply --marketplaceH4FontStyles;
}

h5 {
  @apply --marketplaceH5FontStyles;
}

h6 {
  @apply --marketplaceH6FontStyles;
}

input,
textarea,
select {
  @apply --marketplaceDefaultInputFontStyles;
}

li {
  @apply --marketplaceDefaultFontStyles;
}

p,
pre {
  @apply --marketplaceBodyFontStyles;
}

/* ================ Normalisations ================ */

html {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

legend,
label {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

button {
  font-family: Helvetica, Arial, sans-serif;
}

select {
  @apply --marketplaceSelectStyles;
}

input {
  @apply --marketplaceInputStyles;
}

textarea {
  @apply --marketplaceInputStyles;
  width: 100%;

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: content-box;
  padding: 8px 12px 8px 12px;

  /* &::placeholder {
    padding: 0 0 10px 0;
  } */

  @media (--viewportMedium) {
    padding: 8px 12px 8px 12px;
  }
}

:global(.fontsLoaded) {
  & body {
    font-family: 'Inter', Helvetica, Arial, sans-serif;
  }

  & button {
    font-family: 'Inter', Helvetica, Arial, sans-serif;
  }
}