@import '../../marketplace.css';

.urlInput {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
  margin-bottom: 24px;
}
