@import '../../../marketplace.css';

.desktop {
    display: none;

    @media (--viewportMedium) {
        display: block;
    }
}

.mobile {
    @media (--viewportMedium) {
        display: none;
    }
}

.modalContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 16px;
    width: 100%;
    margin: 0;
    position: relative;
    background-color: #ffffff;
    border-radius: 2px;
    position: relative;
}

.modalScrollLayer {
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.modalCloseButton {
    background-color: transparent;
    color: #4a4a4a;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: calc(100 + 1);
    display: flex;
    align-items: flex-start;
    width: auto;
    padding: 16px;
    margin: 0;
    border: 0;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.inputRoot {
    width: 100%;
}

.textarea {
    @apply --marketplaceH4FontStyles;
    /* border-bottom-width: 0; */

    /* Avoid text going behind the submit button */
    padding: 12px;
    margin: 0;
    height: 140px;
    overflow-y: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;


    /* We need to remove horizontal paddings,
     since textarea uses content-box (autosize library fix). */
    /*width: calc(100% - 24px);*/
    border: 1px solid var(--matterColor);
    border-radius: 5px;

    @media (--viewportMedium) {
        margin: 0;
    }

    @media (--viewportLarge) {
        margin: 0;
        /*width: calc(100% - 24px);*/
    }
}

.textarea::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}


.submitContainer {
    width: 100%;
}

.errorContainer {
    display: block;
    flex: 1;
    text-align: right;
    padding-top: 4px;
}

.error {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--failColor);
    margin: 0;
}

.submitButton {
    margin-top: 24px;
    margin-bottom: 32px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    border-radius: 3px;
    min-height: 44px;
    text-transform: uppercase;

    @media (--viewportMedium) {
        margin-bottom: 0;
    }
}

.title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding-bottom: 24px;
    text-align: center;

    @media (--viewportMedium) {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        display: block;
        text-align: left;
        padding-bottom: 16px;
    }
}

.messageText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding-bottom: 4px;
}

.infoText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--matterColorAnti);
}

.contactButton {
    @apply --marketplaceLinkStyles;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;

    @media (--viewportMedium) {
        display: none;
    }
}
