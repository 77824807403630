@import '../../../marketplace.css';

.formContainer {
    margin: 32px 0;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    @media(--viewportMedium) {
        width: 600px;
        margin: 32px auto 64px auto;
        padding: 32px;
    }
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;

    @media(--viewportMedium) {
        font-size: 26px;
        line-height: 32px;
    }
}

.mainForm {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.checkboxLabel {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
}

.checkboxInput {
    width: auto;
}

.checkbox>input[type=checkbox] {
    visibility: hidden;
}

.checkbox {
    display: flex;
    justify-items: center;
    position: relative;
    padding: 0;
    margin-top: 4px;
    cursor: pointer;
    height: 16px;
}

.checkboxText {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid var(--matterColorAnti);
    border-radius: 4px;
}

.checkboxText>span {
    padding-left: 22px;
    line-height: 16px;
    position: relative;
    bottom: 30%;
    font-weight: 500;
}

.checkbox input:checked~.checkboxText {
    background-color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
}

.checkboxText:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked~.checkboxText:after {
    display: block;
}

.checkbox .checkboxText:after {
    left: 5px;
    bottom: 2px;
    width: 4px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.actionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 16px;
    padding-top: 16px;

    @media(--viewportMedium) {
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 24px;
    }
}

.submitButton {
    font-weight: 500;
    min-height: 44px;
    height: 44px;
    min-width: 130px;
    padding: 0 40px;
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
    border-radius: 4px;

    @media(--viewportMedium) {
        width: auto;
    }
}

.cancelButton {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 100%;

    @media(--viewportMedium) {
        width: auto;
        padding: 14px 24px;
    }
}

.error {
    color: var(--failColor);
    margin: 0;
    font-size: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.notificationContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.notification {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    font-weight: 400;
}
