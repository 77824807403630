@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 24px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  margin-top: 5px;
  margin-bottom: 14px;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #4A4A4A;

  @media (--viewportMedium) {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 14px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.timeSlotsError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.minBookingDaysInfo {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-top: -2px;
  margin-bottom: 14px;
  color: #4A4A4A;
  padding: 0 24px;

  @media(--viewportMedium) {
    padding: 0;
  }
}

.quantitySelector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px 24px 24px;

  @media(--viewportMedium) {
    margin: 0;
    margin-bottom: 16px;
  }
}

.quantityInput {
  display: none;
}

.quantityButton {
  border: 1px solid #949494;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  color: #949494;
  line-height: 28px;
  font-size: 21px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    pointer-events: none;
    border: 1px solid #e7e7e7;
    color: #e7e7e7;
  }
}

.quantityWrapper {
  display: flex;
  align-items: center;
}

.quantityValue {
  color: #101010;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  margin: 0 12px;
  width: 20px;
  text-align: center;

  &.disabled {
    color: #e7e7e7;
  }
}

.quantityLabel {
  color: #101010;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}