@import '../../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding: 16px;
  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 567px;
    margin: auto 0;
    padding: 32px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 32px;
}

.modalContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportMedium) {
    gap: 32px;
  }
}

/* Title of the modal */
.modalTitle {
  /* @apply --marketplaceModalTitleStyles; */
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding-bottom: 8px;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  /* @apply --marketplaceModalParagraphStyles; */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.productContainer {
  display: flex;
  gap: 8px;

  @media(--viewportMedium) {
    gap: 16px;
    width: 100%;
  }
}

.rootForImage {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  align-self: center;
}

.confirmQuestion {
  padding-bottom: 16px;
}

.productContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  @media(--viewportSmall) {
    gap: 16px;
  }
}

.productName {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  text-transform: capitalize;
  /* padding-bottom: 8px; */
}

.productDetails {
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media(--viewportSmall) {
    flex-direction: row;
    align-items: center;
    gap: 80px;
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  padding-right: 24px;
}

.input {
  border: 1px solid var(--matterColorAnti);
  border-radius: 4px;
  max-width: 128px;
  padding: 6px 10px;
}

.input:focus {
  border: 1px solid var(--marketplaceColor);
}

.actionSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 32px 0 16px 0;

  @media(--viewportMedium) {
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 0;
    padding-top: 24px;
  }
}

.cancelBtn {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: 100%;

  @media(--viewportMedium) {
    width: auto;
    padding: 14px 24px;
  }
}

.cancelBtn:hover {
  color: var(--marketplaceColorDark);
  text-decoration: none;
}

.saveBtn {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  padding: 0 48px;
  border-radius: 4px;
  border: none;
  height: 44px;
  min-width: 180px;

  @media(--viewportMedium) {
    min-width: 180px;
    width: auto;
  }
}

.error {
  color: var(--attentionColor);
}
