@import '../../marketplace.css';

.root {
  box-sizing: border-box;
}

.title {
  margin-top: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  width: 100%;
  color: var(--marketplaceColor);

  margin: 0px 0 80px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    margin: 0px 0 80px 0;
    font-size: 26px;
    line-height: 36px;
  }
}

.reviews {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

:root {
  --star-size: 16px;
  --star-color: #fff;
  --star-background: #FFDF38;
}

.stars {
  --percent: calc(4.9 / 5 * 100%);
  
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
}

.stars.four {
  --percent: 80%;
}

.stars.five {
  --percent: 100%;
}
 
.stars.three {
  --percent: 60%;
}

.stars::before {
  content: '★★★★★';
  letter-spacing: 3px;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

.ratingsWrapper {
  display: flex;
  width: 100%;
  opacity: 1;
  position: relative;
  margin-bottom: 20px;
  align-items: center;
}

.rating {
  font-size: 16px;
  margin-left: 3px;
}

.reviewWrapper {
  display: flex;
  flex-flow: column nowrap;
}

.review {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  font-weight: 400;
}

.date {
  font-size: 14px;
}

.slider {
  display: flex;
  align-items: center;
  width: 100%;
}

:global(.slick-track) {
  display: flex;
  height: 100%;
}

:global(.slick-list) {
  overflow: hidden;
  width: 100%;
  max-width: 1128px;
}

.slider div[tabindex]{
  float: none;
  flex-grow: 1;
  display: flex;
  padding: 0 20px;
}

.slider div[tabindex]>div {
  display: flex;
  width: 100%;
}

.carousel {
  /* width: 43px;
  /* height: 43px;
  background: var(--marketplaceColor);
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25); */
  display: flex; 
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.carousel:before {
  content: none;
}

.carousel.icon {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  background: var(--marketplaceColor);
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    width: 40px;
    height: 40px;
  }
}

.right {
  position: absolute;
  right: 0;
  transform: translate(50%, -50%);
}
  
.left {
  transform: rotate(180deg);
}

.left > svg {
  margin-right: 1px;
}

.product {
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.author {
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}

.link {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  display: block;
  color: var(--marketplaceColor);
}