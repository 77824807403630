@import '../../../marketplace.css';

.formContainer {
    margin: 32px 0;
    border-radius: 8px;
    transition: ease-in-out 0.2s;

    @media(--viewportSmall) {
        padding: 16px;
        background-color: #fff;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }

    @media(--viewportMedium) {
        padding: 32px;
    }
}

.formContainer * {
    transition: ease-in-out 0.2s;
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    margin-top: 0;

    @media(--viewportMedium) {
        font-size: 26px;
        line-height: 32px;
    }
}

.subtitle {
    margin: 0;
    padding-bottom: 8px;
}

.mainForm {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.actionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 16px;
    padding-top: 16px;

    @media(--viewportMedium) {
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 24px;
    }
}

.submitButton {
    font-weight: 500;
    min-height: 44px;
    height: 44px;
    min-width: 130px;
    padding: 0 40px;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    border-radius: 4px;

    @media(--viewportMedium) {
        width: auto;
    }
}

.cancelButton {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 100%;

    @media(--viewportMedium) {
        width: auto;
        padding: 14px 24px;
    }
}

.error {
    color: var(--failColor);
    margin: 0;
    font-size: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.input {
    width: 160px;
}

.inputBox {
    width: 60px;
}

.errorForm {
    color: var(--failColor);
    font-size: 12px;
    margin: 0;
    font-weight: 400;
    line-height: 24px;
}

.disabledCheckbox {
  & * {
    cursor: not-allowed;
  }

  & span:first-child {
    color: var(--matterColorAnti);
  }

  & span:last-child {
    background-color: var(--matterColorAnti) !important;
    border: 1px solid var(--matterColorAnti) !important;
  }
}
