@import '../../../marketplace.css';

.summary {
    padding: 16px;
    background-color: #fff;
    height: auto;
    margin-top: 24px;

    @media(--viewportMedium) {
        padding: 24px;
        min-width: 400px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }

    @media(--viewportLargeCart) {
        flex-shrink: 0;
        width: 473px;
    }
}

.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 0;

    @media(--viewportMedium) {
        font-size: 20px;
        line-height: 24px;
    }
}

.productSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0 16px 0;
    border-bottom: 1px solid var(--matterColorAnti);
}

.productContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding-top: 4px;
    gap: 24px;
}

.termText, .productText {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin: 0;

    @media(--viewportMedium) {
        font-size: 16px;
        line-height: 24px;
    }
}

.togetherSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    padding: 16px 0 0 0;

    @media(--viewportSmall) {
        padding: 16px 0;
    }
}

.togetherText {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
}

.promoDiscount {
    color: var(--successColor);
    font-weight: 500;
}

.discount {
    color: var(--marketplaceColor);
}