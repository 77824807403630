@import '../../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding: 32px;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
    margin: auto 0;
  }
}

.modalContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
}

.modalTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding-bottom: 8px;
}

.modalMessage {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.mainForm {
  transition: display 1s ease-in-out;;
}

.cancelButton {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  padding: 10px 24px;
  margin-bottom: 6px;
}

.cancelButton:hover {
  color: var(--marketplaceColorDark);
  text-decoration: none;
}

.error {
  color: var(--failColor);
  margin: 4px 0 8px 0;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.success {
  color: var(--successColor);
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  margin: 4px 0 8px 0;
  transition: display 2s ease-in-out;
}

.iconTick {
  margin-right: 8px;
}
