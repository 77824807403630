@import '../../marketplace.css';

.container {
    @media(--viewportMedium) {
        max-width: 1284px;
        margin: 0 auto;
        padding-top: 24px;
    }
}

.navigation {
    padding: 16px 16px 0 16px;
    display: flex;
    align-items: center;

    @media(--viewportMedium) {
        padding: 0 16px 16px 16px;
    }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(--viewportMedium) {
    flex-direction: row;
  }
}

.actionButtons {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--matterColor);
}

.arrow {
    padding-left: 1px;
    margin: 0 8px;
}

.link:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
}

.messagesSection {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    @media(--viewportLarge) {
        padding-top: 40px;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        column-gap: 48px;
    }
}

.feedContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @media (--viewportLarge) {
        flex-grow: 2;
    }
}

.cancelled {
  color: var(--failColor);
  background: #FEC9CB;
}

.delivered {
  color: var(--successColor);
  background: #CBF0C7;
}

.inProgress {
  color: var(--marketplaceColor);
  background: var(--marketplaceColorLight);
}

.pending {
  color: #fa0;
  background: #feeac2;
}

.expired {
  color:  #979595;
  background: #e5e5e5;
}

.serviceFeeContainer {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    gap: 16px;
  }
}

.serviceFeeWrapper {
  @media (--viewportLarge) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 2fr 1fr;
    grid-column-gap: 48px;
    column-gap: 48px;
  }
}

.relatedTransactions {
  padding: 16px;
  margin: 0;
}

.actionButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.actionButton {
  padding: 16px;
}

.cancelServiceFee {
  border: 1px solid var(--failColor);
  background-color: var(--failColor);
  color: #FFF;
}

.cancelServiceFee:hover {
  border: 1px solid var(--attentionColor);
  background-color: var(--attentionColor);
}
