@import '../../marketplace.css';

.warningContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  gap: 6px;
  background: #FEC9CB;
  border: 1px solid var( --failColor);
  border-radius: 4px;
  margin: 16px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.warningContent {
  display: flex;
  align-items: center;
  gap: 6px;
}

.warningText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  flex-shrink: 1;
}

.linkToProfile {
  font-size: 14px;
  line-height: 20px;
}
