@import '../../../marketplace.css';

.cardContainer {
    margin: 24px 0;
    border-radius: 8px;
    transition: ease-in-out 0.2s;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--matterColorNegative);
    padding: 16px;

    @media(--viewportSmall) {
        padding: 16px;
        background-color: #fff;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        border: none;
    }

    @media(--viewportMedium) {
        padding: 32px;
    }
}

.heading {
    display: flex;
    justify-content: space-between;
}

.list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 8px;
    margin: 0;

    @media (--viewportSmall) {
        flex-direction: row;
    }
}

.closeBtn {
    width: fit-content;
    align-self: flex-end;
    border: none;
}

.closeBtn svg {
    stroke: var(--matterColor)
}

.closeBtn:hover {
    cursor: pointer;
}

.closeBtn:hover svg {
    stroke: var(--matterColorDark)
}

.listItem {

    @media (--viewportSmall) {
        width: 45%;
    }

    @media (--viewportMedium) {
        width: 32%;
    }
}

.code {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: var(--matterColorDar);
}

.discount {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border-radius: 4px;
    padding: 4px 8px;
    text-align: center;
    display: inline-block;
    color: #23A942;
    background: #CBF0C7;
    font-weight: 500;
    width: 100px;
    height: fit-content;

    @media(--viewportSmall) {
        min-width: 80px;
        width: auto;
    }
}

.text {
    color: var(--matterColorAnti);
    font-size: 16px;
    line-height: 22px;
    margin: 0;
}

.cartTitle {
    color: var(--successColor);
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.iconTick {
    margin-right: 8px;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media(--viewportSmall) {
        flex-direction: row;
        gap: 16px;
    }
}
