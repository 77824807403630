@import '../../marketplace.css';

.colorBackground {
  background: #f3f3f3;
}

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    margin-bottom: 19px;
  }
}

.title {
  margin-top: 0;

  @media (--viewportMedium) {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #515151;
  }
}

.title small {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  margin-left: 12px;
}

.lastUpdate {
  font-size: 12px;
  font-weight: 400;
}

.filters {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  @media (--viewportMedium) {
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 40px;
  }
}
