@import '../../marketplace.css';

.noResultsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

.searchIcon {
    height: 100px;
    margin-bottom: 20px;
}

.noResultsHeader {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #4A4A4A;
    margin-top: 0;
    margin-bottom: 20px;
    max-width: 380px;
    text-align: center;
}

.noResultsText {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #4A4A4A;
    margin-top: 0;
    max-width: 380px;
    text-align: center;
}

.noResultsContainer {
    max-width: 360px;
}

.noResultsInput {
    background: #FFFFFF;
    box-sizing: border-box;
}

.noResultsTextArea {
    resize: none;
    background: #FFFFFF;
    box-sizing: border-box;
}

.noResultsBtn {
    background: var(--marketplaceColor);
    border: none;
    border-radius: 4px;
    color: #FFF;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    padding: 10px 24px;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
}
