@import '../../../marketplace.css';

.container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media(--viewportMedium) {
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 24px;
        row-gap: 0;
    }
}

.filterWrapper label {
    font-weight: 500;
    font-size: 16px;
}

.filterWrapper select,
.filterWrapper.filterWrapper input {
    font-size: 16px;
}

.filterWrapper+.filterWrapper {
    margin-top: 10px;

    @media (--viewportMedium) {
        margin-top: 0;
    }
}

.filterWrapper {
    min-width: 240px;
    flex-grow: 1;
    position: relative;
}

.title {
    margin: 0;
    padding-bottom: 8px;
}

.labelWithClearButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clearButton {
    border: 0;
    font-size: 12px;
    cursor: pointer;
    padding: 6px 0 2px;
    line-height: 18px;
}

.clearButton:focus {
    outline: none;
}

.clearButton:hover {
    text-decoration: underline;
}

.clearButton:disabled {
    color: var(--matterColorNegative);
    text-decoration: none;
    cursor: not-allowed;
}
