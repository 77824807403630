@import '../../../marketplace.css';


.wrapper {
  width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media(--viewportMedium) {
      flex-direction: row;
      column-gap: 24px;
      row-gap: 12px;
      flex-wrap: wrap;
    }
}

.checkboxContainer {
    width: 100%;
}

.filterWrapper label {
    font-weight: 500;
    font-size: 16px;
}

.filterWrapper select,
.filterWrapper.filterWrapper input {
    font-size: 16px;
}

.filterWrapper+.filterWrapper {
    margin-left: 0;
    margin-top: 10px;

    @media (--viewportMedium) {
        margin-left: 20px;
        margin-top: 0;
    }
}

.filterWrapper {
    min-width: 240px;
    flex-grow: 1;
    position: relative;
}

.title {
    margin: 0;
    padding-bottom: 8px;
}

.labelWithClearButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.clearButton {
    border: 0;
    font-size: 12px;
    cursor: pointer;
    padding: 6px 0 2px;
    line-height: 18px;
}

.clearButton:focus {
    outline: none;
}

.clearButton:hover {
    text-decoration: underline;
}

.clearButton:disabled {
    color: var(--matterColorNegative);
    text-decoration: none;
    cursor: not-allowed;
}

.dateInput input {
    padding: 8px 12px 8px 12px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.dateInput div:disabled {
    background-color: #F6F6F6 !important;
    border-radius: 10px !important;
    border: 1px solid #F6F6F6 !important;
    cursor: not-allowed;
}

.dateInput div input:disabled {
    font-style: normal !important;
    cursor: not-allowed;
}


.error {
    color: var(--failColor);
    font-size: 12px;
    margin: 0;
    font-weight: 400;
}
