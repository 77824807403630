@import '../../../marketplace.css';

.listItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
}

.listItem:last-child {
  border-bottom: none;
}

.transactionDetails {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    flex-direction: row;
    justify-content: space-between;
  }
}


.firstTransactionDetails {
  width: 100%;
  padding-bottom: 6px;
  padding-right: 16px;

  @media(--viewportSmall) {
    width: 70%;
  }
}

.secondTransactionDetails {
  width: 100%;

  @media(--viewportSmall) {
    width: 30%;
    text-align: right;
  }
}

.firstTransactionDetails p,
.secondTransactionDetails p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColorAnti);
  hyphens: auto;

  @media (--viewportSmall) {
    font-size: 16px;
    line-height: 24px;
  }
}

.firstTransactionDetails p:first-child {
  color: var(--matterColor);
}

.date {
  font-weight: 500;

  @media(--viewportSmall) {
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }

  @media(--viewportMedium) {
    font-size: 16px;
    display: block;
  }
}

.link {
  font-size: 14px;
  white-space: nowrap;
  padding-top: 8px;
}

.firstTransactionDetails .providerName {
  color: var(--successColorDark);
  text-transform: uppercase;
}

.providerCountry {
  text-transform: uppercase;
  font-weight: 400;
}

.warning {
  color: black !important;
  background-color: #ffc107;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px !important;
  margin-top: 10px !important;
  padding: 2px 8px;
}

.lineThrough {
  text-decoration-line: line-through;
}

.secondTransactionDetails>.accepted {
  color: var(--successColor);
}

.medicoverLogo {
  height: 16px;
  width: 120px;
}

.txHeader {
  display: flex;;
  align-items: center;
  font-size: 16px;
}