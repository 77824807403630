@import '../../../../marketplace.css';

.codeSection * {
  transition: ease-in-out 0.1s;
}

.title {
  margin: 0;
  padding-bottom: 8px;
}

.input {
  transition: ease-in-out 0.1s;

  @media(--viewportSmall) {
    width: 160px;
  }
}

.input:disabled {
  background-color: #F6F6F6;
  border-radius: 10px;
  cursor: not-allowed;
}

.label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.labelDisabled {
  color: var(--matterColorAnti);
  font-weight: 500;
}

.textButton {
  padding-right: 10px;
}

.codeButton {
  border-radius: 5px;
  text-align: left;
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--marketplaceColor);
  padding: 4px 8px;
  font-size: 16px;
}

.codeButton svg {
  width: 10px;
  height: 10px;
}

.codeButton:hover {
  color: var(--marketplaceColorDark);
  cursor: pointer;
}

.codeButton:hover svg {
  stroke: var(--marketplaceColorDark);
}

.codeButton:disabled {
  border: 1px solid #e5e5e5;
  color: var(--matterColorAnti);
  cursor: not-allowed;
}

.codeButton:disabled svg {
  stroke: var(--matterColorAnti);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 0 12px 0;

  @media(--viewportSmall) {
    padding: 12px 0 12px 22px;
    flex-direction: row;
    gap: 24px;
  }
}

.addCodeContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 0 0 0;

  @media(--viewportSmall) {
    padding: 12px 0 0 22px;
    flex-direction: row;
    gap: 24px;
  }
}

.nameInput:disabled {
  background-color: #F6F6F6;
  border-radius: 10px;
  cursor: not-allowed;
}

.codeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px 0 0 0;

  @media (--viewportSmall) {
    padding: 0 0 0 22px;
  }
}

.btn {
  width: auto;
  padding: 6px 12px;
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  border-radius: 6px;
  color: var(--matterColorBright);
  font-size: 16px;
  font-weight: 500;

  @media(--viewportSmall) {
    margin: 0 0 20px 0;
  }

  @media(--viewportMedium) {
    margin: 0 0 22px 0;
  }
}

.btn:hover {
  cursor: pointer;
  border: 1px solid var(--marketplaceColorDark);
  background-color: var(--marketplaceColorDark);
}

.btn:disabled {
  border: 1px solid var(--matterColorNegative);
  background-color: var(--matterColorNegative);
  color: #ffffff;
  cursor: not-allowed;
}

.errorForm,
.error {
  color: var(--failColor);
  font-size: 12px;
  margin: 0;
  font-weight: 400;
  line-height: 24px;
}

.error {
  @media(--viewportSmall) {
    padding-left: 22px;
  }
}
