@import '../../marketplace.css';

.logoMobile {
  height: 24px;
  max-width: 190px;
  width: auto;

  @media(--viewportSmall) {
    max-width: none;
  }
}
