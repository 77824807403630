@import '../../marketplace.css';

.mainWrapper {
  width: calc(100% - 48px);
  max-width: 720px;
  margin: 24px auto;

  @media (--viewportMedium) {
    max-width: 650px;
    width: calc(100% - 72px);
    margin: 72px auto 36px auto;
  }
}

.mainWrapper p,
.mainWrapper li {
  font-weight: 400;
}

.mainHeader {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #4A4A4A;
  border-bottom: 4px solid var(--marketplaceColor);
  display: table;
  width: fit-content;
  padding-bottom: 10px;
  margin-left: 30px;
}

.questionWrapper {
  border-bottom: 1px solid #E5E5E5;
  padding: 10px 30px;
  -webkit-tap-highlight-color: transparent;

  &:last-child {
    border-bottom: none;
  }

  & h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #4A4A4A;
    display: block;
    width: 90%;
  }

  & p,
  & li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #4A4A4A;
  }
}

.questionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.notOpen {
  cursor: pointer;
}

.reversed {
  transition-duration: 0.3s;
  transition-property: transform;
  transform: rotate(180deg);
}

.answer {
  text-align: justify;
}

.icon {
  stroke: var(--marketplaceColor);
  width: 16px;
  height: 16px;
}
