@import '../../marketplace.css';

.container {
  padding-bottom: 24px;
  position: relative;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColor);
  font-weight: 700;
  display: block;
  margin: 0 0 6px 0;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.error {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--failColor);
  margin: 0;
  padding-top: 8px;
}

.wrapper {
  width: fit-content;
}
