@import '../../marketplace.css';

.dateRangePickerWrapper {
  position: relative;
  z-index: 1;
}

.dateRangePickerPlaceholder {
  border-bottom: 1px solid #B1B1B1;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    border-bottom-color: #4A4A4A;
  }
}

.datesSeparator {
  margin-left: 6px;
  margin-right: 6px;
}

.arrow {
  margin-left: 10px;
}

.dateRangePicker {
  background-color: #FFF;
  position: absolute;
  right: 0;
  box-shadow: 0 2px 6px rgba(0,0,0,.05), 0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px;
  width: 300px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  & :global(.DayPicker__horizontal) {
    align-self: center;
  }
}

.footer {
  padding: 0 23px 20px;
}

.submitButton {
  height: 27px;
  background: #3882BD;
  border-radius: 13.5px;
  line-height: 27px;
  color: #fff;
  padding: 0 20px;
  font-size: 14px;
  border: 0;
  outline: 0;

  transition: all .2s ease-in;
}

.submitButton:hover {
  background-color: #00557f;
  cursor: pointer;
}

.comparison {
  font-size: 14px;
  margin: 0;
  line-height: 14px;
  margin-left: 20px;
}

.comparisonCalendar {
  align-self: center;

  & :global(.CalendarDay__selected) {
    background-color: #F5B400;
    border-color: #FFD25A;
  }

  & :global(.CalendarDay__selected_span) {
    background-color: #FFC933;
    border-color: #FFD25A;
  }
}

.skeleton {
  height: 200px;
  width: 198px;
  margin: 8px 0;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background: linear-gradient( 90deg, transparent, rgba(0, 0, 0, 0.04), transparent );
  animation: shimmer 2s linear 0.5s infinite;
  content: '';
}

.compareContainer {
  margin: 8px 20px 0 20px;
}

.text {
  margin: 0;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}