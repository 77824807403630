@import '../../marketplace.css';

.container {
    margin: 8px 0;
}

.titleContainer {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.titleContainer:hover {
    cursor: pointer;
}

.title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding-bottom: 4px;
}

.contentContainer {
    padding: 8px 0;
}
