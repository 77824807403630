.wrapper {
  position: relative;
}

.suggestionsWrapper {
  position: absolute;
  margin-top: -12px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);

  @media (--viewportMedium) {
    width: calc(100% - 8px);
    margin-top: 0;
  }

  & ul {
    margin-bottom: 0;
  }
}

.highlightedSuggestion {
  background-color: var(--marketplaceColorLight);
}

.findProductsInfo {
  font-style: italic;
  line-height: 20px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
