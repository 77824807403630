@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TabNav_linkWidth: 240px;
  --TabNav_linkBorderWidth: 3px;
}

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: flex;
  align-items: center;
  white-space: nowrap;

  /* Font */
  @apply --marketplaceTabNavFontStyles;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  color: #7D7D7D;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  & path {
    fill: #7D7D7D;
  }

  & svg {
    margin-right: 16px;
  }

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);

    & path {
      fill: var(--matterColorDark);
    }
  }

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 4px;

    &:hover {
      border-right: 3px solid var(--matterColorDark);
    }
  }
}

.selectedLink {
  border-bottom-color: var(--matterColorDark);
  color: var(--marketplaceColor);

  & path {
    fill: var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    background-color: var(--activeColor);
    border-radius: 25px 0 0 25px;
    border-right: 3px solid var(--marketplaceColor);
    width: var(--TabNav_linkWidth);

    &:hover {
      border-right: 3px solid var(--marketplaceColor);
      color: var(--marketplaceColor);

      & path {
        fill: var(--marketplaceColor);
      }
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
