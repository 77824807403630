@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.title {
  @apply --EditListingDescriptionForm_formMargins;
  margin-bottom: 4px;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.category, .categoryDisabled {
  @apply --EditListingDescriptionForm_formMargins;
  margin-bottom: 4px;
}

.categoryDisabled label {
  color: var(--matterColorAnti);
}

.categoryDisabled select:disabled {
  transition: ease-in-out 0.1s;
  cursor:not-allowed;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.priceInput, .customPriceInput {
  margin-bottom: 4px;
}

.customPriceInput {
  margin-top: 20px;
}

.infoWrapper {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.infoWrapper svg {
  min-width: 14px;
  margin-top: 2px;
}

.infoText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8996A2;
  margin: 0;
}

.serviceDays {
  margin-bottom: 4px;
}

.modalBtn {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    width: auto;
    padding: 0 36px;
  }
}

.consentLabel {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;

  @media (--viewportSmall) {
    line-height: 24px;
  }
}

.customPriceContainer {
  padding-bottom: 22px;

  & div {
    padding-bottom: 22px;
  }
}

.checkmark {
  top: 2px;
}

.customMinDays {
  padding-bottom: 0;
}

.customMinDaysContainer {
  padding-bottom: 22px;
}