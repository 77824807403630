@import '../../../marketplace.css';

.actionButtons {
    padding-bottom: 12px;
}

.actionButtonWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.actionButton {
    padding: 16px;
}

.actionError {
    @apply --marketplaceH5FontStyles;
    color: var(--failColor);
    margin: 0 0 11px 0;

    @media (--viewportMedium) {
        margin: 0 0 10px 0;
    }

    @media (--viewportLarge) {
        margin: 0;
    }
}

.actionErrors {
    width: 100%;
    text-align: center;

    @media (--viewportLarge) {
        position: absolute;
        top: 151px;
    }
}

.cancelPickedUp {
    border: 1px solid var(--failColor);
    background-color: var(--failColor);
    color: #FFF;
}

.cancelPickedUp:hover {
  border: 1px solid var(--attentionColor);
  background-color: var(--attentionColor);
}

.greenBtn {
  background-color: var(--successColor);
}

.greenBtn:hover {
  background-color: var(--successColorDark);
}

.deliveryButton {
    background-color: #A74482;
    color: #FFF;
}