@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  display: flex;
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  align-items: center;
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.productsOrderLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;

  /* Content/contentInactive */

  color: #8996A2;
  margin-left: 12px;
  & span {
    margin-right: 4px;
  }
}

.mapIcon {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  /* background map image */
  background-image: url(./map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0 0 0 16px;
}

.mapIcon:hover {
  cursor: pointer;
}

.formContainer form{
  border: none;
}

.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.topbarSearch {
  padding: 0 16px;
  height: 40px;
  border: 1px solid var(--matterColorAnti);
  border-radius: 10px;
  margin: 0 0 0 16px;
  background-color: white;

  @media (--viewportLarge) {
    padding: 0 24px;
  }
}

.topbarSearch > div {
  height: 100%;
  margin: 0;
}

.topbarSearch > input {
  height: 100%;
}