@import '../../marketplace.css';

.root {
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.deliveryRoot {
  display: inline-block;
}

.menuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    background-color: inherit;
    outline: none;
    text-decoration: none;
  }
}

.homeDelivery {
  border: 1px solid var(--marketplaceColor);
  background-color: #FFF;

  &:hover {
    border: 1px solid var(--marketplaceColor);
    background-color: #adcfe7;
  }
}

.homeDeliveryOn {
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor) !important;
  color: #FFF;
}

.filterLabel,
.filterLabelSelected {
  @apply --marketplaceH3FontStyles;

  /* Baseline adjustment for label text */
  margin-top: 20px;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
  
}

.filterLabel {
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    display: none;
  }
}