@import '../../../marketplace.css';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;

  @media(--viewportMedium) {
    flex-direction: row;
  }
}

.serviceFeeContainer {
  margin-top: 16px;

  @media(--viewportMedium) {
    margin-bottom: 16px;
    margin-top: 0;
  }
}

.serviceFeeDetails {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.paymentLink {
  @apply(--marketplaceButtonStyles);
  width: 100%;
  background-color: var(--marketplaceColor);
  margin-top: 8px;
  color: #FFF;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(--viewportSmall) {
    max-width: 192px;
    margin-bottom: 20px;
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
