@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 12px;
  height: 42px;

  /* Border */
  border-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 42px;
  }
  @media (--viewportLarge) {
    padding: 8px 12px 8px 12px;
  }
}

.cardSuccess {
  border-color: var(--successColor);
}

.cardError {
  border-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
  font-weight: 400;
  margin-bottom: 8px;
  font-size: 16px;
  display: block;
}

.paymentHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 25px;
    color: #4A4A4A;
    margin-bottom: 6px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
    margin-top: 10px;
  }
}

.submitButton {
  margin-top: 0;
  border-radius: 4px;
  background-color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.submitButton:hover {
  border: 1px solid var(--marketplaceColorDark);
  background-color: var(--marketplaceColorDark);
  cursor: pointer;
}

.submitButton:disabled,
.submitButton:disabled:hover {
  background-color: var(--disabledButtonColorDark);
  border: 1px solid  var(--disabledButtonColor);
  cursor: not-allowed;
}

.missingStripeKey {
  color: var(--failColor);
}

.paymentSection {
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }

  & h3 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    margin-top: 0;
    display: block;

    @media (--viewportMedium) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.field {
  margin-bottom: 12px;
}

.fieldsRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  & .field {
    width: 100%;

    &:first-child {
      @media (--viewportMedium) {
        margin-right: 6px;
      }
    }

    &:last-child {
      @media (--viewportMedium) {
        margin-left: 6px;
      }
    }
  }
}

.infoBox {
  font-size: 14px;
  font-weight: 400;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  color: #545454;
  padding: 16px 24px;
}

.p24Info {
  text-align: center;
}

.methodSelector {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
}

.paymentMethodButton {
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #4A4A4A;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 18px 12px;

  &.paymentMethodSelected {
    border-color:#306CB8;
  }

  & span {
    margin-right: 16px;
  }

  &:hover {
    border-color:var(--marketplaceColor);
  }
}

.paymentMethodBtnDisabled:disabled {
  color: var(--matterColorAnti);
  justify-content: flex-start;
  gap: 16px;
  border-color: var(--matterColorAnti);
}

.paymentMethodBtnDisabled:disabled:hover {
  border-color: var(--matterColorAnti);
}

.paymentMethodBtnDisabled {
  position: relative;
}

.paymentMethodBtnDisabled .tooltiptext {
  visibility: hidden;
  width: 288px;
  background-color: #fff;
  color: var(--matterColor);
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 8px;
  border-radius: 4px;
  filter: drop-shadow(-4px 8px 16px rgba(0, 83, 125, 0.1));
  position: absolute;
  top: 100%;
  z-index: 1;
}

.paymentMethodBtnDisabled .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.paymentMethodBtnDisabled:disabled:hover .tooltiptext {
  visibility: visible;
}

.paymentImg {
  height: 20px;
  margin-left: 10px;
}

.boldedText {
  font-weight: 700;
}

.legalInfo {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}

.homeDeliveryInfo {
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: 500 !important;

  & .underlined {
    text-decoration: underline !important;
  }
}

.checkboxSaveInvoiceContainer,
.checkboxTermContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

.checkboxSaveInvoiceContainer {
  margin-top: 8px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.checkboxTermContainer {
  padding-bottom: 8px;

  @media (--viewportMedium) {
    padding-bottom: 16px;
  }
}

.openingHours {
  white-space: pre-line;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (--viewportSmall) {
    flex-direction: row;
    gap: 56px;
  }
}

.addressText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.addressTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
