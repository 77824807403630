@import '../../marketplace.css';

.root {}

.payoutPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media(--viewportSmall) {
    gap: 40px;
  }
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #515151;
  }
}

.pagination {
  margin: auto 24px 0 24px;

  @media (--viewportLarge) {
    margin: auto 36px 0 36px;
  }
}

.headerWrapper {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.select {
  background-color: #FFF;
  border: 1px solid var(--matterColorNegative);
}

.textResult,
.text {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;

  @media (--viewportSmall) {
    text-align: center;
  }
}

.textResult {
  color: var(--marketplaceColor);
}

.layoutWrapperMain {
  background-color: #f3f3f3;
}

.statusSection {
  background-color: #FFF;
  width: 100%;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  overflow-x: scroll;
  box-shadow: 0px 0px 15px rgba(160, 160, 160, 0.15);

  @media (--viewportSmall) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    border-radius: 20px;
  }
}

.statusContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #E8E8E8;

  @media (--viewportSmall) {
    border-bottom: none;
    flex-direction: column;
    padding: 16px;
  }

  @media (--viewportMedium) {
    border-left: 1px solid #E8E8E8;
    border-top: none;
  }
}

.statusContainer:first-child {
  @media (--viewportMedium) {
    border-left: none;
  }
}

.statusContainer:last-child {
  border-bottom: none;
}

.statusContainer:nth-last-child(2),
.statusContainer:last-child {
  @media (--viewportSmall) {
    border-top: 1px solid #E8E8E8;
  }

  @media (--viewportMedium) {
    border-top: none;
  }
}

.statusContainer:nth-child(2n) {
  @media (--viewportSmall) {
    border-left: 1px solid #E8E8E8;
  }
}

.btn {
  background: var(--marketplaceColor);
  border-radius: 13.5px;
  line-height: 27px;
  color: #fff;
  padding: 4px 20px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  outline: 0;
  transition: all .2s ease-in;
  align-self: flex-end;
}

.btn:hover,
.btn:active {
  background-color: var(--marketplaceColorDark);
  cursor: pointer;
}

.item {
  background-color: #FFF;
  width: 100%;
  border-radius: 20px;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.itemContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
  }

}

.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #515151;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.btnLink {
  font-size: 16px;
  line-height: 24px;
}
