@import '../../marketplace.css';

.root {
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;
  background-color: #F6F6F6;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  background-color: #F6F6F6;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  display: flex;
  width: 100%;
  padding: 0 24px;
  background-color: #FFF;
  /* justify-content: center; */
}

.activeImageWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  max-height: 450px;
  position: relative;

  @media (--viewportMedium) {
    /* padding: 0; */
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  max-width: 450px;
  width: 100%;
  /* padding-bottom: 100%; */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
  @media (--viewportMedium) {
    padding: 0;
  }

}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--marketplaceColor);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  width: 100%;
  position: static;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    /* height: calc(0.41 * 100vw);
    max-height: 50vh; */
    max-height: 340px;
    width: auto;
    object-fit: cover;
    border-radius: var(--borderRadius);
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  margin-bottom: 50px;

  @media (--viewportMedium) {
    max-width: 1200px;
    /* margin: 45px auto 0; */
    display: flex;
    flex-flow: column nowrap;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1200px;
    margin-bottom: 50px;
    /* padding: 0 36px; */
    /* margin: 45px auto 117px; */
  }

  @media (--viewportXLarge) {
    max-width: 1200px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 40px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 40px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 0;
    margin-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 372px;
    flex-shrink: 0;
  }
}

.sectionWithBackground {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: #FFF;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    height: 600px;
    margin-bottom: 0;
    background-color: transparent;
  }
}

.sectionWithBackground:before {
  display: none;

  @media (--viewportMedium) {
    display: block;
    width: 100vw;
    position: absolute;
    left: 0;
    height: 560px;
    background-color: #FFF;
    content: '';
  }
}

.content {
  /* width: calc(100% - 48px); */
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    justify-content: space-between;
  }
}

.contentWraper {
  @media (--viewportMedium) {
    flex-grow: 1;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-bottom: 34px;
  z-index: 1;
  background-color: #FFF;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 53px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  font-size: 32px;
  line-height: 1;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  font-size: 24px;
  line-height: 1.2;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 5px;
    text-align: left;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
    text-align: left;
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceSectionHeaderStyles;

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;
  display: grid;
  grid-gap: 20px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
}

.feature {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;

  & img {
    height: 30px;
    margin-right: 16px;
  }

  @media (--viewportMedium) {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(200, 181, 181, 0.25);
    border-radius: 20px;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #4A4A4A;
    padding: 30px;
    flex-direction: column;
    align-items: center;

    & img {
      margin-bottom: 20px;
      margin-right: 0;
      height: 45px;
    }
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceSectionHeaderStyles;

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceSectionHeaderStyles;

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceSectionHeaderStyles;

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceSectionHeaderStyles;

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}


.topButtonsContainer {
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 6px;
  justify-content: space-between;
  background-color: #FFF;

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px 0 28px;
  }
}

.backButton {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  padding: 0;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chevronLeft {
  transform: rotate(180deg);
}

.chevronLeft path {
  fill: #000000;
}

.carousel {
  display: none;

  @media (--viewportMedium) {
    flex-flow: column nowrap;
    display: flex;
    align-items: center;
    width: 100px;
    margin-right: 30px;
  }
}

.carouselImagesWrapper {
  margin: 10px 0;
  height: 280px;
  overflow: auto;
  display: flex;

  @media (--viewportMedium) {
    flex-direction: column;
  }
}

.carouselImage {
  border: 1px solid #CBCBCB;
  box-sizing: border-box;
  border-radius: 23px;
  height: 85px;
  width: 85px;
}

.carouselImageLink {
  display: block;
}

.carouselImageLink + .carouselImageLink {
  margin-top: 10px;
}

.images {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.externalLink {
  border: 1px solid #2ECC71;
  box-sizing: border-box;
  border-radius: 30px;
  color: #2ECC71;
  background-color: transparent;
  padding: 6px 18px;
  margin-top: 20px;
  display: inline-block;
}

.reviewsCount {
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.ratingValue {
  font-size: 16px;
  font-weight: 400;
  margin: 0 8px;
}

.reviewRatingStar {
  width: 16px;
  height: 16px;
  margin-bottom: 4px;
}

.carouselArrow {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: var(--marketplaceColor);
  border: 2px solid var(--marketplaceColor);
  box-sizing: border-box;
  color: #fff;
  border-radius: 10px;
  padding: 0;
  transform: rotate(-90deg);
  cursor: pointer;
}

.carouselArrow:focus {
  outline: none;
}

.moreImagesWrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;

  @media (--viewportMedium) {
    display: none;
  }
}

.moreImages {
  border: 1px solid #AFAFAF;
  border-radius: 5px;
  color: #AFAFAF;
  font-size: 12px;
  cursor: pointer;
}

.pickupLocationList {
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 10px;
}

.pickupLocationList li {
  font-weight: 400;
  font-size: 16px;
}

.pickupLocationHeader {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.pickupLocationInfo {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}

.rulesSubSection {
  margin-bottom: 30px;
}

.rulesSubHeaderWrapper {
  display: flex;
  align-items: center;
  margin: 0;
}

.rulesSubHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4A4A4A;
  text-transform: uppercase;
}

.rulesIcon {
  height: 20px;
  margin-right: 6px;
}

.termsLink {
  margin-top: 10px;
  display: block;
  font-size: 16px;
  font-weight: normal;
  color: var(--marketplaceColor);
  text-decoration: underline;
}

.termsLink:hover {
  color: var(--marketplaceColorDark);
}

.footer {
  position: static;
}

.deliveryProvider {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 6px;
}

.deliveryAddress {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 0;
}

.delivery {
  & h3 {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4A4A4A;
  }
}

.homeDelivery {
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4A4A4A;
}

.divider {
  font-style: normal;
  /* font-weight: 700; */
  font-size: 16px;
  line-height: 24px;
  color: #4A4A4A;
}

