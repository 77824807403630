@import '../../marketplace.css';

.tabs {
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;

  @media (--viewportMedium) {
    padding-top: 12px;
    padding-top: 20px;
  }

  @media (--viewportLarge) {
    padding-top: 36px;
    flex-direction: column;
  }
}

.tab {
  margin-left: 16px;

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.tab:first-child {
  margin-left: 0;
}

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 29px 24px 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 24px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 62px 0 82px 35px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 275px;
  }
}


.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding-bottom: 0;
    width: 100%;
    text-align: left;
    font-size: 36px;
    padding-left: 16px;
  }
}

