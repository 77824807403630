@import '../../../../marketplace.css';

:root {
  --AutocompleteLocation_inputHeight: 50px;
  --AutocompleteLocation_sidePadding: 24px;
  --AutocompleteLocation_sidePaddingDesktop: 36px;
}

.suggestionRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  padding-top: 16px;
  margin-top: 60px;
  top: 48px;
  left: 0;
  background-color: #FFF;
  z-index: calc(var(--zIndexPopup) + 1);

  @media(--viewportSmall) {
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
    margin-top: 0;
  }
}

.suggestionList {
  @apply --marketplaceBodyFontStyles;

  margin-bottom: 8px;
  margin-top: 0;
  padding: 0;
  background-color: #FFF;

  & li {
    color: var(--matterColorLight);
    transition: var(--transitionStyleButton);
    margin: 0;
    line-height: 36px;
    font-size: 16px;
    font-weight: 400;
    color: #4a4a4a;
    padding: 0;

    @media (--viewportSmall) {
      padding: 0 20px;
    }

    &:hover,
    &.highlighted {
      cursor: pointer;
      color: #4a4a4a;
      background-color: var(--marketplaceColorLight);
    }
  }
}

.btn {
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  margin: 0;
  line-height: 36px;
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  padding: 0;
  border: none;
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  @media (--viewportSmall) {
    padding: 0 20px;
  }
}

.btn:hover,
.highlighted {
  cursor: pointer;
  color: #4a4a4a;
  background-color: var(--marketplaceColorLight);
}

.currentLocation {
  @apply --marketplaceH4FontStyles;
}

.predictions {
  & li.defaultInfo {
    & span {
      font-style: italic;
      color: #C0C0C0;
    }

    &:hover,
    &.highlighted {
      cursor: pointer;
      color: #4a4a4a;
      background-color: #FFF;
    }
  }
}

.subheading {
  color: var(--matterColorAnti);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;

  @media(--viewportSmall) {
    padding: 0 20px;
  }
}

.subheadingContainer {
  display: flex;
  justify-content: space-between;
}

.clearBtn {
  color: #3643BA;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  text-align: center;
  border: none;
  padding: 0 20px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clearBtn:active,
.clearBtn:hover {
  color: var(--marketplaceColorDark);
  cursor: pointer;
}

.icon {
  stroke: none;
  width: 10px;
  height: 10px;
  margin-left: 6px;
}

.loadingContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
  padding: 0;

  @media (--viewportSmall) {
    padding: 0 20px;
  }
}
