@import '../../../marketplace.css';

.searchResultSummary {
    @apply --marketplaceH4FontStyles;
    line-height: 20px;
    margin: auto 8px auto 0px;
}

.resultsFound {
    white-space: nowrap;
}

.searchResultContainer {
    display: flex;
    flex-direction: column;
    padding: 2px 0 0 0;

    @media (--viewportMedium) {
        padding: 18px 0 0 0;
    }

    @media (--viewportLarge) {
        padding: 23px 0 0 0;
    }
}

.filterContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 24px;

    @media(--viewportLarge) {
        padding: 0 36px 36px 36px;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.filterWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;

    @media(--viewportLarge) {
      flex-direction: row;
      flex-wrap: wrap;
    }
}

.infoContainer {
    display: flex;
    justify-content: flex-start;
    padding-top: 16px;
}

.actionContainer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;

    @media(--viewportSmall) {
        justify-content: space-between;
        width: auto;
        justify-content: flex-start;
        gap: 16px;
    }

    @media(--viewportLarge) {
        justify-content: flex-end;
    }
}

.mapBtn,
.filtersBtn {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
    gap: 8px;
    min-width: 160px;

    @media(--viewportSmall) {
        padding: 10px 20px;
        border: 1px solid #D9DDE1;
        background: #FFF;
        justify-content: center;
    }
}

.mapBtn {
    gap: 5px;
}

.mapBtn,
.filtersBtn>span {
    color: #3643BA;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.27px;

    @media(--viewportSmall) {
        font-weight: 700;
    }
}

.mapBtn,
.filtersBtn:hover {
    cursor: pointer;
}

.mapBtn:hover,
.filtersBtn:hover>span {
    color: var(--marketplaceColorDark);
}

.filtersBtn {
    width: 100%;

    @media(--viewportMedium) {
        width: auto;
    }
}

.productsOrderLink {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #8996A2;
    margin-left: 12px;

    & span {
        margin-right: 4px;
    }
}

.formDesktop {
    display: none;

    @media(--viewportSmall) {
        display: flex;
        width: 100%;
    }

    @media(--viewportMedium) {
        width: auto;
    }
}

.formContainerMobile {
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.btnSearch {
    border-radius: 8px;
    background: #3643BA;
    height: 44px;
    flex-shrink: 0;
    border: none;
    width: 100%;

  @media(--viewportMedium) {
    height: 48px;
  }
}

.input {
    background-color: #FFF;
    border-radius: 8px;
    border: 1px solid #D9DDE1;
    width: 100%;
}

.wrapper {
    @media(--viewportLarge) {
        transition: 0.6s;
        position: fixed;
        top: 12px;
        left: 300px;
        z-index: 99;

      & .storeFilter {
        display: none;
      }
    }
}

.searchBtnContainer {
    width: 100%;

    @media(--viewportSmall) {
        display: none;
    }
}

.searchBtn {
    padding: 12px 8px;
    border-radius: 8px;
    border: 1px solid #D9DDE1;
    background: #FFF;
    box-shadow: 0px 6px 6px 0px rgba(0, 83, 125, 0.10);
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;

    @media(--viewportSmall) {
        display: none;
    }
}

.mobileWrapper {
    position: fixed;
    width: 100%;
    padding: 12px 24px;
    background-color: #FFF;
    top: 0;
    left: 0;
    z-index: 99;
    /* margin: 0 24px; */
}

.searchBtnText {
    color: var(--contentColorPrimary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-align: left;
    background-color: #FFF;
}

.modalContent {
    padding-top: 40px;
}

.emptyContainer {
    @media(--viewportLarge) {
        width: 100px;
        height: 46px;
    }
}

.emptyMobileContainer {
    width: 100px;
    height: 46px;

    @media(--viewportSmall) {
        display: none;
    }
}

.filtersWrapper {
    position: relative;
}

@keyframes notificationPop {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.filterDot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #FFF;
    position: absolute;
    top: 4px;
    right: 40px;
    animation-name: notificationPop;
    animation-duration: 0.1s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(0);
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    color: #FFF;
    background-color: #3643BA;

    @media(--viewportSmall) {
        width: 18px;
        height: 18px;
        top: -6px;
        right: -6px;
        font-size: 12px;
        line-height: 16px;
    }
}

.borderFilters {
    @media(--viewportSmall) {
        border: 1px solid #3643BA;
    }
}

.icon {
    height: 18px;
    width: 18px;

    @media(--viewportSmall) {
        height: 20px;
        width: 20px;
    }
}
