@import '../../../marketplace.css';

.itemContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-bottom: 16px;
  margin: 0 0 16px 0;
  border-bottom: 1px solid var(--matterColorNegative);

  &:hover {
    text-decoration: none;
  }

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.imgContainer {
  display: block;
  width: 100%;
  cursor: pointer;
  flex-basis: 25%;
  padding: 4px;

  @media (--viewportSmall) {
    max-width: 128px;
  }

  @media (--viewportMedium) {
    flex-basis: 30%;
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 0 8px;
  width: 100%;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.aspectWrapper {
  background: var(--matterColorNegative);
  height: 120px;
  width: 120px;
  position: relative;
  margin: 0 auto;
  border-radius: 4px;

  @media (--viewportSmall) {
    margin-bottom: auto;
  }
}

.rootForImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  object-fit: contain;
}

.title {
  color: var(--matterColor);
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  color: var(--matterColor);
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.link {
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
}

.linkContainer {
  padding-top: 8px;
  line-height: 24px;
  padding-bottom: 0;
  margin-top: 16px;
  margin-bottom: 0;
  color: var(--marketplaceColor);
}
