@import '../../../../marketplace.css';

:root {
    --rootPaddingMobile: 16px;
    --rootPadding: 32px;
    --formMobileGap: 16px;
    --formGap: 24px;
    --minHeightHeadContainer: 40px;
    --minHeightButtonSection: 52px;
}

.root {
    @apply --marketplaceModalBaseStyles;
    padding: var(--rootPaddingMobile);
    min-height: calc(var(--mobileHeight) * 100);
    height: calc(var(--mobileHeight) * 100);
    overflow: auto;

    @media (--viewportMedium) {
        flex-basis: 650px;
        min-height: auto;
        max-height: 90%;
        padding: var(--rootPadding);
        margin: auto;
    }
}

.modalContent {
    height: 100%;
}

.modalIcon {
    @apply --marketplaceModalIconStyles;
}

.headContainer {
    display: flex;
    padding-top: 4px;
    min-height: var(--minHeightHeadContainer);

    @media (--viewportMedium) {
        padding-top: 0;
    }
}

.modalTitle {
    color: var(--matterColor);
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 100%;
    padding-right: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;

    @media (--viewportMedium) {
        display: block;
        padding-right: 0;
        font-size: 20px;
    }
}

.form {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--formMobileGap);
    overflow: hidden;

    @media(--viewportMedium) {
        gap: var(--formGap);
    }
}

.formContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 80%;
    overflow: auto;
    height: 90%;
    min-height: calc(100% - var(--formMobileGap) * 2 - var(--rootPaddingMobile) * 2 - var(--minHeightHeadContainer) - var(--minHeightButtonSection));

    @media(--viewportMedium) {
        gap: 24px;
        min-height: calc(100% - var(--formGap) * 2 - var(--rootPadding) * 2 - var(--minHeightHeadContainer) - var(--minHeightButtonSection));
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.buttonSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 16px;
    min-height: var(--minHeightButtonSection);

    @media(--viewportMedium) {
        flex-direction: row;
        justify-content: flex-end;
    }
}

.boxContainer::-webkit-scrollbar {
    display: none;
}

.boxContainer,
.providerContainer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 8px 0;

    @media(--viewportMedium) {
        flex-wrap: wrap;
    }
}

.sectionTitle {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding-bottom: 8px;
}

.expandBtn {
  border: none;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 4px;
  font-size: 16px;
  text-decoration-line: underline;
  color: #3643BA;
  background-color: #FFFFFF;
}

.btnSearch:hover,
.expandBtn:hover {
    cursor: pointer;
}

.expandBtn:hover,
.expandBtn:active {
    color: var(--marketplaceColorDark);
}

.btnClearMobile {
    display: inline-block;

    @media(--viewportMedium) {
        display: none;
    }
}

.btnClearDesktop {
  display: none;

    @media(--viewportMedium) {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-decoration-line: underline;
      display: block;
    }
}

.btnSearch {
    border: none;
    color: #FFFFFF;
    background-color: #3643BA;
    text-align: center;
    width: 100%;
    padding: 0 24px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    min-height: 44px;

    @media(--viewportMedium) {
        width: auto;
    }
}

.btnSearch:hover,
.btnSearch:active {
    background-color: var(--marketplaceColorDark);
}

.btnSearch:disabled {
    background-color: #D9DDE1;
    cursor: not-allowed;
}

.btn {
    display: inline-block;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #D9DDE1;
    background: #FFF;
    color: #001018;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.btn:hover,
.btn:active {
    cursor: pointer;
    border: 1px solid #3643BA;
}

.btn:disabled {
    color: #D9DDE1;
    cursor: not-allowed;
    border: 1px solid #D9DDE1;
}

.btnSelected {
    border: 1px solid #3643BA;
}

.providerContainer {
    height: 200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.isLoading {
    align-items: center;
    justify-content: center;
}

.btnLine,
.btnLineSelected {
    font-size: 16px;
    line-height: 24px;
    border: none;
    display: block;
}

.btnLine {
    font-weight: 400;
}

.btnLine:active,
.btnLine:hover,
.btnLineSelected:active,
.btnLineSelected:hover {
    font-weight: 700;
    cursor: pointer;
}

.btnLineSelected {
    font-weight: 700;
}

.btnLine>svg {
    margin-left: 6px;
}

.label>span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.homeDeliver {
    padding-bottom: 16px;

    @media(--viewportMedium) {
        padding-bottom: 0;
    }
}

.skeleton {
    line-height: 16px;
    width: 100%;
}

@keyframes skeleton-loading {
    0% {
        background-color: var(--matterColorNegative);
    }

    100% {
        background-color: var(--matterColorBright);
    }
}

.skeletonAnimation {
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeletonCheckbox,
.skeletonLabel {
    height: 16px;
    margin-right: 8px;
    border-radius: 4px;
    display: inline-block;
    line-height: normal;
}

.skeletonCheckbox {
    width: 16px;
}

.skeletonLabel {
    width: 150px;
}

.expandBtn {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    border: none;
    color: #3643BA;
    background-color: #FFFFFF;
}

.expandBtn {
    font-weight: 400;
    padding-top: 4px;
}

.itemContainer {
    display: flex;
    align-items: center;
    gap: 6px;
}

.distanceText {
    color: #8996A2;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.dateFilterContainer {
    margin-top: 10px;
    display: flex;
    flex-flow: column nowrap;

    @media (--viewportMedium) {
        margin-top: 10px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        gap: 16px
    }
}

.dateFilterWrapper {
    font-weight: 500;
    font-size: 16px;
    min-width: 240px;
    flex-grow: 1;
}

.dateFilterWrapper>div {
    transform: none;
}

.label {
    font-weight: 500;
    font-size: 16px;
}

.dateInput input {
    padding: 8px 12px 8px 12px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.accordionContainer {
    margin: 0;
}

.accordionTitlesContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 8px;
}

.accordionTitlesContainer:hover {
    cursor: pointer;
}

.radioContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media(--viewportSmall) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.radioBtn {
    margin-top: 0;
    height: 20px;

    @media(--viewportSmall) {
        width: 48%;
    }
}

.radioBtnText {
    height: 20px;
    width: 20px;
}

.radioBtnText:after {
    border: 7px solid #3882bd !important;
}

.radioBtnText span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 28px;
    bottom: 15%;
}

.modal {
    height: 100vh;
    height: calc(var(--mobileHeight) * 100);
}
