@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding: 16px;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 650px;
    min-height: auto;
    height: auto;
    padding: 32px;
    margin: auto;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  color: var(--successColor);
  margin: 0;
  padding-top: 4px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  @media (--viewportMedium) {
    padding-top: 0;
  }
}

.iconTick {
  margin-right: 8px;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.rootForImage {
  width: 120px;
  height: 120px;
  align-self: center;
  border-radius: 8px;
}

.contentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.container,
.productInfo {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.productInfo {
  width: 100%;
}

.productSection {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media(--viewportMedium) {
    gap: 24px;
  }
}

.details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;

  @media(--viewportMedium) {
    flex-direction: row;
  }
}

.warning {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  gap: 6px;
  background: #FFFBC7;
  border: 1px solid #F8AF2D;
  border-radius: 4px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  @media(--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.warningText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  flex-shrink: 1;
}

.buttonSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 16px;
  padding-top: 16px;

  @media(--viewportMedium) {
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 0;
  }
}

.browseButton {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(--viewportMedium) {
    width: auto;
    padding: 0 24px;
    min-height: 44px;
  }
}

.browseButton:hover {
  color: var(--marketplaceColorDark);
}

.cartButton {
  color: #FFFFFF;
  background-color: var(--marketplaceColor);
  text-align: center;
  padding: 0 24px;
  border-radius: 4px;
  min-height: 44px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media(--viewportSmall) {
    width: auto;
  }
}

.cartButton:hover {
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
}
