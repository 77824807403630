@import '../../marketplace.css';

.pointsTextContainers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding-top: 16px;
    width: 100%;

  @media (--viewportMedium) {
    min-width: 340px;
  }
}

.pointsTextContainers:hover {
    cursor: pointer;
}

.pointsTextContainers:hover .pointsText {
    text-decoration: underline;
}

.points {
    border-radius: 4px;
    background: var(--activeColor);
    color: var(--marketplaceColor);
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 2px 8px;

  @media (--viewportMedium) {
    font-size: 15px;
  }
}

.pointsText {
    color: #222;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    opacity: 0.9;
    margin: 0;

  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.btnInfoIcon {
    display: inline-block;
    border: none;
    padding: 0;
}

.btnInfoIcon:hover {
    cursor: pointer;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 16px;
    width: 100%;
    margin: 0;
    position: relative;
    background-color: #ffffff;
    border-radius: 2px;
    position: relative;

    @media (--viewportMedium) {
        flex-basis: 700px;
        min-height: auto;
        height: auto;
        padding: 32px;
        margin: auto;
    }

    @media (--viewportLarge) {
        flex-basis: 805px;
    }
}

.modalScrollLayer {
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.modalCloseButton {
    background-color: transparent;
    color: #4a4a4a;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: calc(100 + 1);
    display: flex;
    align-items: flex-start;
    width: auto;
    padding: 16px;
    margin: 0;
    border: 0;
}

.modalContent {
    flex-grow: 1;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media(--viewportMedium) {
        gap: 40px;
    }
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.halfContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media(--viewportMedium) {
        flex-direction: row;
        gap: 0;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
    }
}

.rowContainer {
    display: flex;
    row-gap: 16px;
    justify-content: space-between;

    @media(--viewportMedium) {
        align-items: flex-start;
        align-self: stretch;
        width: 50%;
    }

    @media(--viewportLarge) {
        align-items: center;
        width: auto;
    }
}

.itemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    @media(--viewportLarge) {
        width: 209px;
        align-items: center;
    }
}

.heading {
    color: #001018;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.title,
.text {
    color: #222;
    line-height: 24px;
    font-size: 16px;
    margin: 0;
    text-align: center;
}

.title {
    font-weight: 700;
}

.text {
    font-weight: 400;
}

.link {
    width: 100%;
    padding: 8px 16px;
    border-radius: 3px;
    background: var(--marketplaceColor);
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    @media(--viewportSmall) {
        width: 282px;
        align-self: center;
    }
}

.link:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
}
