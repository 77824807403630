@import '../../marketplace.css';

:root {
    /* Variables to change */
    --backgroundColor: #FFF;
    --backgroundColorHover: var(--matterColorBright);
    --backgroundColorChecked: var(--marketplaceColor);
    --checkmarkColor: #FFF;
    --disabledColor: #b2b2b2;
    --border: 1px solid #b2b2b2;
    --borderHover: 1px solid var(--marketplaceColor);
    --borderChecked: 1px solid var(--marketplaceColor);
    --borderRadius: 4px;
    --boxShadow: none;
    --textColor: var(--matterColor);
}

.label {
    display: block;
    position: relative;
    padding: 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 400;
}

.smallLabel {
    padding-left: 24px;
    line-height: 14px;
    font-size: 14px;
}

.mediumLabel {
    padding-left: 26px;
    line-height: 16px;
    font-size: 16px;
}

.largeLabel {
    padding-left: 30px;
    line-height: 20px;
    font-size: 16px;
}

.label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.large, .medium, .small {
    position: absolute;
    bottom: 0;
    left: 0;
    border: var(--border);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    background-color: var(--backgroundColor);
}

.label:hover .large, .label:hover .medium:hover, .label:hover .small:hover {
    cursor: pointer;
    border: var(--borderHover);
}

.large {
    height: 20px;
    width: 20px;
}

.medium {
    height: 16px;
    width: 16px;
}

.small {
    height: 14px;
    width: 14px;
}

.label:hover input~.checkmark {
    background-color:  var(--backgroundColorHover);
}

.label input:checked~.checkmark {
    background-color: var(--backgroundColorChecked);
    border: var(--borderChecked);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.label input:checked~.checkmark:after {
    display: block;
}

.label .large:after,
.label .medium:after,
.label .small:after {
    border: solid var(--checkmarkColor);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.label .large:after {
    left: 5px;
    bottom: 6px;
    width: 8px;
    height: 12px;
}

.label .medium:after {
    left: 4px;
    bottom: 4px;
    width: 6px;
    height: 10px;
}

.label .small:after {
    left: 4px;
    bottom: 3px;
    width: 5px;
    height: 8px;
}

.text {
    color: var(--textColor);
}

.label input:checked~.disabled,
.label input:checked~.disabled:hover {
  background-color: var(--disabledColor);
  border: var(--border);
  cursor: not-allowed;
}

.labelDisabled {
  cursor: not-allowed;

  .text {
    color: var(--disabledColor);
  }
}
