@import '../../../marketplace.css';


.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (--viewportMedium){
        align-items: center;
    }
}

.text {
    text-align: right; 
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    @media (--viewportMedium){
        text-align: center; 
        max-width: 300px;
    }
}

.btn {
    display: inline-block;
    text-transform: uppercase;
    border: none;
    color: var(--bannedColorDark);
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px;
}

.btn:hover, .btn:active {
    color: var(--bannedColorLight);
}