@import '../../marketplace.css';

.container,
.stepsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.container {
  padding: 0 16px;
  margin: 40px 0;

  @media(--viewportSmall) {
    padding: 24px;
    align-items: center;
    gap: 24px;
  }

  @media(--viewportMedium) {
    flex-direction: row-reverse;
    padding: 32px;
    align-items: center;
    margin: 32px 0;
  }

  @media(--viewportLarge) {
    padding: 24px 78px;
    max-width: calc(var(--containerMaxWight) + (78px * 2));
    margin: 32px auto;
  }
}

.stepsContainer {
  padding: 0;

  @media(--viewportMedium) {
    align-self: center;
    flex: 1 1 50%;
    padding-left: 32px;
  }
}

.title {
  color: var(--contentColorPrimary);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
}

.stepTitle {
  color: var(--contentColorPrimary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.stepText {
  color: var(--contentColorPrimary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.imgContainer {
  flex: 1 1 50%;
}

.image {
  border-radius: 8px;
  width: 100%;
}
