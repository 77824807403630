@import '../../marketplace.css';

.root {
  background-color: #FFF;
  width: 100%;
  border-radius: 20px;
  padding-top: 10px;
  margin-top: 16px;
  overflow: scroll;

  @media (--viewportMedium) {
    padding: 6px 24px 12px 24px;
    margin-top: 24px;
  }
}

.resultsTable {
  width: 100%;
}

.tableHeader {
  font-size: 14px;
  font-weight: 700;
  height: 40px;
}

.resultHead {
  visibility: hidden;
  height: 0;
  position: absolute;

  @media (--viewportMedium) {
    visibility: visible;
    height: auto;
    position: static;
  }
}

.row {
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  line-height: 18px;
  display: block;
  margin-bottom: 8px;
  border-radius: 8px;

  @media (--viewportMedium) {
    border-bottom: 1px solid #E4E4E4;
    padding: 0 20px;
    margin-bottom: 0;
    display: table-row;
  }
}

.row:nth-child(even) {
  background: #f1f1f1;

  @media (--viewportMedium) {
    background: #fff;
  }
}

.row:last-child {
  border-bottom: none;
}

.td {
  padding: 4px 8px;
  border: 1px solid #E4E4E4;
  border-bottom: none;
  display: block;
  font-size: 12px;
  text-align: right;

  @media (--viewportMedium) {
    border: none;
    display: table-cell;
    text-align: center;
    font-size: 14px;
  }
}

.td::before {
  content: attr(data-label);
  float: left;
  font-weight: bold;
  text-transform: uppercase;

  @media (--viewportMedium) {
    content: none;
  }
}

.td:last-child {
  border-bottom: 1px solid #E4E4E4;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  @media (--viewportMedium) {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.td:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  @media (--viewportMedium) {
    border-bottom: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
