@import '../../../marketplace.css';

.productsOrderLink {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #8996A2;
    margin-left: 12px;

    & span {
        margin-right: 4px;
    }
}

.searchResultSummary {
    @apply --marketplaceH4FontStyles;
    line-height: 20px;
    margin: auto 8px auto 0px;
}

.resultsFound {
    white-space: nowrap;
}

.searchResultContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 2px 0 0 0;

    @media (--viewportMedium) {
        padding: 18px 0 0 0;
    }

    @media (--viewportLarge) {
        padding: 23px 0 0 0;
    }
}

.sortBy {
    margin-right: 9px;
}

.sortByMenuLabel {
    @apply --marketplaceButtonStylesSecondary;
    @apply --marketplaceTinyFontStyles;
    font-weight: var(--fontWeightBold);

    height: 35px;
    min-height: 35px;
    padding: 0 18px;
    margin: 0;
    border-radius: 4px;
    white-space: nowrap;
}

.loadingResults {
    @apply --marketplaceH4FontStyles;
    line-height: 20px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 9px 24px 0 24px;
    margin: 0;
    background-color: var(--matterColorBright);

    @media (--viewportLarge) {
        padding: 9px 36px 0 36px;
    }
}

.error {
    color: var(--failColor);
    padding-left: 24px;
    padding-right: 24px;
}

.listings {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.newSearchInProgress {
    opacity: 0.1;
}

.searchListingsPanel {
    flex-grow: 1;
}

.wrapper {
    padding: 24px;
}

.pageHeader {
    padding: 0 24px 0 24px;

    &>h1 {
        font-size: 24px;
        line-height: 28px;
        margin-top: 16px;
        margin-bottom:0;
    }

    @media (--viewportMedium) {
        padding: 0 36px 0 36px;
        &>h1 {
            font-size: 36px;
            line-height: 36px;
            margin-top: -20px;
            margin-bottom: 40px;
        }
    }
}

.content {
    padding: 0 24px 24px 24px;

    @media (--viewportMedium) {
        padding: 0 36px 36px 36px;
    }

    &>h2 {
        font-size: 18px;
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 14px;
        line-height: 20px;
    }

    &>p {
        font-size: 14px;
        line-height: 16px;
        margin-top: 0;
        margin-bottom: 10px;
    }
}