@import '../../marketplace.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.aside {
  box-shadow: none;
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--matterColorNegative);
  gap: 24px;
  margin: 16px 0;

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
    margin: 0;
  }
}

.avatar {
  margin: auto 0;
  height: 60px;
  width: 60px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    height: 96px;
    width: 96px;
  }

  @media (--viewportLarge) {
    margin: auto 79px auto 78px;
  }
}

.mobileHeading {
  margin: auto 0;

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 2px 0 24px 0;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 16px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  @media (--viewportMedium) {
    margin: 4px 0 16px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 20px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  @apply --clearfix;
  border-top: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 58px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.listings {
  margin: 0;
  padding: 18px 0 64px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 32px;

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 24px;
  }
}

.listing {
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    max-width: 600px;
    margin-bottom: 0;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH3FontStyles;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 48px 0;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: 0 0 48px 0;
    gap: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;

  @media(--viewportMedium) {
  width: calc(50% - 0.8rem);
  }
}

.heading {
  text-transform: uppercase;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media (--viewportMedium) {
    padding-top: 0;
  }
}

.textContainer {
  margin: 0;
  background-color: #eff1f3;
  padding: 16px;
  line-height: 20px;
}

.boldText, .text, .termsLink, .textAddress {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

.termsLink:hover {
  color: var(--marketplaceColorDark)
}

.boldText {
  font-weight: 700;
}

.textAddress {
  line-height: 24px;
}

.descriptionContainer {
  padding-bottom: 32px;
}

.section {
  padding: 8px 0;
}

.title {
  margin: 0;
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 4px;
}

.openingHours {
  white-space: pre-line;
  line-height: 22px;
}
