@import '../../marketplace.css';

.formRoot {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;

  @media(--viewportMedium) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media(--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0;
  }
}

.btnSearch {
  border-radius: 8px;
  background: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  height: 44px;
  flex-shrink: 0;
  width: 100%;

  @media(--viewportMedium) {
    width: 64px;
    height: 48px;
    border-radius: 0px 8px 8px 0px;
  }
}

.btnSearch:hover,
.btnSearch:active {
  cursor: pointer;
  background-color: var(--marketplaceColorDark);
}

.searchIcon {
  stroke: none;
}

.locationInput {
  border: none;
  height: 100%;
}

.customSelectInput,
.listingInput {
  background-color: #FFF;
  border-radius: 8px;
  border: 1px solid #D9DDE1;
  width: 100%;

  @media(--viewportMedium) {
    border-right: 1px solid #FFF;
    border-radius: 0;
    width: 290px;
  }

  @media(--viewportLargeCart) {
    width: 300px;
  }
}

.listingInput {
  @media(--viewportMedium) {
    border-radius: 8px 0 0 8px;
    height: 100%;
  }
}

.listingInput:focus,
.customSelectInput:focus {
  border: 1px solid var(--marketplaceColor);
}

.customSelectInputIcon {
  display: none;
}

.labelSelect {
  border-radius: 8px;
  border: 1px solid #D9DDE1;

  @media(--viewportMedium) {
    border-radius: 8px 0 0 8px;
    border-right: none;
  }
}

.labelSelect:hover,
.labelSelect:focus,
.labelSelect:active {
  border-right: none;
}

.filtersBtn {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  gap: 8px;
  width: unset;

  @media(--viewportSmall) {
    padding: 10px 20px;
    border: 1px solid #D9DDE1;
    background: #FFF;
    justify-content: center;
  }

  @media(--viewportMedium) {
    margin-left: 12px;
  }

  &>span {
    color: #3643BA;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.27px;

    @media(--viewportSmall) {
      font-weight: 700;
    }
  }

  &:hover {
    cursor: pointer;
  }
}


/* modal */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modalContainer {
  background: #fff;
  width: 100%;
  max-width: 600px;
  height: 75vh; /* Stała wysokość modala */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 767px) {
    height: 100vh;
    border-radius: 0;
    width: 100vw;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative; /* aby można było pozycjonować closeButton absolutnie */
}

.modalHeader {
  padding: 20px;
  flex-shrink: 0;
  background: #fff;
  border-bottom: 1px solid #ddd;
  position: relative; /* Umożliwia pozycjonowanie closeButton względem headera */
}

.modalHeader h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.searchInput {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  font-size: 1rem;
  border-radius: 8px 0 0 8px;
  font-size: 14px;
  font-weight: 400;
}

/* Sekcja z listą providerów będzie rosnąć i przewijać się */
.providerContainer {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.itemContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.distanceText {
  margin-left: 10px;
  color: #666;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.modalFooter {
  padding: 20px;
  flex-shrink: 0;
  border-top: 1px solid #ddd;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  /* Poniższy padding zapewni, że na iPhone nie zasłoni przycisku np. notch lub pasek nawigacji. */
  padding-bottom: calc(20px + env(safe-area-inset-bottom));

  /* Dla kompatybilności z starszym iOS można użyć również: */
  /* padding-bottom: calc(20px + constant(safe-area-inset-bottom)); */
}

.btnSearchPro {
  border: none;
  color: #FFFFFF;
  background-color: #3643BA;
  text-align: center;
  width: 100%;
  padding: 0 24px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  min-height: 44px;

  @media(--viewportMedium) {
      width: auto;
  }
}

.btnSearchPro:hover,
.btnSearchPro:active {
  background-color: var(--marketplaceColorDark);
}

.btnSearchPro:disabled {
  background-color: #D9DDE1;
  cursor: not-allowed;
}

.countrySeparator {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
  font-size: 16px;
}

.clearLink {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
  display: block;
  margin: auto 16px auto 0;
  cursor: pointer;
}

.filtersWrapper {
  position: relative;
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.filterDot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #FFF;
  position: absolute;
  top: 4px;
  right: 40px;
  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  color: #FFF;
  background-color: #3643BA;

  @media(--viewportSmall) {
    width: 18px;
    height: 18px;
    top: -6px;
    right: -6px;
    font-size: 12px;
    line-height: 16px;
  }
}

.borderFilters {
  @media(--viewportSmall) {
    border: 1px solid #3643BA;
  }
}
