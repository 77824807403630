@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;
}

/* Dummy Topbar */

.topbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    height: var(--topbarHeightDesktop);
    justify-content: center;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

  @media (--viewportLarge) {
    padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
  }
}

.logoMobile {
  display: block;

  & path {
    fill: var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}

.page {
  background-color: #F7F7F7;
}

.container {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f1f1f1;

  @media (--viewportMedium) {
    background-color: #FFF;
    padding: 0;
    max-width: 1284px;
    margin: 24px 24px;
    gap: 16px;
  }

  @media (min-width: 1332px) {
    margin: 24px auto 0 auto;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    border-radius: 8px;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    padding: 32px 24px 32px 32px;
    background-color: #FFF;
  }

  @media (--viewportLarge) {
    gap: 40px;
  }

  @media (--viewportLargeCart) {
    gap: 84px;
  }
}

.paymentDetailsContainer {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.paymentDetailsContainer > div > div {
  width: 100%;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;

  @media (--viewportMedium) {
    gap: 24px;
    margin-bottom: 64px;
    border-bottom: none;
    background-color: #FFF;
  }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0 48px;
  }
}

.bookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-grow: 0;
    flex-basis: 660px;
    margin-top: 46px;
    margin-right: 60px;
  }
}

.aspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  @media (--viewportLarge) {
    display: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 25px 25px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  /* margin-left: 24px;
  margin-top: -31px; */

  /* Rendering context to the same lavel as listing image */
  /* position: relative; */

  /* Layout */
  display: block;

  @media (--viewportLarge) {
    margin-left: 48px;
  }
}

.avatarMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  margin-top: 29px;
  margin-bottom: 14px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;

  & img {
    height: 80px;
    margin-right: 10px;
    border-radius: 20px;
    border: 1px solid #A4A4A4;
  }

  @media (--viewportMedium) {
    margin-top: 27px;
    margin-bottom: 30px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 54px;
    padding: 0;
    display: flex;

    & img {
      height: 120px;
      margin-right: 20px;
      border-radius: 25px;
    }
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);
  font-size: 22px;
  line-height: 26px;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;
  display: flex;
  flex-direction: column;
  /* text-align: center; */

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 32px;
    text-align: left;
  }
}

.listingTitle {
  color: var(--marketplaceColor);
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;
  display: none;
  font-weight: 400;

  @media (--viewportMedium) {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 30px;
  background: #FFF;

  @media (--viewportMedium) {
    margin-bottom: 38px;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.paymentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-top: 2px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    padding: 0;
  }
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}
.orderError {
  color: var(--failColor);
}
.notFoundError {
  color: var(--attentionColor);
}

.speculateError {
  color: var(--failColor);
  margin: 19px 0 1px 0;

  @media (--viewportLarge) {
    margin: 28px 48px 0 48px;
  }
}

.paymentForm {
  flex-grow: 1;
  padding: 16px;
  background-color: #FFF;
  margin-top: 24px;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  display: none;

  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 409px;
    padding-bottom: 30px;

    /* Position */
    margin: 46px 0 24px 0;

    /* Coloring */
    background-color: var(--matterColorLight);
    box-shadow: 0px 0px 30px 10px rgba(123, 123, 123, 0.08);
    border-radius: 25px;
  }
}

.detailsAspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  /* background-color: var(--matterColorNegative); Loading BG color */
}

.detailsHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 25px 30px 15px 30px;
  }
}

.detailsTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 17px;
    margin-bottom: 9px;
    font-size: 20px;
    line-height: 24px;
  }
}

.detailsSubtitle {
  @apply --marketplaceH5FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  /* Layout spacing */
  margin: 5px 24px 25px 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
  }
}

.bookingBreakdown {
  @media (--viewportLarge) {
    padding: 0 30px;
  }
}

.businessLocationHeader {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 12px;
  margin-top: 20px;
}

.businessLocation {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 4px;
}

.businessLocationWrapper {
  display: flex;
  align-items: center;

  &>div {
    flex-shrink: 0;
    margin-right: 16px;
  }
}

.locationInfo {
  margin-bottom: 14px;
}

.locationInfo > div {
  margin-bottom: 14px;
}

.mainRoot {
  background-color: #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymentStatus {
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding: 30px;

  & h2 {
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #165EB9;
  }

  & .paymentStatusInfo {
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #4A4A4A;
    font-weight: 400;
  }
}

.bolded {
  font-weight: 700;
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--marketplaceColor);
  background: -moz-linear-gradient(left, var(--marketplaceColor) 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, var(--marketplaceColor) 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, var(--marketplaceColor) 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, var(--marketplaceColor) 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, var(--marketplaceColor) 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: var(--marketplaceColor);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #FFF;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.failed {
  color: #E50000 !important;
}

.transactionBtn {
  border: 1px solid #165EB9;
  border-radius: 30px;
  color: #165EB9;
  margin-top: 30px;
  font-weight: 400;
  padding: 8px 12px;
}

.desktopContainer {
  display: none;

  @media(--viewportLarge) {
    /* display: block;   */
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.mobileContainer {
  @media(--viewportLarge) {
    display: none;
  }
}

.linkToProfile {
  color: #165EB9;
  margin-left: 10px;
}

.backContainer {
  padding: 16px;
}

.notAvailableError {
  padding: 32px 20px;
  margin: 0;
}

.backButton {
  border: none;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.orderContainer {
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding: 16px 16px 0 16px;
    margin-top: 0;
  }

  @media (--viewportLarge) {
    padding: 0;
  }
}

.serviceFeeContainer {
  @media (--viewportMedium) {
    padding: 0 16px 32px 16px;
  }

  @media (--viewportLarge) {
    padding: 0 16px;
  }
}
