@import '../../marketplace.css';

:root {
  --disabledColor: rgba(123, 97, 255, 0.08);
}

.wrapper {
  position: relative;
  min-width: 318px;

  :global(.react-datepicker-wrapper) {
    width: 100%;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
  }
}

.container {
  display: flex;
  gap: 16px;
  margin: 0 auto;
  padding: 0;

  @media(--viewportMedium) {
    padding: 0;
  }
}

.popper {
  width: 100%;
  padding: 0;
  transition: 0s !important;
  top: 0 !important;
  inset: 0 auto auto 0 !important;
  transform: translate3d(0px, 42px, 0px) !important;
  padding-top: 10px !important;
}

.calendar {
  background: #FFF;
  box-shadow: 24px 24px 24px 24px #00537D1A;
  padding: 16px 8px;
  width: 100%;
  display: flex;
  border: none !important;

  @media(--viewportSmall) {
    padding: 24px 8px;
  }

  :global(.react-datepicker__triangle::after) {
    border-color: #FFF !important;
    display: none;
  }

  :global(.react-datepicker__triangle::before) {
    border-color: #FFF !important;
    display: none;
  }

  :global(.react-datepicker__header ) {
    background-color: #FFF;
    border-bottom: none;
    padding: 0;
    margin: 0 auto 16px auto;
    width: 287px;
  }

  :global(.react-datepicker__month-container) {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  :global(.react-datepicker__month ) {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  :global(.react-datepicker__day-name) {
    margin: 0;
    width: 41px;
  }

  :global(.react-datepicker__day) {
    margin: 6px 1px;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    width: 41px;
    height: 32px;
    line-height: 32px;
  }

  :global(.react-datepicker__day:hover) {
    background-color: #DADCF1;
  }

  :global(.react-datepicker__day),
  :global(.react-datepicker__day:hover) {
    margin: 0 !important;
    border-radius: 0;
  }

  :global(.react-datepicker__day:focus-visible) {
    outline-color: #FFF;
  }

  :global(.react-datepicker__day--in-range),
  :global(.react-datepicker__day--in-selecting-range) {
    background: var(--disabledColor);
    color: #001018;
  }

  :global(.react-datepicker__day--outside-month) {
    color: var(--contentColorTertiary);
  }

  :global(.react-datepicker__day--selected),
  :global(.react-datepicker__day--selecting-range-start),
  :global(.react-datepicker__day--selecting-range-start:hover),
  :global(.react-datepicker__day--range-start) {
    border-radius: 0;
    background-color: var(--marketplaceColor);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    color: #FFF;
  }

  :global(.react-datepicker__day--selecting-range-end),
  :global(.react-datepicker__day--selecting-range-end:hover),
  :global(.react-datepicker__day--range-end) {
    border-radius: 0;
    background-color: var(--marketplaceColor);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    color: #FFF;
  }

  :global(.react-datepicker__day--selected:hover),
  :global(.react-datepicker__day--in-selecting-range:hover),
  :global(.react-datepicker__day--in-range:hover) {
    background-color: #323894;
    color: #FFF;
  }

  :global(.react-datepicker__day--keyboard-selected) {
    background-color: #FFF;
  }

  :global(.react-datepicker__day--disabled) {
    color: #ccc;
  }
}

.dateContainer {
  flex-grow: 1;
}

.label {
  margin: 0;
  padding: 0;
}

.text {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColor);
  font-weight: 400;
  display: block;
  margin-bottom: 6px;
}

.btn,
.defaultBtn {
  width: 100%;
  border: 1px solid var(--borderColorPrimary);
  border-radius: 4px;
  color: var(--matterColor);
  transition: border-color ease-in 0.1s;
  box-shadow: none;
  padding: 8px 12px;
  font-weight: 400;
}

.btn:focus,
.defaultBtn:focus {
  border: 1px solid var(--marketplaceColor);
}

.btn:hover,
.defaultBtn:hover {
  border: 1px solid var(--marketplaceColorDark);
  cursor: pointer;
}

.defaultBtn {
  color: var(--matterColorAnti);
}

.errorBtn {
  border: 1px solid var(--failColor);
}

.active {
  border: 1px solid var(--marketplaceColor);
}

.disabled, .disabled:hover {
  border: 1px solid var(--disabledButtonColor);
}

.headerContainer {
  background-color: #FFF;
  margin: 0 auto;
}

.monthContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  margin: 16px 0 16px 0;

  @media(--viewportMedium) {
    margin: 24px 0 16px 0;
  }
}

.arrowBtn {
  border: none;
  font-weight: 500;
  width: 24px;
  height: 24px;
  position: relative;
}

.arrowBtn>svg {
  stroke: #101010;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrowBtn:hover {
  cursor: pointer;
  color: var(--matterColor);
}

.arrowBtn:hover>svg {
  stroke: var(--matterColor);
}

.calendar {
  :global(.react-datepicker__children-container) {
    width: 230px;
    margin: 0 auto;

    @media(--viewportSmall) {
      width: 296px;
    }
  }
}

.error {
  color: var(--failColor);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-top: 8px;
}

.month,
.calendarTitle {
  color: #001018;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media(--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
  }
}

.month {
  text-transform: capitalize;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media(--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
  }
}

.dayNameText {
  text-transform: uppercase;
  color: #8996A2;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
  background-color: #FFF;
}

.dayNameText::selection {
  border-radius: 0;
}

.calendarTitle {
  text-align: left;
  margin: 0;
}

.day {
  width: 41px;
  height: 32px;
  color: #001018;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.validationError {
  padding: 0 24px;
  margin: 0;

  @media(--viewportMedium) {
    padding: 0;
  }
}
