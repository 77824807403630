@import '../../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 0 26px 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColor);
  font-weight: 700;
  display: block;
  margin: 0;
}

.text {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColor);
  font-weight: 400;
  margin: 0;
}
