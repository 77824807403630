@import '../../marketplace.css';

.container {
  padding: 16px;
}

.titleContainer {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;

  @media(--viewportSmall) {
    gap: 16px;
  }

  @media(--viewportMedium) {
    justify-content: flex-start;
    padding-bottom: 40px;
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;

  @media(--viewportMedium) {
    font-size: 22px;
    line-height: 26px;
  }
}

.title > span:first-child {
  padding-bottom: 4px;
}

.statusText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius: 2px;
  padding: 4px 8px;
  width: 35%;
  text-align: center;
  align-self: flex-start;

  @media(--viewportSmall){
    width: auto;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  @media(--viewportMedium) {
    gap: 16px;
  }
}

.productContainer {
  display: flex;
  gap: 8px;

  @media(--viewportMedium) {
    gap: 16px;
    width: 540px;
  }
}

.overlay {
  opacity: 0.5;
}

.rootForImage {
  max-width: 110px;
  height: 100%;
  border-radius: 8px;

  @media(--viewportSmall) {
    max-width: 150px;
  }
}

.productContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  @media(--viewportSmall) {
    gap: 16px;
  }
}

.productName {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.productDetails {
  padding-bottom: 8px;

  @media(--viewportSmall) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 56px;
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.price {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  padding-top: 8px;

  @media(--viewportSmall) {
    padding-top: 0;
  }

  @media(--viewportMedium) {
    display: none;
  }
}

.code {
  font-size: 16px;
  font-weight: 400;
  display: block;

  & .codeValue {
    font-weight: 700;
  }
}

.paymentLink {

  @apply(--marketplaceButtonStyles);
  width: 100%;
  background-color: var(--marketplaceColor);
  margin-top: 8px;
  color: #FFF;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(--viewportSmall) {
    max-width: 192px;
    margin-bottom: 20px;
  }
}

.wrapper {
  margin-top: 24px;
}

.serviceFeeContainer {
  margin-top: 16px;

  @media(--viewportMedium) {
    margin-bottom: 16px;
    margin-top: 0;
  }
}

.serviceFeeDetails {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
