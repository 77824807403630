@import '../../marketplace.css';

.infoContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.questionMark {
  width: 30px;
  height: 30px;
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  color: #FFF;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.infoText {
  color: #757575;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0;
  margin-left: 10px;
}
