@import '../../../marketplace.css';

.listItem {
  margin-bottom: 32px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1), 0 1px 3px 0 rgba(0,0,0,.05);
  border-radius: 4px;
  overflow: hidden;
  padding: 8px;

  & > div:last-child {
    border: none;
    margin-bottom: 0;
  }
}

.itemContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.expanded {
  padding-bottom: 16px;
}

.imgContainer {
  display: block;
  width: 100%;
  cursor: pointer;
  flex-basis: 25%;

  @media (--viewportSmall) {
    max-width: 150px;
  }

  @media (--viewportMedium) {
    flex-basis: 30%;
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 0 12px;
  width: 100%;
}

.actionContainer {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.actionWrapper {
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 16px;
  margin-bottom: 0;
  color: var(--marketplaceColor);
}

.details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.link {
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
}

.aspectWrapper {
  background: var(--matterColorNegative);
  height: 150px;
  width: 150px;
  position: relative;
  margin: 0 auto;
  border-radius: 4px;

  @media (--viewportSmall) {
    margin-bottom: auto;
  }
}

.rootForImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  object-fit: contain;
}

.title {
  color: var(--matterColorDark);
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  color: var(--matterColor);
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.price {
  margin: 0;
}

.priceValue {
  color: var(--marketplaceColor);
  font-weight: 700;
  padding-right: 4px;
  margin: 0;
  font-size: 14px;
}

.perUnit {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.1px;
  margin: 0;
}

.itemSection {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (--viewportSmall) {
    gap: 24px;
    flex-direction: row;
    align-items: center;
  }

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}

.showBtn {
  background-color: var(--marketplaceColor);
  border: 1px solid  var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
  min-height: 40px;
  height: 40px;
  align-self: center;
  width: 100%;
  padding: 0 24px;

  @media (--viewportSmall) {
    width: auto;
  }

  @media (--viewportSmall) {
    align-self: flex-start;
  }
}

.showBtn:hover {
  background-color: var(--marketplaceColorDark);
  border: 1px solid  var(--marketplaceColorDark);
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 4px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}
