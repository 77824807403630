@import '../../../../marketplace.css';

.discountContainer {
    display: flex;
    gap: 16px;
    align-items: center;
}

.title {
    margin: 0;
    padding-bottom: 8px;
}

.input {
    width: 160px;
}

.label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0;
}

.inputBox {
    width: 60px;
    margin: 4px 0 20px 0;

    @media(--viewportMedium) {
        margin: 10px 0 22px 0;
    }
}

.text {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
}

.textCheckbox span {
    width: 50px;
}

.errorForm {
    color: var(--failColor);
    font-size: 12px;
    margin: 0;
    font-weight: 400;
    line-height: 24px;

    @media(--viewportSmall) {
        padding-left: 22px;
    }
}

.disabledRadioAmount, .disabledRadioPercentage {
  & * {
    cursor: not-allowed;
  }

  & span:first-child {
    color: var(--matterColorAnti);
  }
}

.disabledRadioAmount {
  & span:last-child {
    border: 1px solid var(--matterColorAnti) !important;

    &:after {
      border: 5px solid var(--matterColorAnti) !important;
    }
  }
}

.disabledRadioPercentage {
  & span:last-child {
    border: 1px solid var(--matterColorAnti) !important;
  }
}
