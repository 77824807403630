@import '../../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 24px 0;

  @media (--viewportSmall) {
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}

.container .btn {
  @media (--viewportSmall) {
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
    max-height: 42px;
  }
}

.error {
  color: var(--failColor);
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}

.mobile {
  display: block;

  @media(--viewportMedium) {
    display: none;
  }
}

.desktop {
  display: none;

  @media(--viewportMedium) {
    display: block;
  }
}

.monday {
  :global(.react-datepicker__week) {
    :global(.react-datepicker__day:nth-child(1)) {
      color: #ccc;;
    }
  }
}

.tuesday {
  :global(.react-datepicker__week) {
    :global(.react-datepicker__day:nth-child(2)) {
      color: #ccc;;
    }
  }
}

.wednesday {
  :global(.react-datepicker__week) {
    :global(.react-datepicker__day:nth-child(3)) {
      color: #ccc;;
    }
  }
}

.thursday {
  :global(.react-datepicker__week) {
    :global(.react-datepicker__day:nth-child(4)) {
      color: #ccc;;
    }
  }
}

.friday {
  :global(.react-datepicker__week) {
    :global(.react-datepicker__day:nth-child(5)) {
      color: #ccc;;
    }
  }
}

.saturday {
  :global(.react-datepicker__week) {
    :global(.react-datepicker__day:nth-child(6)) {
      color: #ccc;;
    }
  }
}

.sunday {
  :global(.react-datepicker__week) {
    :global(.react-datepicker__day:nth-child(7)) {
      color: #ccc;;
    }
  }
}

.calendar {
  :global(.react-datepicker__week) {
    :global(.react-datepicker__day--highlighted-custom-1) {
      color: var(--successColor) !important;
    }

    :global(.react-datepicker__day--highlighted-custom-2) {
      color: var(--failColor) !important;
    }
  }
}

.calendar {
  :global(.react-datepicker__day--disabled),
  :global(.react-datepicker__day--excluded),
  :global(.react-datepicker__day--disabled):hover,
  :global(.react-datepicker__day--excluded):hover {
    background-color: transparent !important;
  }
}

.legendContainer {
  display: inline-block;
  margin-top: 8px;
}

.legendTitle {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.legendItem {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  margin-right: 18px;
  margin-bottom: 6px;
}

.legendColor {
  width: 15px;
  height: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  margin-right: 8px;
  display: inline-block;
}

.legendClosedColor {
  composes: legendColor;
  background-color: var(--failColor);
  border: solid 1px var(--failColor);
}

.legendOpenedColor {
  composes: legendColor;
  background-color: var(--successColor);
  border: solid 1px var(--successColor);
}

.legendText {
  font-size: 14px;
  font-weight: normal;
}
