@import '../../marketplace.css';

.root {
  & label {
    font-size: 14px;
    line-height: 25px;
    color: #4A4A4A;
  }
}

.textarea {
  width: 100%;
  box-sizing: border-box;
}
