@import '../../marketplace.css';


.wrapper {
  background: #ecedf8;
  width: 100%;
  padding: 32px;

  @media(--viewportLarge) {
    padding: 56px 78px;
  }
}

.container {
    display: flex;
    /*width: 100%;*/
    /*padding: 32px;*/
    flex-direction: column;
    align-items: center;
    gap: 24px;
    /*background: #E7F3F9;*/

    @media(--viewportMedium) {
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
    }

    /*@media(--viewportLarge) {*/
    /*    padding: 56px 78px;*/
    /*}*/

    @media(--viewportLargeCart) {
        justify-content: space-between;
        max-width: var(--containerMaxWight);
        margin: 0 auto;
    }
}

.actionContainer {
    @media(--viewportMedium) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
        max-width: 507px;
    }
}

.programContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @media(--viewportMedium) {
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        max-width: 507px;
    }

    img {
        width: 300px;

        @media(--viewportMedium) {
            width: 360px;
        }
    }
}

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    @media(--viewportMedium) {
        align-items: flex-start;
        flex-direction: row;
        gap: 8px;
    }
}

.header,
.subheader,
.title,
.text {
    margin: 0;
    color: #222;
    text-align: center;
}

.header {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.096px;

    @media(--viewportMedium) {
        text-align: left;
    }
}

.subheader {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.subheader span {
    @media(--viewportMedium) {
        display: block;
        text-align: left;
    }
}

.itemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 200px;

    @media(--viewportSmall) {
        width: auto;
    }

    @media(--viewportLarge) {
        width: 200px;
    }
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.link {
    width: 100%;
    padding: 8px 16px;
    border-radius: 3px;
    background: var(--marketplaceColor);
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    @media(--viewportSmall) {
        width: 282px;
    }
}

.link:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
}

.desktop {
    display: none;

    @media(--viewportMedium) {
        display: block;
    }
}

.mobile {
    @media(--viewportMedium) {
        display: none;
    }
}
