
.feedHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  
    @media (--viewportMedium) {
        font-size: 26px;
        line-height: 32px;
    }
  }

  .messageError {
    color: var(--failColor);
    margin: 13px 0 22px 0;
  
    @media (--viewportMedium) {
      margin: 13px 0 23px 0;
    }
  
    @media (--viewportLarge) {
      margin: 12px 0 23px 0;
    }
  }
  
.notRespond {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}