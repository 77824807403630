@import '../../../marketplace.css';

.listItem {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
}

.container {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.listItem:last-child {
  border-bottom: none;
}

.item {
  width: 100%;
  display: flex;
}

.itemAvatar {
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-top: 2px;
    margin-right: 15px;
  }
}

.itemDetails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 6px;

  @media (--viewportSmall) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.text {
  color: var(--matterColorAnti);
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.user {
  font-size: 16px;
  color: var(--matterColorDar);
  inline-size: 200px;
  overflow-wrap: break-word;

  @media (--viewportSmall) {
    inline-size: 100%;
  }
}

.actionContainer {
  padding: 16px 0;
  display: flex;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-end;

  @media (--viewportSmall) {
    padding: 0;

  }
}

.btn {
  border: none;
  height: 32px;
  width: 32px;
  padding-left: 8px;
  cursor: pointer;
}

.checkbox>input[type=checkbox] {
  visibility: hidden;
}

.checkbox {
  display: flex;
  justify-items: center;
  position: relative;
  padding: 0;
  margin-top: 4px;
  cursor: pointer;
  height: 16px;
}

.checkboxText {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid var(--matterColorAnti);
  border-radius: 4px;
}

.checkboxText>span {
  padding-left: 22px;
  line-height: 16px;
  position: relative;
  bottom: 30%;
  font-weight: 500;
}

.checkbox input:checked~.checkboxText {
  background-color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
}

.checkboxText:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked~.checkboxText:after {
  display: block;
}

.checkbox .checkboxText:after {
  left: 5px;
  bottom: 2px;
  width: 4px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.emailContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.button {
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @media(--viewportSmall) {
    width: 100%;
  }

  @media(--viewportMedium) {
    width: auto;
    padding: 14px 24px;
  }
}

.submitButton {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  padding: 14px 48px;
  border-radius: 4px;
  border: none;

  @media(--viewportMedium) {
    width: auto;
  }
}

.strikethroughText {
  text-decoration: line-through;
}

.textNotification {
  font-weight: 400;
}
