@import '../../../marketplace.css';

.wrapper {
  position: relative;
  height: 100%;
}

.suggestionsWrapper {
  position: absolute;
  margin-top: 130px;
  width: 100%;
  background-color: #FFF;
  padding-bottom: 12px;
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportSmall) {
    margin-top: 0;
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
  }

  & ul {
    margin-bottom: 0;
  }
}

.container {
  margin-top: 16px;
}

.searchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listItem {
  padding: 3px 0;

  @media (--viewportSmall) {
    padding: 3px 12px;
  }
}

.highlightedSuggestion,
.listItem:hover,
.listItem:active {
  background-color: var(--marketplaceColorLight);
  cursor: pointer;
}

.predictions {
  margin: 0;
}

.findProductsInfo {
  font-style: italic;
  line-height: 20px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.text,
.textBold,
.searchText {
  color: #001018;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.textBold {
  font-weight: 500;
}

.searchText {
  font-weight: 500;
  color: var(--marketplaceColor)
}

.subtext {
  color: var(--matterColorAnti);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}

.subheading {
  color: var(--matterColorAnti);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;

  @media (--viewportSmall) {
    padding: 0 12px;
  }
}

.subheadingContainer {
  display: flex;
  justify-content: space-between;
}

.clearBtn {
  color: #3643BA;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  text-align: center;
  border: none;
  padding: 0 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clearBtn:active,
.clearBtn:hover {
  color: var(--marketplaceColorDark);
  cursor: pointer;
}

.icon {
  stroke: none;
  width: 10px;
  height: 10px;
  margin-left: 6px;
}
