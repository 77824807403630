@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.consentsWrapper {
  margin-top: 30px;
}

.consentLabel {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 10px;
}

.consentCheckbox {
  display: block;
  margin-bottom: 10px;
}

.requiredLabel:after {
  color: var(--failColor);
  margin-left: 5px;
  content: '(Pole obowiązkowe)';
}

.termsLink {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  color: var(--marketplaceColor);
}

.termsLink:hover {
  text-decoration: underline;
}

.termsLink:focus {
  outline: none;
}