@import '../../../marketplace.css';

.rootForImage {
    width: 120px;
    height: 120px;
    align-self: center;
    border-radius: 8px;
}

.container {
    width: 90vw;

    @media (--viewportMedium) {
        width: 600px;
    }
}

.itemsContainer {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 16px;
    overflow-y: auto;

    @media(--viewportMedium) {
        overflow: hidden;
    }
}

.itemContainer {
    padding: 8px;
    border: 1px solid white;
    border-radius: 8px;
    width: 138px;
}

.itemContainer:hover {
    border: 1px solid var(--matterColorNegative);
}

.item:hover {
    cursor: pointer;
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin: 0 0 20px 0;
}

.itemTitle {
    margin: 12px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
}

.text {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.btnAddToCart {
    font-size: 14px;
    line-height: 18px;
}

.visibleBtn {
    visibility: visible;
}

.hiddenBtn {
    @media (--viewportMedium) {
        visibility: hidden;
    }
}

.spinner {
    stroke: var(--bannedColorDark);
    width: 28px;
    stroke-width: 3px;
}

.scrollContainer {
    display: flex;
    justify-content: space-between;
}

.arrowButton,
.arrowButtonDisabled {
    display: none;
    border: none;
    height: 30px;
    width: 32px;
    align-self: center;

    @media(--viewportMedium) {
        display: inline-block;
    }
}

.arrowButtonDisabled>svg {
    stroke: #fff;
}

.arrowButton>svg {
    stroke: var(--matterColorAnti);
}

.arrowButton:hover {
    cursor: pointer;
}

.arrowButton:hover>svg {
    stroke: var(--matterColor);
}

.arrowButton:disabled {
    cursor: default;
}

.arrowButton:disabled>svg {
    stroke: white;
}

.arrowLeftButton {
    padding-right: 8px;
}

.arrowRightButton {
    padding-left: 8px;
}
