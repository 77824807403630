@import '../../marketplace.css';

.successContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  gap: 6px;
  background: #CBF0C7;
  border: 1px solid var( --successColor);
  border-radius: 4px;
  margin: 16px;

  @media (--viewportMedium) {
    margin:  0 16px 16px 16px;
  }
}

.successContent {
  display: flex;
  align-items: center;
  gap: 6px;
}

.successText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  flex-shrink: 1;
}
