@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 300px;

  /* Text positioning is done with paddings */
}

.title {
  margin: 10px 0 40px 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  width: 100%;
  color: var(--marketplaceColor);

  margin-bottom: 40px 0 80px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (--viewportMedium) {
    margin: 10px 0 80px 0;
    font-size: 26px;
    line-height: 36px;
  }
}

.elements {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  overflow-x: scroll;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    overflow: visible;
  }
}

.elements >  a {
  text-decoration: none;
  border-radius: 12px;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    width: unset;
  }
}

.primaryElement {
  background-color: #f4f4f4;
}

.secondaryElement {
  background-color: #f4f4f4;

  @media (--viewportMedium) {
    grid-column-start: 2;
    grid-column-end: span 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }
}

.minorElementLeft {
  background-color: #f4f4f4;

  @media (--viewportMedium) {
    grid-column-start: 2;
    grid-row-start: 1;
  }
}

.minorElementRight {
  background-color: #f4f4f4;

  @media (--viewportMedium) {
    grid-column-start: 3;
    grid-row-start: 1;
  }
}

.primaryElement .img {
  padding-top: 100%;
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.primaryElement .img .imgWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.primaryElement .img .imgWrapper img {
  width: 100%;
}

.secondaryElement img,
.minorElementLeft img,
.minorElementRight img {
  width: 100%;
}

.secondaryElement .img,
.minorElementLeft .img,
.minorElementRight .img {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.desc  {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(29,107,190);
  opacity: .78;
  color: #FFF;
  padding: 8px 8px;
  height: 72px;
}

.desc h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
}

.desc p {
  display: none;
  margin-top: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.img {
  height: calc(100% - 72px);
  background-position: center center;
  border-radius: 12px 12px 0 0;
  background-size: 100% 100%;
}

.secondaryImages {
  width: 100%;
  object-fit: contain;
}

.secondaryElement .img {
  background-size: auto 100%;

  @media (--viewportMedium) {
    background-size: 100% 100%;
  }
}