.tableWrapper {
  background-color: #FFF;
  width: 100%;
  border-radius: 20px;
  padding: 6px 24px 12px 24px;
  margin-top: 40px;
}

.resultsTable {
  width: 100%;
}

.tableHeader {
  font-size: 14px;
  font-weight: 700;
  height: 40px;
}

.row {
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  padding: 0px 20px;
  height: 40px;
  border-bottom: 1px solid #E4E4E4;
}

.row:last-child {
  border-bottom: none;
}

.row.extended {
  border-bottom: 1px dashed #E4E4E4;
}

td {
  text-align: center; 
  vertical-align: middle;
}

.textToLeft {
  text-align: left;
}

.headerCellWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.descName {
  font-size: 12px;
  color: 	#808080;
  font-weight: 400;
  line-height: 14px;
  font-style: italic;
  margin-left: 10px;
}

.arrow {
  display: inline-block;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-right: 4px;
  margin-left: 8px;
}

.positive {
  border-bottom: 8px solid #2ECC71;
}

.negative {
  border-top: 8px solid #FF0000;
}

.neutral {
  border-bottom: 4px solid #cacccd;
  border-top: 4px solid #cacccd;
  border-radius: 50%;
  border-left: 4px solid #cacccd;
  border-right: 4px solid #cacccd;
}

.deltaValue {
  color: #515151;
  font-size: 13px;
}

.extend {
  cursor: pointer;
}

.chartWrapper {
  height: 200px;
}

.stat {
  text-align: right;
}