@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.contentMain p {
  font-size: 16px;
  font-weight: 400;
}

.contentMain ul {
  list-style-type: circle;
  margin-left: 20px;
}

.contentMain li {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
  line-height: 32px;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}

.searchLink {
  background-color: var(--marketplaceColor);
  color: #FFF;
  padding: 8px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}

.logo {
  width: 240px;
  margin: auto;
  display: block;
}