@import '../../../marketplace.css';

.tableWrapper {
    transition: ease-in-out 0.1s;
    background-color: #FFF;
    border-radius: 12px;
    padding: 14px;
    box-shadow: 0px 0px 15px rgba(160, 160, 160, 0.15);

    @media (--viewportMedium) {
        padding: 8px 16px;
        border-radius: 20px;
    }
}

.cell {
    @media (--viewportMedium) {     
        padding: 8px;
    }
}

.noResults,
.text {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;

    @media (--viewportSmall) {
        text-align: center;
    }
}

.noResults {
    text-align: left;
}

.error {
    color: var(--failColor);
    margin: 16px 16px 16px 0;

    @media (--viewportLarge) {
        margin-top: 18px;
    }
}

.pagination {
    margin-top: auto;
    background-color: var(--matterColorLight);
    padding: 0 24px;

    @media (--viewportLarge) {
        padding: 0 36px;
    }
}

.title {
    font-weight: 700;
    margin: 0;
    padding: 6px 0;
    font-size: 16px;
    line-height: 24px;
}
