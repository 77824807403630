@import '../../marketplace.css';

.panel {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(160, 160, 160, 0.15);
  border-radius: 20px;
}

.panelRow {
  display: flex;
  flex-flow: column nowrap;

  @media(--viewportMedium) {
    flex-flow: row nowrap;
  }
}


.panelRow + .panelRow {
  border-top: 1px solid var(--marketplaceColor);
}

.panelSection {
  padding: 40px;
}

.panelSection + .panelSection {
  border-top: 1px solid #E8E8E8;

  @media (--viewportMedium) {
    border-top: 0;
    border-left: 1px solid #E8E8E8;
  }
}

.width33 {
  width: 100%;
  display: flex;

  @media (--viewportMedium) {
    width: 33%;
    padding-right: 0;
  }
}

.width50 {
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.panelContent {
  display: flex;
  align-items: flex-start;
}

.panelIcon {
  width: 36px;
  margin-right: 22px;
}

.panelValues {
  display: flex;
  flex-flow: column nowrap;
}

.difference {
  margin-left: 14px;
}

.mainValue {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: var(--marketplaceColor);
}

.valueTitle {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 19px;
  color: #515151;
}

.normalValue {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: var(--marketplaceColor);
}

.normalValue + .valueTitle {
  margin-top: 3px;
}

.versus {
  color: #FF8A00;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-right: 4px;
  margin-left: 8px;
}

.positive {
  border-bottom: 8px solid var(--successColor);
}

.negative {
  border-top: 8px solid var(--failColor);
}

.neutral {
  border-bottom: 4px solid #cacccd;
  border-top: 4px solid #cacccd;
  border-radius: 50%;
  border-left: 4px solid #cacccd;
  border-right: 4px solid #cacccd;
}

.deltaValue {
  color: #515151;
  font-size: 15px;
}
