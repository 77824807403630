@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  /* min-height: 600px; */
  height: 550px;
  /* max-height: 600px; */
  padding: 0;

  /* @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  } */
}

.overlay {
  position: absolute;
  /* width: 100vw; */
  height: 333px;
  top: 0px;
  /* right: 20px; */
  bottom: 0;
  left: 0px;
  background: var(--marketplaceColor);
  opacity: 0.8;
  transform-origin: top left;
  transform: scale(1) skew(45deg) translateY(0) translateX(calc(-1 * (332px)));

  @media (--viewportMedium) {
    height: 600px;
    transform: scale(1) skew(55deg) translateY(-168px) translateX(calc(-1.3 * (474px)));
  }

  @media (--viewportLarge) {
    height: 600px;
    transform: scale(1) skew(54deg) translateX(calc(-1.3 * (465px + 215px)));
  }
}

.overlayBottom {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 268px;
  background: var(--marketplaceColor);
  opacity: 0.8;
  content: '';

  @media(--viewportMedium) {
    display: block;
    height: 106px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.landingPageTopbar {
  position: relative;
}

.hero {
  flex-grow: 1;
  background-color: #FFF;
  z-index: 0;

  @media (--viewportMedium) {
    /*padding-bottom: 83px;*/
    padding-bottom: 63px;
    /*margin-bottom: 20px;*/
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 40px;
  }
}

.sections {
  margin: 0;
  padding-top: 20px;
  background-color: #FFF;
}

.section {
  overflow: auto;
}

.sectionWithBackground {
  background-color: #f4f4f4;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionDark {
  /* margin-left: 0;
  margin-right: 0;
  max-width: unset;
  padding: 0; */
  background-color: black !important;
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 0;
  margin-bottom: 0px;
}

.videoOverlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: -72px;
  right: 20px;
  bottom: 0;
  left: 0px;
  background: #1d6bbe;
  opacity: 0.7;
  transform-origin: top left;
  min-height: 600px;
}

.blueBack {
  background-color: #F0F7FF;

  @media (--viewportMedium) {
    background-image: url('./elipse.png');
    background-position: right;
    background-size: auto 100%;
  }
}

.popularCategories {
  display: grid;
  gap: 16px;
  padding: 16px;
  margin: 24px 0;

  @media (--viewportMedium) {
    padding: 32px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 24px;
    margin: 32px 0;
  }

  @media(--viewportLarge) {
    padding: 56px 78px;
    max-width: calc(var(--containerMaxWight) + (78px * 2));
    margin: 32px auto;
  }
}

.popularCategories a {
  position: relative;
  background-color: #eff1f3;
  width: 100%;
  height: 360px;
  border-radius: 8px;
  padding: 16px;
  overflow: hidden;

  @media (--viewportMedium) {
    height: 230px;
    border-radius: 16px;
    display: flex;
    align-items: center;
  }
}

.popularCategories a:hover {
  text-decoration: none;
}

.popularCategories a p {
  margin: 0;
  color: #001018;
  font-size: 20px;
  line-height: 1.2;
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    font-size: 24px;
  }

  @media (--viewportLarge) {
    font-size: 32px;
  }
}

/* .popularCategories a p:first-child {
  color: #8996a2;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 4px;

  @media (--viewportMedium) {
    font-size: 18px;
  }
} */

.popularCategories div {
  z-index: 2;
}

.popularCategories img {
  position: absolute;
}

/* Unique styles for each category (img and div with text) */

/* Category 1 */

.popularCategories .category-1 img {
  width: 280px;
  bottom: 16px;
  right: 50%;
  transform: translateX(50%);

  @media (--viewportMedium) {
    width: auto;
    height: 92%;
    right: 30%;
    top: 50%;
    transform: translate(50%,-50%);
  }
}

.popularCategories .category-1 div {
  @media (--viewportMedium) {
    max-width: 50%;
    padding-left: 24px;
  }
}

/* Category 2 */
.popularCategories .category-2 {
  flex-direction: row-reverse;
}

.popularCategories .category-2 img {
  width: 300px;
  bottom: 0;
  right: 50%;
  top: 50%;
  transform: translate(50%,-50%);

  @media (--viewportMedium) {
    height: 90%;
    width: auto;
    /* width: 40%; */
    right: 75%;
    top: 50%;
    transform: translate(50%,-50%);
  }
}

.popularCategories .category-2 div {
  @media (--viewportMedium) {
    max-width: 50%;
    padding-right: 24px;
  }
}

/* Category 3 */

.popularCategories .category-3 {
  @media (--viewportMedium) {
    grid-row: 1/3;
    grid-column: 2/3;
    height: 100%;
  }
}

.popularCategories .category-3 img {
  height: 70%;
  width: auto !important;
  right: 50%;
  top: 55%;
  transform: translate(55%,-50%);

  @media (--viewportMedium) {
    height: 70%;
    width: auto !important;
    right: 50%;
    top: 60%;
    transform: translate(50%,-50%);
  }
}

.popularCategories .category-3 div {
  @media (--viewportMedium) {
    align-self: flex-start;
    padding: 24px;
    width: 100%;
  }
}

.sportsList {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #FFF;
  padding: 0 16px;
  margin-top: -24px;

  @media (--viewportSmall) {
    padding: 0 24px;
  }

  @media (--viewportMedium) {
    padding: 0 32px;
  }

  @media (--viewportLarge) {
    padding: 0 78px;
    max-width: calc(var(--containerMaxWight) + (78px * 2));
    margin: -14px auto 0 auto;
  }
}

.sportsList::-webkit-scrollbar {
  display: none;
}

.sportsList ul {
  display: flex;
  justify-content: space-between;
  width: 1000px;

  @media (--viewportMedium) {
    width: unset;
  }
}

.sportsList li {
  margin: 0 16px;
}

.sportsList h2 {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  font-weight: 500;
}

.sportsList li a {
  color: #001018;
  display: flex;
  align-items: center;
  flex-direction: column;

  & img {
    width: 80px;
    height: 80px;
  }
}

.sportsList li a:hover {
  color: var(--marketplaceColorDark);
  text-decoration: none;
  transition: color var(--transitionStyle);
}
