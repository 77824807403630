@import '../../marketplace.css';

.selectWrapper {
  min-width: 200px;

  @media (--viewportMedium) {
    min-width: 250px;

  }
}

.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.cancelled {
  color: var(--failColor);
}

.accepted {
  color: var(--successColor);
}

.pending {
  color: #fa0;
}

.expired {
  color:  #979595;
}

.textButton {
  padding-right: 12px;
}

.closeButton {
  padding: 6px 12px;
  border-radius: 5px;
  text-align: left;
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--marketplaceColor);
  margin-bottom: 8px;
}

.closeButton:hover {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-bottom: 0;

  @media (--viewportSmall) {
    flex-direction: row;
    column-gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}

.searchInputWrapper {
  padding-bottom: 0;
  gap: 0;
}

.list {
  border-top: 1px solid var(--matterColorAnti);
  margin: 0;
  padding: 20px 0;
  ;
}

.error {
  color: var(--failColor);
  margin: 16px 16px 16px 0;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.chooseCountry {
  padding-top: 16px;
  margin: 0;
  color: var(--matterColorAnti);
}

.totalItems {
  transition: ease-in-out 0.1s;
  margin: 0 8px 8px 0;
  text-align: right;
  font-size: 16px;
  font-weight: 400;

  @media(--viewportMedium) {
    margin: 0 8px 0 0;
  }
}

.totalItems span {
  font-weight: 500;
}

.btn {
  @apply --marketplaceButtonFontStyles;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  height: 44px;
  color: #FFF;
  align-self: flex-end;
  margin: 16px 0;
  width: 100%;

  @media(--viewportSmall) {
    width: auto;
    margin-bottom: 4px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.btn:hover {
  border: 1px solid var(--marketplaceColorDark);
  background-color: var(--marketplaceColorDark);
  cursor: pointer;
}
