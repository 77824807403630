@import '../../marketplace.css';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  gap: 6px;
  background: #FFFBC7;
  border: 1px solid #F8AF2D;
  border-radius: 4px;
  margin: 16px;

  @media (--viewportMedium) {
    margin:  0 16px 16px 16px;
  }
}

.content {
  display: flex;
  align-items: center;
  gap: 6px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  flex-shrink: 1;
  color: #001018;
}
