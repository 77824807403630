@import '../../../marketplace.css';

.container {
  margin: 16px 0;
  transition: ease-in-out 0.2s;

  @media(--viewportSmall) {
    padding: 24px;
    box-shadow: 0px 8px 28px 0px rgba(136, 136, 136, 0.20);
    border-radius: 8px;
  }
}

.container * {
  transition: ease-in-out 0.2s;
}

.title {
  margin: 0;
  padding-bottom: 16px;
}

.formContainer {
  display: flex;
  flex-direction: column;

  @media(--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    margin-bottom: 20px;
  }
}

.itemContainer {
  @media (--viewportSmall) {
    width: 300px;
  }
}

.actionContainer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.cancelButton {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  height: 40px;
  padding: 0 12px;

  @media(--viewportMedium) {
    width: auto;
  }
}

.submitButton {
  min-height: 40px;
  height: 40px;
  min-width: 106px;
  padding: 0 32px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  border-radius: 4px;

  @media(--viewportMedium) {
    width: auto;
  }
}

.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.error {
  color: var(--failColor);
  margin: 0;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.checkboxInput {
  width: auto;
}

.checkbox>input[type=checkbox] {
  visibility: hidden;
}

.checkbox {
  display: flex;
  justify-items: center;
  position: relative;
  padding: 0;
  margin-top: 8px;
  cursor: pointer;
  height: 16px;
}

.checkboxText {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid var(--matterColorAnti);
  border-radius: 4px;
}

.checkboxText>span {
  padding-left: 22px;
  line-height: 16px;
  position: relative;
  bottom: 30%;
  font-weight: 500;
  display: inline-block;
  width: 180px;
}

.checkbox input:checked~.checkboxText {
  background-color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
}

.checkboxText:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked~.checkboxText:after {
  display: block;
}

.checkbox .checkboxText:after {
  left: 5px;
  bottom: 2px;
  width: 4px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.inputsContainer {
  margin-top: 20px;

  @media(--viewportLargeCart) {
    width: 100%;
    display: flex;
    gap: 32px;
  }
}

.amountContainer {
  @media(--viewportMedium) {
    margin-top: 0;
  }
}

.textareaContainer {
  width: 100%;
}

.descriptionTextarea {
  resize: none;
  height: 102px;
  width: 100%;
  box-sizing: border-box;
}

.expDateContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}

.errorForm {
  color: var(--failColor);
  font-size: 12px;
  margin: 0;
  font-weight: 400;
}

.dateContainer {
  margin: 20px 0;

  @media(--viewportMedium) {
    margin: 0;
  }
}

.dateContainer > div:first-child {
  @media(--viewportMedium) {
    margin-bottom: 8px;
  }
}
