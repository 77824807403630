@import '../../../marketplace.css';

:root {
  --AutocompleteLocation_inputHeight: 50px;
  --AutocompleteLocation_sidePadding: 24px;
  --AutocompleteLocation_sidePaddingDesktop: 36px;
}

.root {
  position: relative;
  display: flex;
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.input {
  flex-grow: 1;
  height: var(--AutocompleteLocation_inputHeight);
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;

  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}
