@import '../../marketplace.css';


.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.addButton {
  border: none;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 16px 0 4px 0 ;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  color: var(--marketplaceColor);
}

.addButton:hover {
  text-decoration-line: underline;
}

.error {
  color: var(--failColor);
  margin: 0;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.filterContainer {
  display: flex;
  flex-direction: column;

  @media(--viewportMedium) {
    /*column-gap: 16px;*/
    /*min-width: 45%;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
  }

  @media(--viewportXLarge) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.filter {
  width: 100%;

  @media(--viewportSmall) {
    min-width: 45%;
  }
}

.selectWrapper {
  min-width: 300px;

  & div {
    padding: 0;
  }
}
