@import '../../../marketplace.css';

.container {
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 24px;

    @media(--viewportMedium) {
        border-top: none;
        padding: 24px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }

    @media(--viewportLargeCart) {
        flex-shrink: 0;
        width: 473px;
    }
}

.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
}

.wrapper {
    display: flex;
    gap: 16px;
}

.codeWrapper {
    width: 100%;
    margin: auto 0;
}

.labelText {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: var(--matterColorAnti);
}

.promoCodeContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 10px 0 8px 0;
}

.removeBtn {
    border: none;
    cursor: pointer;
}

.positionBtn {
    position: absolute;
    right: 10px;
    bottom: 14px;
}

.promoText {
    margin: 0;
    background: var(--disabledButtonColor);
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.inputCode::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--matterColorAnti);
}

.inputCode:focus {
    border: 1px solid var(--matterColor);
}

.inputContainer {
    position: relative;
    width: 100%;

    & input {
        border: 1px solid #e6e6e6;
    }

    @media(--viewportMedium) {
        max-width: 300px;
    }
}

.btn {
  @apply --marketplaceButtonStyles;
    padding: 0 24px;
    width: auto;
    border: 1px solid var(--marketplaceColorDark);
  margin: 4px 0;

    @media(--viewportSmall) {
        margin: 10px 0 8px 0;
    }
}

.btn:disabled,
.btn:disabled:hover  {
    background: var(--disabledButtonColor);
    border: 1px solid var(--disabledButtonColor);
    cursor: not-allowed;
}

.btn:hover {
    background: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColorDark);
}

.successMessage,
.errorMessage {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

.successMessage {
    color: var(--successColor);
}

.errorMessage {
    color: var(--failColor);
}
