@import '../../marketplace.css';

.selectWrapper {
  @media (--viewportSmall) {
    min-width: 304px;
  }
}

.dateContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}
