@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 0 24px 64px 24px;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px; */

  display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 32px;

    @media (--viewportSmall) {
      padding: 0 36px 64px 36px;
      justify-content: flex-start;
    }

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 24px;
    padding: 0 36px 64px 36px;
  }
}

.listingCard {
  flex-basis: 45%;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(25% - 24px);
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(3n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color:transparent;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-bottom: 36px;
  }
}

.otherListings {
  background-color: #f5f4f5;
  padding: 24px 0 24px 0;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportSmall) {
    padding: 36px 0 36px 0;
    margin-bottom: 48px;
    margin-left: 36px;
    margin-right: 36px;
  }

  @media (--viewportLarge) {
    padding: 36px 0 36px 0;
    margin-left: 36px;
    margin-right: 36px;
  }
}