@import '../../../marketplace.css';

.listItem {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
}

.listItem:last-child {
  border-bottom: none;
}

.item {
  display: flex;
}

.itemAvatar {
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-top: 2px;
    margin-right: 15px;
  }
}

.itemDetails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 6px;

  @media (--viewportSmall) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.text,.itemDateDetails,.itemDateBanned {
  color: var(--matterColorAnti);
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.itemDateDetails,.itemDateBanned {
  @media (--viewportSmall) {
    text-align: center;
  }
}

.itemDateBanned {
  color: #E3262F;
}

.itemNameDetails>p:first-child {
  font-size: 16px;
  color: var(--matterColor);
}

.link {
  font-size: 14px;
  white-space: nowrap;
  padding-top: 8px;
}

.name {
  min-height: 26px;
}

.nameText {
  padding-right: 16px;
}

.bannedButton {
  min-height: 40px;
  width: auto;
  padding: 0 12px;
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
}

.bannedIcon {
  font-family: 32px;
}

.bannedText {
  display: inline-block;
  color: #E3262F;
  background: #FEC9CB;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius: 2px;
  padding: 4px 8px;
  text-align: center;
}

.itemBlocking {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 140px;
}

.separator {
  font-size: 14px;
}
