@import '../../marketplace.css';

.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
}

.mapRoot {
  width: 100%;
  height: 100%;
}

.reusableMap {
  width: 100%;
  height: 100%;
}

.defaultMapLayout {
  position: fixed;
  top: 0;
  right: 0;
  width: 50vw;
  height: 100vh;
}

/**
 * When reusable map is attached right to the body it's hidden.
 * Specificity rule is added to overwrite positioning coming from props.className
 */
body > .reusableMapHidden {
  position: absolute;
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  opacity: 0;

  @media (--viewportMedium) {
    top: -1000px;
    left: -1000px;
    right: auto;
  }
}

.productInfo {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  background-color: #fff;
  width: 60%;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 8px;
  text-align: center;
  padding: 8px 6px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

  @media (--viewportMedium) {
    display: none;
  }
}

/* .markerClusterer > img {
  content: url('./pin.png') !important;
} */

/* .markerClusterer > div {
  color: #fff !important;
} */