@import '../../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  /* Layout: size and positioning */
  width: 100%;
  height: 100%;
  margin-top: 24px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating>fieldset>div {
  margin: 6px;
}

.reviewContent {
  flex-shrink: 0;
  margin-top: 16px;
  margin-bottom: 16px;
}

.submitButton {
  align-self: flex-end;
  flex-shrink: 0;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 12px;
  min-height: 44px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    display: inline-block;
    width: 100%;
  }
}
