@import '../../marketplace.css';

:root {
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
  --ManageAvailabilityCalendar_reservedColor: #e6fff0;
  --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;
}

.root {
  @apply --marketplaceH5FontStyles;
  margin-top: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  z-index: 0;

  & :global(.CalendarMonth_caption) {
    @apply --marketplaceH3FontStyles;
    font-weight: var(--fontWeightMedium);
    text-align: left;
    padding-top: 18px;
    padding-bottom: 55px;

    margin-left: 97px;
    margin-top: 0;
    margin-bottom: 0;

    & strong {
      font-weight: 400;
      letter-spacing: 0.2px;
    }
  }

  & :global(.DayPicker) {
    margin: 0 auto;
    box-shadow: none;
  }

  & :global(.DayPicker__horizontal) {
    background-color: transparent;
    margin-left: -18px;
  }

  & :global(.DayPicker_weekHeader) {
    top: 65px;

    & small {
      @apply --marketplaceH5FontStyles;
    }
  }
  & :global(.DayPicker_weekHeader_li) {
    text-align: left;
  }

  & :global(.DayPickerNavigation__horizontal) {
    width: 80px;
    margin-left: 18px;
    position: relative;

    & :first-child {
      border-top-left-radius: 2px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 2px;
    }

    & :last-child {
      /* The navigation arrows have 9px padding. Add -9px margin to
      align the arrows with the calendar */
      left: 35px;
      right: unset;
      border-top-left-radius: 0;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 0;
    }
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 13px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px var(--matterColorNegative);
    background-color: var(--matterColorBright);

    &:hover {
      background-color: var(--ManageAvailabilityCalendar_availableColorHover);

      & svg {
        fill: var(--matterColorDark);
      }
    }
    &:focus {
      outline: none;
      background-color: var(--ManageAvailabilityCalendar_availableColorHover);
      & svg {
        fill: var(--matterColorDark);
      }
    }
  }

  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.CalendarMonth) {
    background-color: transparent;
  }

  & :global(.CalendarMonth_table) {
    border: 1px solid var(--ManageAvailabilityCalendar_gridColor);
  }

  & :global(.CalendarDay__default) {
    border: 0;
    background-color: var(--ManageAvailabilityCalendar_gridColor);

    &:hover {
      border: 0;
      background-color: var(--ManageAvailabilityCalendar_gridColor);
    }
  }

  & :global(.CalendarDay__selected) {
    color: var(--matterColor);
  }

  & :global(.DayPickerKeyboardShortcuts_show__bottomRight) {
    right: -20px;
    right: 19px;
    bottom: -24px;
  }
}

.dayWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.day {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_availableColorHover);
  }
}

.dayNumber {
  font-size: 14px;
  line-height: 14px;
}

.totalSeats {
  font-size: 10px;
  line-height: 10px;
  margin-top: 0;
  margin-bottom: auto;
  margin-left: 0;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 14px;
  }
}

.default {
  composes: day;
  background-color: var(--ManageAvailabilityCalendar_availableColor);
}

.outsideRange {
  background-color: #A9A9A9;

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  }

  & .dayNumber {
    text-decoration: line-through;
    color: lightgrey;
  }
}

.today {
  background-color: var(--ManageAvailabilityCalendar_availableColor);

  & .dayNumber {
    position: relative;
    text-decoration: none;

    /* underline */
    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 100%;
      right: 0;
      bottom: -4px;
      left: 0;
      margin: 0 auto;
      background-color: var(--matterColor);
    }
  }
}

.blocked {
  background-color: var(--ManageAvailabilityCalendar_blockedColor);

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  }
}

.reserved {
  background-color: #41ce79;
  color: #FFF;

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_reservedColorHover);
  }
}

.partiallyReserved {
  background-color: #41ce79;
  opacity: 0.7;
  color: #FFF;

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_reservedColorHover);
  }
}

.overbooked {
  background-color: var(--failColor);
  color: #FFF;

  &:hover {
    background-color: #FEC9CB;
  }
}

.inProgress {
  width: 12px;
  height: 12px;
  stroke: var(--matterColor);
  stroke-width: 3px;
}

.exceptionError {
  opacity: 1;

  /* Animation */
  animation-name: errored;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes errored {
  30%,
  70% {
    background-color: var(--ManageAvailabilityCalendar_failedColor);
  }
}

.monthElement {
  display: flex;
  position: relative;
}

.monthString {
  font-weight: var(--fontWeightSemiBold);

  &:first-letter {
    text-transform: uppercase;
  }
}

.monthInProgress {
  width: 12px;
  height: 12px;
  margin-top: 7px;
  margin-left: 8px;

  stroke: var(--marketplaceColor);
  stroke-width: 4px;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 6px 0 24px 0;
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColor);
  fill: var(--matterColor);
}

.legend {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.legendRow {
  display: flex;
  flex-grow: row;
  line-height: 24px;
  margin-right: 18px;
  margin-bottom: 6px;
}
.legendColor {
  width: 20px;
  height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  margin-top: 2px;
  margin-right: 8px;
}
.legendAvailableColor {
  composes: legendColor;
  background-color: var(--matterColorLight);
  border: solid 2px var(--matterColorNegative);
}
.legendReservedColor {
  composes: legendColor;
  background-color: #41ce79;
}
.legendPartiallyReservedColor {
  composes: legendColor;
  background-color: #41ce79;
  opacity: 0.7;
}
.legendBlockedColor {
  composes: legendColor;
  background-color: #A9A9A9;
}
.legendOverbookingColor {
  composes: legendColor;
  background-color: var(--failColor);;
}

.legendText {
  @apply --marketplaceH4FontStyles;
  white-space: nowrap;
  margin: 0;
}

.headingModal {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  padding-top: 16px;

  @media (--viewportMedium) {
    margin: 0;
    padding-top: 0;
  }
}

.dateInEditSeats {
  color: var(--marketplaceColor);
}

.editSeatsFields {
  display: flex;
  flex-direction: column-reverse;
  gap: 4px;
  padding: 32px 0 64px 0;

  @media (--viewportMedium) {
    padding: 32px 0 48px 0;
  }
}

.bookedSeats h4 {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
  padding-bottom: 2px;
}

.bookedSeats span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.bookedSeats label {
  margin: 0;
  padding: 0;
}

.seatContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seatsInput::-webkit-outer-spin-button,
.seatsInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.seatsInput[type=number] {
  -moz-appearance: textfield;
  width: 100px;
}

.numbers {
  display: flex;
  justify-content: space-between;
}

@media (--viewportSmall) {
  /* day aka table cell content should have bigger paddings when there's more space */
  .day {
    padding: 16px;
  }
  .dayNumber {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }
}

/* Standard break points */
@media (--viewportMedium) {
  .root {
    margin-top: 0;
    margin-bottom: 0;

    & :global(.CalendarMonth_caption) {
      font-size: 24px;
      padding-bottom: 62px;
    }
    & :global(.DayPicker_weekHeader) {
      top: 67px;
    }

    & :global(.DayPickerNavigation__horizontal) {
      & :last-child {
        /* The navigation arrows have 9px padding. Add -9px margin to
        align the arrows with the calendar */
        left: 39px;
      }
    }

    & :global(.DayPickerNavigation_button__horizontal) {
      width: 40px;
      height: 40px;
    }
  }

  .inProgress {
    width: 16px;
    height: 16px;
  }

  .monthInProgress {
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }
  .error {
    margin: 8px 0 24px 0;
  }

  .legendRow {
    margin-right: 24px;
    margin-bottom: 8px;
  }
  .legendText {
    margin: 0;
  }
}

@media (--viewportLarge) {
  .root {
    & :global(.DayPickerNavigation_button__horizontal) {
      background-color: var(--matterColorLight);
    }
  }

  .legend {
    margin: 0;

    @media(--viewportLarge) {
      margin-top: 16px;
    }

    @media(--viewportLargeWithPaddings) {
      margin: 0;
    }

  }
}

@media (--viewportXLarge) {
  .root {
    & :global(.CalendarMonth_caption) {
      margin-left: 108px;
    }
  }
}

.modalContentContainer {
  display: flex;
  flex-direction: column;
}

.closeButton {
  align-items: center;
}

.modalSubmit {
  min-height: auto;
  padding: 14px 24px;
  width: 182px;
  height: 48px;
  align-self: flex-end;
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
    padding: 54px 40px 32px 40px;
  }
}

.seatInput {
  padding-top: 16px;

  & input {
    border: 1px solid grey;
  }
}

.numbersWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.reasonInput {
  display: block;

  & textarea {
    border: 1px solid grey;
  }
}
