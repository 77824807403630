@import '../../marketplace.css';

.root {
  & label {
    font-size: 14px;
    line-height: 25px;
    color: #4A4A4A;
    margin-bottom: 6px;
  }
}

.select {
  color: var(--matterColorAnti);
  border-color: var(--attentionColor);
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}
