@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingCustomForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 86px;
  }
}

.field {
  @apply --EditListingCustomForm_formMargins;
  flex-shrink: 0;
}

.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  padding: 6px 0 2px;
}

.checkbox span {
  font-size: 20px;
  font-weight: 500;
}

.addBtn {
  cursor: pointer;
  color: #2ecc71;
  text-align: center;
  @apply --EditListingCustomForm_formMargins;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #2ecc71;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-size: 36px;
  margin: 0 auto;
}

.addBtn span:first-child, .removeBtn span:first-child, .addOptionBtn span:first-child {
  margin-right: 5px;
}

.addBtn svg {
  width: 24px;
  height: 24px;
}

.addBtn path {
  fill: #2ecc71;
}

.removeBtn {
  cursor: pointer;
  font-size: 16px;
  color: var(--failColor);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--failColor);
  border-radius: 50%;
  padding: 2px;
  width: 28px;
  height: 28px;
  margin: 0 0 0 auto;
}

.removeBtn svg {
  width: 16px;
  height: 16px;
  stroke: var(--failColor);
}

.removeBtn path, .removeOptionBtn path {
  fill: var(--failColor);
}

.removeOptionBtn {
  cursor: pointer;
  font-size: 12px;
  color: var(--failColor);
  display: flex;
  align-items: center;
}

.removeOptionBtn svg {
  width: 12px;
  height: 12px;
  stroke: var(--failColor);
}

.addOptionBtn {
  color: #2ecc71;
  @apply --EditListingCustomForm_formMargins;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addOptionBtn svg {
  width: 12px;
  height: 12px;
}

.addOptionBtn path {
  fill: #2ecc71;
}

.fieldWrapper {
  border: 1px solid #e7e7e7;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(160, 160, 160, 0.15);
}

.firstRow {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
}