@import '../../marketplace.css';

.root {}

.title {
  margin-top: 8px;
  margin-bottom: 19px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #515151;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.layoutWrapperMain {
  background-color: #f3f3f3;
}

.wrapper {
  transition: ease-in-out 0.1s;
  background-color: #FFF;
  border-radius: 12px;
  padding: 14px;
  box-shadow: 0px 0px 15px rgba(160, 160, 160, 0.15);

  @media (--viewportMedium) {
      padding: 8px 16px;
      border-radius: 20px;
  }
}

.cell {
  @media (--viewportMedium) {     
      padding: 8px;
  }
}

.navigation {
  padding: 16px 0 ;
  display: flex;
  align-items: center;

  @media(--viewportMedium) {
      padding: 0 0 16px 0;
  }
}

.linkText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColor);
}

.arrow {
  padding-left: 1px;
  margin: 0 8px;
}

.code {
  color: var(--marketplaceColor);
}

.code:hover {
  text-decoration: underline;
  color: var(--marketplaceColor);
}

.link:hover {
  text-decoration: none;
  color: var(--marketplaceColor);
}

.pagination {
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;
  margin: 16px 0;

  @media (--viewportLarge) {
      padding: 0 36px;
  }
}

.pagination a {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 40px;
  font-size: 16px;
  line-height: 24px;
}

.pagination span {
  padding-top: 10px;
  padding-bottom: 6px;
  font-size: 16px;
  line-height: 24px;
}

.pendingStatus, .readyStatus {
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
}

.readyStatus {
  color: var(--successColorDark);
}

.pendingStatus {
  color: var(--marketplaceColorLight);
}
