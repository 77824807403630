@import '../../marketplace.css';

.skeletonLabel,
.skeletonCell {
    height: 24px;
    width: 100%;
    margin: 8px 0;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 4px;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.skeletonLabel::after,
.skeletonCell::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    animation: shimmer 2s linear 0.5s infinite;
    content: '';
}
