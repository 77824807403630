@import '../../marketplace.css';

.selectWrapper {
  @media (--viewportSmall) {
    min-width: 304px;
  }
}

.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.textButton {
  padding-right: 12px;
}

.closeButton {
  padding: 6px 12px;
  border-radius: 5px;
  text-align: left;
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--marketplaceColor);
}

.closeButton:hover {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;

  @media (--viewportSmall) {
    flex-direction: row;
    column-gap: 24px;
    row-gap: 4px;
    flex-wrap: wrap;
    padding-bottom: 24px;
  }
}

.table {
  transition: ease-in-out 0.1s;

  @media (--viewportLarge) {
    border-top: 1px solid #E4E4E4;
  }
}

.cell {
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  margin: 0;
  line-height: 18px;
}

.checkboxContainer {
  display: flex;
  gap: 24px;
}

.checkboxLabel {
  display: flex;
  gap: 8px;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.avgRatingContainer {
  transition: ease-in-out 0.1s;

  @media(--viewportSmall) {
    height: fit-content;
    align-self: center;
  }
}

.avgRating {
  margin: 0;
  border: 1px solid #F8AF2D;
  background-color: #FFFBC7;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
  text-align: center;

  @media (--viewportSmall) {
    width: 300px;
  }
}

.ratingTitle {
  margin: 0;
  padding-bottom: 6px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.error {
  color: var(--failColor);
  margin: 16px 16px 16px 0;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.btn {
  min-height: 34px;
  height: 34px;
  min-width: 80px;
  padding: 0 12px;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;


  @media(--viewportSmall) {
    width: auto;
    padding: 0 24px;
  }

  @media(--viewportMedium) {
    min-height: 40px;
    height: 40px;
    align-self: center;
  }
}

.totalItems {
  transition: ease-in-out 0.1s;
  margin: 0 8px 8px 0;
  text-align: right;
  font-size: 16px;
  font-weight: 400;

  @media(--viewportMedium) {
    margin: 0 8px 0 0;
  }
}

.totalItems span{
  font-weight: 500;
}

.option {
  -moz-white-space: pre-wrap;
  -o-white-space: pre-wrap;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
