@import '../../marketplace.css';

.pagination {
  margin: auto 24px 0 24px;
}

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px;

  @media (--viewportLarge) {
    margin-top: 6px;
  }
}

.itemList {
  margin: 16px 0 0 0;
  padding: 0;
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}


.cancelled {
  color: var(--failColor);
  background: #FEC9CB;
}

.accepted {
  color: var(--successColor);
  background: #CBF0C7;
}

.pending {
  color: #fa0;
  background: #feeac2;
}

.expired {
  color:  #979595;
  background: #e5e5e5;
}
