@import '../../marketplace.css';

.featuresWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;

  @media (--viewportMedium) {
    flex-flow: row nowrap;
  }
}

.feature {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 58px;
  width: 50%;

  @media (--viewportMedium) {
    width: unset;
  }
}

.feature:nth-child(1),
.feature:nth-child(2) {
  margin-bottom: 30px;
  
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.feature + .feature {
  @media (--viewportMedium) {
    border-left: 1px solid #c4c4c4;
  }
}

.title {
  font-size: 14px;
  line-height: 20px;
  color: #666;
  text-align: center;
  margin-top: 40px;
}