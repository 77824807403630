@import '../../marketplace.css';

.colorBackground {
  background: #f3f3f3;
}

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 19px;
  }
}

.title {
  @media (--viewportMedium) {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #515151;
    margin: 0;
  }
}

.tableWrapper {
  background-color: #FFF;
  width: 100%;
  border-radius: 20px;
  padding: 6px 24px 12px 24px;
  margin-top: 40px;
}

.resultsTable {
  width: 100%;
}

.tableHeader {
  font-size: 14px;
  font-weight: 700;
  height: 40px;
}

.row {
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  padding: 0px 20px;
  height: 40px;
  border-bottom: 1px solid #E4E4E4;
}

.row:last-child {
  border-bottom: none;
}

td {
  text-align: center; 
  vertical-align: middle;
}

.textToLeft {
  text-align: left;
}

.headerCellWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.descName {
  font-size: 12px;
  color: 	#808080;
  font-weight: 400;
  line-height: 14px;
  font-style: italic;
}

.lastUpdate {
  font-size: 12px;
  font-weight: 400;
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.comparisonDates {
  margin-top: 10px;
  font-size: 16px;
  color: #FF8A00;
  display: flex;
  align-items: center;
}

.removeComparison {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid #FF8A00;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}