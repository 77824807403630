@import '../../marketplace.css';

.container {
  position: relative;
  z-index: 100;
}

.btn {
  position: fixed;
  z-index: 20;
  color: #fff;
  cursor: pointer;
  transition: all .5s ease-in-out;
  left: 0;
  bottom: 0;
  width: 64px;
  height: 64px;
  border-radius: 0;
  border-style: solid;
  border-width: 0 64px 64px 0;
  border-color: transparent transparent var(--marketplaceColor) transparent;
  background: transparent;
  box-shadow: none;
  animation: none;
  padding: 0;
}

.btn:hover  {
  animation: none;
  background:  var(--marketplaceColorDark);
  color: #FFF;
  border-style: solid;
  border-width: 0 64px 64px 0;
  border-color: transparent transparent var(--marketplaceColorDark) transparent;
  background: transparent;
}

.iconStyle {
  fill: #FFF;
  stroke: #FFF;
  height: 12px;
  width: 12px;
  animation: moveArrow 1s ease-in-out 400ms;
  animation-iteration-count: 3;
  transition: all .5s ease-in-out;
  top: 37px;
  left: 13px;
  position: absolute;
}

@keyframes moveArrow {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(4px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-4px);
  }
  100%{
    transform: translateY(0px);
  }
}
