@import '../../marketplace.css';

:root {
    /* Variables to change */
    --backgroundColor: #FFF;
    --backgroundColorHover: var(--matterColorBright);
    --backgroundColorChecked: var(--matterColorBright);
    --backgroundColorIconChecked: var(--marketplaceColor);
    --border: 1px solid #b2b2b2;
    --borderHover: 1px solid var(--marketplaceColor);
    --borderChecked: 1px solid var(--marketplaceColor);
    --borderIconChecked: 5px solid var(--marketplaceColor);
    --borderRadius: 50%;
    --boxShadow: none;
    --textColor: var(--matterColor);
}

.label {
    display: block;
    position: relative;
    padding: 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 400;   
}

.smallLabel {
    padding-left: 24px;
    line-height: 14px;
    font-size: 14px;
}

.mediumLabel {
    padding-left: 26px;
    line-height: 16px;
    font-size: 16px;
}

.largeLabel {
    padding-left: 30px;
    line-height: 20px;
    font-size: 16px;
}

.label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.large, .medium, .small {
    position: absolute;
    bottom: 0;
    left: 0;
    border:  var(--border);
    border-radius: var(--borderRadius);
    background-color: var(--backgroundColor);
    box-shadow: var(--boxShadow);
}

.label:hover .large, .label:hover .medium:hover, .label:hover .small:hover {
    cursor: pointer;
    border: var(--borderHover);
}

.large {
    height: 20px;
    width: 20px;
}

.medium {
    height: 16px;
    width: 16px;
}

.small {
    height: 14px;
    width: 14px;
}

.label:hover input~.radio {
    background-color: var(--backgroundColorHover);
}

.label input:checked~.radio {
    background-color: var(--backgroundColorChecked);
    border: var(--borderChecked);
    border-radius: var(--borderRadius);
}

.radio:after {
    content: "";
    position: absolute;
    display: none;
}

.label input:checked~.radio:after {
    display: block; 
}

.label .large:after, 
.label .medium:after,
.label .small:after {
    background-color: var(--backgroundColorIconChecked);
    border: var(--borderIconChecked);
    border-radius: var(--borderRadius);
}

.label .large:after {
    left: 2px;
    bottom: 2px;
    width: 14px;
    height: 14px;
}

.label .medium:after {
    left: 2px;
    bottom: 2px;
    width: 6px;
    height: 6px;
}

.label .small:after {
    left: 1px;
    bottom: 1px;
    width: 4px;
    height: 4px;
}

.text {
    color: var(--textColor);
}