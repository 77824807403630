@import '../../../marketplace.css';

.root {
  margin: 0;

  /* Clearfix */
  @apply --clearfix;
}

.root >:last-child .line {
  border-left: 3px solid transparent;
  z-index: -1;
}

.root >:last-child .transitionContentWrapper {
  padding: 0 0 0 16px;
}

.messageItem {
  padding-bottom: 20px;

  /* Clearfix */
  @apply --clearfix;

  @media (--viewportMedium) {
    padding-bottom: 17px;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 433px;
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0 0 auto;
}

.ownMessage {
  float: right;
}

.ownMessageContentWrapper {
  @apply --clearfix;
}

.messageContent,
.ownMessageContent {
  @apply --marketplaceMessageFontStyles;

  display: inline-block;
  margin: 0;
  margin-left: 16px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 16px;
    margin: 0;
    margin-left: 16px;
  }
}

.messageContent {
  flex: 1;
  background: var(--activeColor);
}

.ownMessageContent {
  /* background-color: var(--marketplaceColor);
  color: var(--matterColorLight); */
  background: #F5F4F5;
  /* float: right; */
  margin-left: 16px;
}

.messageDate,
.ownMessageDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--matterColorAnti);
  margin-top: 4px;
}

.ownMessageDate {
  text-align: left;
  padding-left: 16px;
}

.messageDate {
  text-align: right;
}

.ownMessagePerson {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.transitionContentWrapper {
  padding: 0 0 16px 16px;
}

.transitionContent {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.transitionDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--matterColorAnti);
  margin: 0;
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}

.step {
  position: relative;
}

.circle {
  background-color: var(--matterColor);
  border: 1px solid var(--matterColor);
  border-radius: 100%;
  width: 7px;
  height: 7px;
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 0;
}

 .line {
  top: 15px;
  left: 3px;
  height: 100%;
  position: absolute;
  border-left: 1px solid var(--matterColorAnti);
}


.text {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.productContainer {
  display: flex;
}

.productContainer p:first-child {
  padding-right: 8px;
}
