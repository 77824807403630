@import '../../marketplace.css';

.error {
  color: var(--failColor);
  margin: 16px 16px 16px 0;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.pagination {
  margin: auto 24px 0 24px;
}

.searchInputWrapper {
  padding-top: 6px;
}

.listItemsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.selectWrapper {
  min-width: 200px;

  @media (--viewportMedium) {
    min-width: 250px;

  }
}

.filterWrapper {
  border-bottom: 1px solid var(--matterColorAnti);
}

.filterContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  @media(--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
  }

  @media(--viewportXLarge) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.totalItems {
  transition: ease-in-out 0.1s;
  margin: 0 8px 8px 0;
  text-align: right;
  font-size: 16px;
  font-weight: 400;

  @media(--viewportMedium) {
    margin: 0 8px 0 0;
  }
}
