@import '../../marketplace.css';

.root {
  padding: 16px;
  background-color: #fff;
  height: auto;

  @media(--viewportMedium) {
    padding: 24px;
    min-width: 400px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }

  @media(--viewportLargeCart) {
    flex-shrink: 0;
    width: 473px;
  }
}

.orderContent {
  padding: 0;
  width: 100%;

  @media(--viewportMedium) {
    width: 100%;
  }

  @media(--viewportLargeWithPaddings) {
    width: 100%;
  }
}

.summaryTitle, .title, .code {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  @media(--viewportMedium) {
    font-size: 20px;
    line-height: 28px;
  }
}

.summaryTitle {
  padding-top: 32px;
  padding-bottom: 8px;
}

.code {
  color: var(--marketplaceColor)
}

.togetherSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0;
}

.togetherText {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
}

.itemSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0 16px 0;
  border-bottom: 1px solid var(--matterColorAnti);
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  gap: 24px;
}


.itemText {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin: 0;

  @media(--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.link {
  @apply --marketplaceButtonStylesPrimary;
  padding: 10px 0;
}
