@import '../../../marketplace.css';

.skeleton,
.skeletonBtn {
    height: 20px;
    width: 100%;
    margin: 8px 0;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 4px;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.skeleton::after,
.skeletonBtn::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    animation: shimmer 2s linear 0.5s infinite;
    content: '';
}

.skeletonBtn {
    @media(--viewportSmall) {
        width: 300px;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.statusSection {
    background-color: #FFF;
    width: 100%;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    overflow-x: scroll;
    box-shadow: 0px 0px 15px rgba(160, 160, 160, 0.15);

    @media (--viewportSmall) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    @media (--viewportMedium) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        border-radius: 20px;
    }
}

.statusContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-bottom: 1px solid #E8E8E8;

    @media (--viewportSmall) {
        border-bottom: none;
        flex-direction: column;
        padding: 16px;
    }

    @media (--viewportMedium) {
        border-left: 1px solid #E8E8E8;
        border-top: none;
    }
}

.statusContainer:first-child {
    @media (--viewportMedium) {
        border-left: none;
    }
}

.statusContainer:last-child {
    border-bottom: none;
}

.statusContainer:nth-last-child(2),
.statusContainer:last-child {
    @media (--viewportSmall) {
        border-top: 1px solid #E8E8E8;
    }

    @media (--viewportMedium) {
        border-top: none;
    }
}

.statusContainer:nth-child(2n) {
    @media (--viewportSmall) {
        border-left: 1px solid #E8E8E8;
    }
}