@import '../../marketplace.css';

.toggleWrapper {
  display: flex;
  align-items: center;
}

.title {
  font-weight: 400;
  font-size: 16px;
  display: block;
  margin-right: 10px;
}

.toggleSwitch {
  position: relative;
  width: 46px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  & .toggleSwitchCheckbox {
    display: none;
  }

  & .toggleSwitchLabel {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
    padding: 0;
  }

  & .toggleSwitchInner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 24px;
      padding: 0;
      line-height: 24px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "";
      text-transform: uppercase;
      padding-left: 10px;
      background-color: var(--marketplaceColor);
      color: #fff;
    }
  }

  /* & .toggleSwitchDisabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  } */

  & .toggleSwitchInner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  & .toggleSwitchSwitch {
    display: block;
    width: 15px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 22px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  & .toggleSwitchCheckbox:checked + .toggleSwitchLabel {
    .toggleSwitchInner {
      margin-left: 0;
    }
    .toggleSwitchSwitch {
      right: 0px;
    }
  }
}
