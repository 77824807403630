@import '../../marketplace.css';

:root {
  /* Variables to change */
  --backgroundColorDisabled: var(--matterColorBright);
  --backgroundColorFocusFailed: #FFF;
  --border: 1px solid var(--borderColorPrimary);
  --borderFocus: 1px solid var(--marketplaceColor);
  --borderDisabled: 1px solid var(--matterColorNegative);
  --borderFailed: 1px solid var(--failColor);
  --borderFocusFailed: 1px solid var(--failColor);
  --borderRadius: 4px;
  --boxShadow: none;
  --textColor: var(--matterColor);
  --placeholderColor: var(--matterColorAnti);
  --textDisabledColor: var(--matterColorAnti);
  --errorColor: var(--failColor);
}

.wrapper {
  position: relative;
  width: 100%;
}

.suggestionsWrapper {
  position: absolute;
  width: 100%;
  background-color: #FFF;
  padding-bottom: 12px;
  z-index: calc(var(--zIndexPopup) + 1);
  overflow-y: auto;
  max-height: 300px;
  box-shadow: var(--boxShadowPopup);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  margin-top: 0;
  top: 76px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border: 1px solid #f1f0ee;
    background-color: #f1f0ee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background:  var(--marketplaceColorDark);
    cursor: pointer;
  }

  & ul {
    margin-bottom: 0;
  }
}

.optionsContainer {
  margin-top: 16px;
}

.searchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listItem,.notFound {
  padding: 3px 8px;

  @media (--viewportSmall) {
    padding: 3px 12px;
  }
}

.highlightedSuggestion,
.listItem:hover,
.listItem:active {
  background-color: var(--marketplaceColorLight);
  cursor: pointer;
}

.notFound {
  cursor: not-allowed;
}

.predictions {
  margin: 0;
}

.findProductsInfo {
  font-style: italic;
  line-height: 20px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.text,
.textBold,
.searchText {
  color: #001018;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.textBold {
  font-weight: 500;
}

.searchText {
  font-weight: 500;
  color: var(--marketplaceColor)
}

.subtext {
  color: var(--matterColorAnti);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}

.subheading {
  color: var(--matterColorAnti);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;

  @media (--viewportSmall) {
    padding: 0 12px;
  }
}

.subheadingContainer {
  display: flex;
  justify-content: space-between;
}

.icon {
  stroke: none;
  width: 10px;
  height: 10px;
  margin-left: 6px;
}

.container {
  position: relative;
  width: 100%;
  margin-top: 0;
  padding: 4px 0;
}

.validation {
  padding: 0 0 22px 0;
}

.validationWrapper {
  top: 72px;
}

.input {
  border: var(--border);
  border-radius: var(--borderRadius);
  color: var(--textColor);
  transition: border-color ease-in 0.1s;
  box-shadow: var(--boxShadow);
}

.input::placeholder {
  color: var(--placeholderColor);
}

.input:focus {
  border: var(--borderFocus);
}

.input:disabled {
  transition: ease-in-out 0.1s;
  color: var(--textDisabledColor);
  background-color: var(--backgroundColorDisabled);
  border: var(--borderDisabled);
  cursor: not-allowed;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
}

.inputError {
  border: var(--borderFailed);
}

.inputError:focus {
  border:  var(--borderFocusFailed);
}

.error {
  font-size: 12px;
  line-height: 16px;
  color: var(--errorColor);
  position: absolute;
  bottom: 4px;
  font-weight: 400;
}

.label {
  padding: 0;
  position: relative;
}

.labelText {
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
  font-weight: 700;
  display: block;
  margin-bottom: 6px;
}

.arrow {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

