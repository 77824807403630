@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  margin-top: 24px;
  margin-bottom: 24px;

  @media(--viewportSmall) {
    margin-top: 24px;
    margin-bottom: 0;
  }

  @media(--viewportMedium) {
    flex-direction: row;
    gap: 32px;
    margin-top: 32px;
  }
}

.orderContainer:first-child {
  margin-top: 0;
}

.orderContent {
  padding: 16px;
  width: 100%;
  padding-bottom: 0;

  @media(--viewportMedium) {
    padding: 0;
    width: 50%;
  }

  @media(--viewportLarge) {
    padding: 0;
    width: 60%;
  }

  @media(--viewportLargeWithPaddings) {
    padding: 0;
    width: 100%;
  }
}

.orderDetailFirstParts {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  @media(--viewportLarge) {
    width: 60%;
  }
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;

  @media(--viewportMedium) {
    font-size: 20px;
    line-height: 24px;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.productContainer {
  display: flex;
  margin-bottom: 24px;
  gap: 12px;
  height: 80px;

  @media(--viewportMedium) {
    margin-bottom: 48px;
    gap: 24px;
    height: unset;
  }
}

.rootForImage {
  max-width: 80px;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #000;

  @media(--viewportSmall) {
    max-width: 120px;
    border: unset;
  }
}

.productContent {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media(--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
  }
}

.productName {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  text-transform: capitalize;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media(--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.productDetails {
  padding-bottom: 0px;

  @media(--viewportSmall) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 56px;
  }
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin: 0;

  @media(--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.price {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;

  @media(--viewportSmall) {
    font-size: 16px;
    line-height: 24px;
  }
}

.orderDetailSecondParts {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  @media(--viewportMedium) {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.deleteButton {
  border: none;
  cursor: pointer;
  display: flex;
}

.error {
  color: var(--failColor);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding-top: 8px;
}

.errorContainer {
  width: 100%;
}

.priceWrapper {
  display: none;

  @media(--viewportMedium) {
    display: block;
  }
}
