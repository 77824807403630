@import '../../../../../marketplace.css';

.container {
    width: 100%;

    @media(--viewportMedium) {
        max-width: 360px;
    }
}

.labels {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    width: 100%;
}

.label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.dateFilters {
    width: 100%;
    z-index: 1;
    display: flex;
    transform: none;
}

.dateFilters :global(.DateRangePickerInput) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    width: 100%;
    margin: 0;
}

.dateFilters :global(.DateRangePickerInput_arrow) {
    display: none;
}

.dateFilters :global(.DateRangePicker) {
    width: 100%;
    
}

.dateFilters :global(.DateInput_input),
.dateFilters :global(.DateInput_input__focused) {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #D9DDE1;
}

.dateFilters :global(.DateInput_input) {
    border: 1px solid #D9DDE1;
}

.dateFilters :global(.DateInput_input__focused) {
    border: 1px solid #3643BA;
}

.dateFilters :global(.DateInput):first-child {
    margin: 0;
    width: 100%;
}

.dateFilters :global(.DateInput) {
    margin: 0;
    width: 100%;
}

.dateFilters :global(.DateRangePicker_picker) {
    margin-top: 8px;
}

.startError :global(.DateRangePickerInput)>div:first-child input {
    border: 1px solid var(--failColor);
}

.endError :global(.DateRangePickerInput)>div:last-child input {
    border: 1px solid var(--failColor);
}

.errorContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
}

.error {
    color: var(--failColor);
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    min-width: 168px;
}
