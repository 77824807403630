@import '../../marketplace.css';

.container {
  margin-bottom: 16px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  padding-bottom: 24px;
  text-align: left;

  @media (--viewportMedium) {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    display: block;
    padding-bottom: 16px;
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 8px 0;
}

.createdDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #b2b2b2;
  margin: 0;
  display: block;
}

.status {
  background: transparent;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius: 2px;
  padding: 4px 8px;
}

.link {
  display: inline-block;
}

.rentalDates {
  padding: 0 8px;
}

.cancelled {
  color: var(--failColor);
  background: #FEC9CB;
}

.delivered {
  color: var(--successColor);
  background: #CBF0C7;
}

.inProgress {
  color: var(--marketplaceColor);
  background: var(--marketplaceColorLight);
}

.pending {
  color: #fa0;
  background: #feeac2;
}

.expired {
  color:  #979595;
  background: #e5e5e5;
}

