@import '../../../marketplace.css';

.listItem {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--matterColorNegative);
  display: flex;
  justify-content: space-between;

  @media (--viewportLarge) {
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
}

.listItem:last-child {
  border-bottom: none;
}

.item {
  margin: 0;
  line-height: 28px;
}

.language {
  color: var(--matterColorAnti);
  font-style: italic;
  text-transform: uppercase;
  padding-right: 16px;
}

.actionContainer {
  display: flex;
  gap: 16px; 
}

.editButton {
  border: none;
  height: 32px;
  width: 32px;
  padding-left: 8px;
  cursor: pointer;
}

.bannedButton {
  min-height: 40px;
  height: 40px;
  min-width: 80px;
  padding: 0 12px;
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
}

.editIcon,
.bannedIcon {
  font-family: 32px;
}

.bannedText {
  display: inline-block;
  color: #E3262F;
  background: #FEC9CB;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius: 2px;
  padding: 4px 8px;
  text-align: center;
}

.editForm {
  display: flex;
  flex-direction: column;

  @media(--viewportSmall) {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
}

.sectionName {
  margin: 12px;
}

.labelName {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--matterColorAnti);
}

.inputName {
  border: 1px solid var(--matterColorAnti);
}

.inputName:active,
.inputName:focus {
  border: 1px solid var(--matterColor);
}

.submitButton {
  width: 100%; 
  min-height: 0;
  padding: 8px 0;
  margin-top: 16px;
  
  @media (--viewportSmall) {
    width: auto;
    padding: 8px 32px;
    margin-top: 0;
  }
}