@import '../../marketplace.css';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 32px;

    @media(--viewportMedium) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media(--viewportLarge) {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        column-gap: 48px;
    }
}

.sectionDeliverAddress {
    @media(--viewportMedium) {
        order: 2;
    }

    @media(--viewportLarge) {
        order: 1;
    }
}

.sectionPayment {
    width: 100%;

    @media(--viewportMedium) {
        width: auto;
        order: 3;
    }

    @media(--viewportLarge) {
        order: 2;
    }
}

.sectionContactDetails {
    @media(--viewportMedium) {
        order: 1;
    }

    @media(--viewportLarge) {
        order: 3;
    }
}

.productName {
    max-width: 60%;
}

.title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    padding-bottom: 8px;

    @media(--viewportMedium) {
        font-size: 26px;
        line-height: 32px;
        padding-bottom: 16px;
    }
}

.subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

.subtitleContainer {
    padding-top: 8px;

    @media(--viewportMedium) {
        padding-top: 24px;
    }
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

.openingHours {
    white-space: pre-line;
}

.priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
}

.invoice {
  display: block;
  padding-top: 8px;

  @media(--viewportMedium) {
    padding-top: 16px;
  }
}

.protocolBtn {
    @apply --marketplaceButtonFontStyles;
    margin-top: 24px;
    min-height: 39px;
    cursor: pointer;
    width: 100%;
    border: none;
    border-radius: var(--borderRadius);
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    text-align: center;
    text-decoration: none;
}

.protocolBtn:hover,
.protocolBtn:focus {
    outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
}

.borderBottom {
    border-bottom: 1px solid var(--matterColorAnti);
    padding-bottom: 16px;
    margin-bottom: 16px;
}
